import { UserAvatar } from "@/components";
import { Avatar, AvatarFallback } from "@/components/_shadui/avatar";
import { H4 } from "@/components/headings";
import { SignupDialog } from "@/components/module-api-editor/signup-dialog";
import {
  EditorInputProps,
  WorkspaceRole,
} from "@/components/module-api-editor/types";
import { useDisclosure } from "@/hooks/use-disclosure";
import { getIsAuthed, getUserCallName } from "@/lib/utils";
import { useMe } from "@/queries/users";
import { AvatarImage } from "@radix-ui/react-avatar";
import { useNavigate } from "@tanstack/react-router";
import { useMemo } from "react";

export function Header({
  workspaceSlug,
}: {
  workspaceSlug?: string;
  hasOperations: boolean;
  workspaceRole: WorkspaceRole;
} & EditorInputProps) {
  const signUpDialogDisclosure = useDisclosure();

  const isAuthed = useMemo(() => getIsAuthed(), []);

  const meQuery = useMe({ enabled: isAuthed });

  const user = meQuery.data;

  const navigate = useNavigate();

  const handleAvatarBadgeClick = () => {
    if (user) {
      navigate({
        from: "/editor/$organizationSlug/$workspaceSlug",
        to: "/dash/onboarding",
      });
    } else {
      navigate({
        from: "/",
        to: "/login",
      });
    }
  };

  return (
    <div className="flex justify-between py-2 px-4 grow-0 shrink-0 items-center rounded-lg">
      <H4 className="text-xs font-semibold">
        {workspaceSlug ? workspaceSlug?.substring(0, 15) : "Workspace"}
      </H4>
      <div className="flex gap-2 items-center">
        <div
          onClick={handleAvatarBadgeClick}
          className="flex cursor-pointer items-center gap-3 hover:bg-secondary/80 pr-2 pl-4 pb-1.5 pt-1 rounded-md"
        >
          <div className="text-left">
            <p className="text-xs">
              {isAuthed && user ? (
                `Heya, ${getUserCallName(user.username)}!`
              ) : (
                <>
                  You are not{" "}
                  <span className="text-brand underline">logged in</span>
                </>
              )}
            </p>
          </div>
          {user ? (
            <UserAvatar>
              <AvatarFallback className="text-xs">
                {user.username}
              </AvatarFallback>
            </UserAvatar>
          ) : (
            <Avatar
              className="border-2 border-brand w-8 h-8"
              onClick={signUpDialogDisclosure.onOpen}
            >
              <AvatarImage src="/img/avatar-background-unauthed.png" />
            </Avatar>
          )}
        </div>
      </div>
      <SignupDialog
        open={signUpDialogDisclosure.isOpen}
        onOpenChange={signUpDialogDisclosure.onOpenChange}
      />
    </div>
  );
}
