import React from "react";
/**
 * This component is only used for renderToString and
 * export as PNG
 */
export const ExportCard: React.FC<{
  apiDefinition: string;
  cardWidth: number;
  cardPadding: number;
}> = ({ apiDefinition, cardWidth, cardPadding }) => (
  <div
    style={{
      width: `${cardWidth}px`,
      backgroundColor: "#20202a",
      borderRadius: "8px",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      padding: `${cardPadding}px`,
      color: "white",
      textAlign: "left",
    }}
  >
    <div
      style={{
        backgroundColor: "#2f313f",
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
        padding: "10px",
        marginBottom: "10px",
      }}
    >
      <div style={{ display: "flex", gap: "6px" }}>
        {["#ff5f56", "#ffbd2e", "#27c93f"].map((color, index) => (
          <div
            key={index}
            style={{
              width: "12px",
              height: "12px",
              borderRadius: "50%",
              backgroundColor: color,
              color: "white",
            }}
          />
        ))}
      </div>
    </div>
    <div dangerouslySetInnerHTML={{ __html: apiDefinition }} />
  </div>
);
