import { useActionBarContext } from "@/components/contexts/action-bar-context";
import {
  EditorInputProps,
  EditorState,
} from "@/components/module-api-editor/types";
import { hardCodedLimits } from "@/lib/const";
import { emptyWorkspaceExample } from "@/lib/oas-examples/empty-workspace";
import { oasExampleMap } from "@/lib/utils";
import isEmpty from "lodash/isEmpty";
import { useCallback, useContext, useMemo, useRef } from "react";
import { parse } from "yaml";
import { ActiveElement, WorkspaceEditorContext } from "./api-editor-context";

export function useAPIEditor() {
  const ctx = useContext(WorkspaceEditorContext);
  if (ctx == null)
    throw new Error(`Trying to use APIEditorContext outside of Provider`);

  return ctx;
}

export function useAPIEditorTools({ value, onChange }: EditorInputProps) {
  const [state, dispatch] = useAPIEditor();
  const [, actionBarDispatch] = useActionBarContext();

  const pushToHistory = useCallback(
    (editorState: EditorState) => {
      dispatch({
        type: "SET_HISTORY",
        payload: [
          ...state.history.slice(-hardCodedLimits.maxEditorHistoryLength - 1),
          editorState,
        ],
      });
    },
    [dispatch, state.history]
  );

  const previousStateRef = useRef(value);
  previousStateRef.current = value;

  const setNewEditorState = useCallback(
    (value: EditorState, shouldPushToHistory: boolean = true) => {
      onChange(value);
      if (previousStateRef.current && shouldPushToHistory) {
        pushToHistory(previousStateRef.current);
      }
    },
    [pushToHistory, onChange]
  );

  const getOASOrError = useCallback(() => {
    const oas = value.data;
    if (!oas) throw new Error("Oas not defined");
    return oas;
  }, [value.data]);

  const setActiveElement = useCallback(
    (activeElement: ActiveElement | undefined) => {
      dispatch({ type: "SET_ACTIVE_ELEMENT", payload: activeElement });
    },
    [dispatch]
  );

  const canGoBackInHistory = state.history.length > 0;

  const goBackInHistory = useCallback(() => {
    const newHistory = state.history.slice();
    const lastElement = newHistory.pop();
    if (!lastElement) return;
    onChange(lastElement);
    dispatch({ type: "SET_HISTORY", payload: newHistory });
  }, [onChange, dispatch, state.history]);

  const hasNoOperations = useMemo(() => {
    const oas = getOASOrError();
    return isEmpty(oas);
  }, [getOASOrError]);

  const resetToExample = useCallback(
    (exampleName: keyof typeof oasExampleMap) => {
      // Shuffle keys in case operation with
      // same key exists. Prevent wrong accordion states.
      setNewEditorState({
        document_type: "oas_api_3_1",
        data: parse(oasExampleMap[exampleName]),
      });
      setActiveElement(undefined);
      actionBarDispatch({
        type: "CLOSE",
      });
    },
    [actionBarDispatch, setActiveElement, setNewEditorState]
  );

  const resetToEmptyWorkspace = useCallback(() => {
    setNewEditorState({
      data: parse(emptyWorkspaceExample),
      document_type: "oas_api_3_1",
    });
    setActiveElement(undefined);
    actionBarDispatch({
      type: "CLOSE",
    });
  }, [actionBarDispatch, setActiveElement, setNewEditorState]);

  return {
    oas: value.data,
    componentsObject: value.data.components || {},
    history: state.history,
    setNewEditorState,
    getOASOrError,
    activeElement: state.activeElement,
    canGoBackInHistory,
    goBackInHistory,
    hasNoOperations,
    setActiveElement,
    resetToExample,
    resetToEmptyWorkspace,
  };
}
