import { ComponentProps, forwardRef } from "react";
import { cn } from "@/lib/utils";
import { LucideIcon } from "lucide-react";
import { Button } from "@/components/_shadui/button";

export const IconButton = forwardRef<
  HTMLButtonElement,
  ComponentProps<typeof Button> & { Icon: LucideIcon }
>(({ className, children, Icon, variant, ...rest }, ref) => {
  return (
    <Button
      variant={variant || "outline"}
      size="sm"
      className={cn("bg-transparent", className)}
      ref={ref}
      {...rest}
    >
      <Icon size={12} className="mr-2" />
      <span>{children}</span>
    </Button>
  );
});
