import {
  Alert,
  AlertDescription,
  AlertTitle,
} from "@/components/_shadui/alert";
import { EditorState } from "@/components/module-api-editor/types";
import { companyInfo, localStorageKeys } from "@/lib/const";
import { apiClient } from "@/lib/http-utils";
import { OrganizationDto } from "@/lib/main-rest-client/definitions";
import { generateRandumSlug, NormIcons, slugify } from "@/lib/utils";
import { useCreateOrganization } from "@/queries/organizations";
import { useMe, userMeQuery, useTokenRefreshMutation } from "@/queries/users";
import { useCreateFullWorkspace } from "@/queries/workspaces";
import { useMutation } from "@tanstack/react-query";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { AxiosError } from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "sonner";

function useGenerateWorkspaceFromLocalStorage() {
  const createFullWorkspaceQuery = useCreateFullWorkspace();
  const [shortCircut, setShortCircut] = useState(false);

  const mutate = ({ onSuccess }: { onSuccess?: () => void }) => {
    const rawStoredEditorState = localStorage.getItem(
      localStorageKeys.editorStateV1
    );
    if (!rawStoredEditorState) {
      setShortCircut(true);
      onSuccess && onSuccess();
      return;
    }
    let editorState: EditorState;
    try {
      editorState = JSON.parse(rawStoredEditorState);
    } catch {
      toast.error(
        "We we're unable to create a workspace from your previous work"
      );
      setShortCircut(true);
      return;
    }
    const name = slugify(generateRandumSlug());
    createFullWorkspaceQuery.mutate({
      createWorkspaceDto: {
        name: name,
        slug: name,
        access_level: "public_read",
      },
      workspaceItemDto: { name: "default-api" },
      workspaceDocumentDto: {
        data: editorState.data,
        document_type: editorState.document_type,
      },
      onSuccess: () => {
        onSuccess && onSuccess();
        localStorage.removeItem(localStorageKeys.editorStateV1);
      },
    });
  };

  return {
    ...createFullWorkspaceQuery,
    mutate,
    isSuccess: createFullWorkspaceQuery.isSucces || shortCircut,
  };
}

export const Route = createFileRoute("/dash/onboarding")({
  loader: ({ context: { queryClient } }) => {
    queryClient.ensureQueryData(userMeQuery);
  },
  component: Onboarding,
});

export function Onboarding() {
  const meQuery = useMe();
  const createOrganizationMutation = useCreateOrganization();
  const tokenRefreshMutation = useTokenRefreshMutation();
  const generateWorkspaceMutation = useGenerateWorkspaceFromLocalStorage();
  const navigate = useNavigate();
  const processingRef = useRef(false);

  const fetchOrganizationMutation = useMutation<
    OrganizationDto,
    AxiosError,
    { organizationId: string }
  >({
    mutationFn: async (values) => {
      return (
        await apiClient.findOrganizationByIdOrSlug({
          organizationIdOrSlug: values.organizationId,
        })
      ).data;
    },
    onSuccess: (data) => {
      navigateToDash(data.slug);
    },
  });

  const navigateToDash = useCallback(
    (organizationSlug: string) => {
      navigate({
        from: "/dash/onboarding",
        to: "/dash/$organizationSlug/home",
        params: {
          organizationSlug,
        },
      });
    },
    [navigate]
  );

  const createOrganizationOrNavigate = useCallback(() => {
    if (!meQuery.data || processingRef.current) return;

    processingRef.current = true;

    if (meQuery.data.organization_id) {
      fetchOrganizationMutation.mutate({
        organizationId: meQuery.data.organization_id,
      });
    } else {
      const orgName = `${meQuery.data.username}'s Organization`;
      createOrganizationMutation.mutate(
        {
          name: orgName,
          slug: orgName,
        },
        {
          onSuccess: (organizationDto) => {
            tokenRefreshMutation.mutate(undefined, {
              onSuccess: () => {
                generateWorkspaceMutation.mutate({
                  onSuccess: () => {
                    navigateToDash(organizationDto.slug);
                  },
                });
              },
            });
          },
        }
      );
    }
  }, [
    createOrganizationMutation,
    meQuery.data,
    navigateToDash,
    tokenRefreshMutation,
    generateWorkspaceMutation,
    fetchOrganizationMutation,
  ]);

  useEffect(() => {
    if (meQuery.isSuccess && !processingRef.current) {
      createOrganizationOrNavigate();
    }
  }, [meQuery.isSuccess]);

  return (
    <div className="h-screen w-screen flex items-center justify-center">
      {fetchOrganizationMutation.error && (
        <Alert>
          <NormIcons.Error className="h-4 w-4" />
          <AlertTitle>Oh no!</AlertTitle>
          <AlertDescription>
            There was an error when trying to navigate you to your dashboard.
            Contact
            <a href={`mailto:${companyInfo.supportEmail}`}>
              {companyInfo.supportEmail}
            </a>{" "}
            if the error persists.
          </AlertDescription>
        </Alert>
      )}
      {createOrganizationMutation.error ? (
        <Alert>
          <NormIcons.Error className="h-4 w-4" />
          <AlertTitle>Oh no!</AlertTitle>
          <AlertDescription>
            We tried to create an organization for you account be ran into an
            error. Please contact{" "}
            <a href={`mailto:${companyInfo.supportEmail}`}>
              {companyInfo.supportEmail}
            </a>{" "}
            if the error persists.
          </AlertDescription>
        </Alert>
      ) : (
        "Loading"
      )}
    </div>
  );
}
