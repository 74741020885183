export const xFields = {
  dtoInfo: "x-fiddle-dto-info",
} as const;

export function getDtoXFields(baseSchemaName: string) {
  return {
    [xFields.dtoInfo]: {
      baseSchemaName: baseSchemaName,
    },
  } as any;
}
