import { Button } from "@/components/_shadui/button";
import { H3 } from "@/components/headings";
import { ActionBarFormTitle } from "@/components/module-action-bar/elements";
import { EditorInputProps } from "@/components/module-api-editor/types";
import { BtnGroup } from "@/components/module-visual-editor/shared-components";
import { SuggestCard } from "@/components/suggest-card";
import { useComponents } from "@/hooks/use-components";
import { useResponses } from "@/hooks/use-responses";
import { HttpStatus, httpStatusMap } from "@/lib/helpers";
import { getHttpStatusResponse } from "@/lib/oas-tools/https-status-default-schemas";
import {
  httpVerbStatusCodes,
  StatusCodeDescription,
  TemplateResponseCodes,
} from "@/lib/oas-tools/https-verb-status-codes";
import { SupportedHTTPVerbs } from "@/lib/types";
import { DEFAULT_ICON_SIZE } from "@/lib/utils";
import { ArrowRight } from "lucide-react";
import { useActionBarContext } from "../contexts/action-bar-context";
import { ActionBarContent } from "./action-bar-content";

export function SuggestedResponses({
  method,
  value,
  onChange,
  operationId,
}: {
  method: SupportedHTTPVerbs;
  operationId: string;
} & EditorInputProps) {
  const { addResponse } = useResponses({ value, onChange });
  const { addComponentResponseToOperation } = useComponents({
    value,
    onChange,
  });
  const [, dispatch] = useActionBarContext();

  const suggestedResponses = httpVerbStatusCodes[method];

  const handleTemplateAdd = (
    statusCodeDescription: StatusCodeDescription<HttpStatus>,
    { onSuccess }: { onSuccess?: () => unknown }
  ) => {
    const response = getHttpStatusResponse(
      statusCodeDescription.code as HttpStatus
    );
    addResponse({
      responseCode: statusCodeDescription.code,
      operationId,
      onSuccess,
      response: {
        description:
          statusCodeDescription.description ||
          `Response for code: ${statusCodeDescription.code}`,
        content: { "application/json": { schema: response } },
      },
    });
  };

  const handleComponentResponseAdd = (
    statusCodeDescription: StatusCodeDescription<TemplateResponseCodes>,
    { onSuccess }: { onSuccess: () => unknown }
  ) => {
    addComponentResponseToOperation({
      operationId,
      responseCode: statusCodeDescription.code,
      onSuccess,
    });
  };

  const handleDone = () => {
    dispatch({
      type: "CLOSE",
    });
  };

  return (
    <ActionBarContent>
      <ActionBarFormTitle>Suggested responses</ActionBarFormTitle>
      <div className="max-h-[500px] overflow-auto">
        <H3 className="pb-3 pt-2 pl-0">Add custom responses</H3>
        <div className="grid grid-cols-2">
          {suggestedResponses.tamplates.map((statusDescription) => {
            const responseCode = statusDescription.code;
            return (
              <SuggestCard
                key={statusDescription.code}
                title={`${responseCode} ${httpStatusMap[responseCode].message}`}
                description={statusDescription.description}
                onAdd={({ onSuccess }) =>
                  handleTemplateAdd(statusDescription, { onSuccess })
                }
              />
            );
          })}
        </div>
        <H3 className="pb-3 pt-norm pl-0">Add reusable responses</H3>
        <div className="grid grid-cols-2 gap-2 pr-2 pb-norm">
          {suggestedResponses.components.map((statusDescription) => {
            const responseCode = statusDescription.code;
            return (
              <SuggestCard
                key={statusDescription.code}
                title={`${responseCode} ${httpStatusMap[responseCode].message}`}
                description={statusDescription.description}
                onAdd={({ onSuccess }) =>
                  handleComponentResponseAdd(statusDescription, { onSuccess })
                }
              />
            );
          })}
        </div>
      </div>
      <BtnGroup className="justify-end pt-4">
        <Button onClick={handleDone} size="sm" variant="primary">
          Done
          <ArrowRight size={DEFAULT_ICON_SIZE} />
        </Button>
      </BtnGroup>
    </ActionBarContent>
  );
}
