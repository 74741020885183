import { useActionBarContext } from "@/components/contexts/action-bar-context";
import {
  DocumentationCard,
  DocumentationCardContent,
  DocumentationCardDescription,
  DocumentationCardHeader,
  DocumentationCardItem,
  DocumentationCardItemContent,
  DocumentationCardItemHeader,
  DocumentationCardItemTitle,
  DocumentationCardMoreButton,
  DocumentationCardTitle,
  DocumentationCardTypeBadge,
} from "@/components/documentation-card";
import { EditorItemDescription } from "@/components/module-api-editor";
import { SetActiveItem } from "@/components/module-api-editor/editor-preview-operation-active-item";
import {
  EditorInputProps,
  WorkspaceRole,
} from "@/components/module-api-editor/types";
import {
  BtnGroup,
  DeleteButton,
  EditButton,
} from "@/components/module-visual-editor/shared-components";
import { useRequestBody } from "@/hooks/use-request-body";
import { NoRefsHereError } from "@/lib/errors";
import { getPristineType } from "@/lib/oas-tools/oas-schema-utils";
import { isReference } from "@/lib/oas-tools/oas-tag-helpers";
import {
  OASRequestBodyObject,
  SupportedContentFormats,
  SupportedHTTPVerbs,
} from "@/lib/types";
import { canEdit } from "@/lib/utils";
import { useMemo } from "react";

const NOT_SUPPORTED_FOR_REQUEST_BODY = ["GET", "HEAD", "DELETE"];

export function RequestBodyCard({
  operationId,
  method,
  format,
  value,
  onChange,
  workspaceRole,
  setActiveItem,
}: {
  operationId: string;
  method: SupportedHTTPVerbs;
  format: SupportedContentFormats;
  workspaceRole: WorkspaceRole;
  setActiveItem: SetActiveItem;
} & EditorInputProps) {
  const { getRequestBody, remove } = useRequestBody({ value, onChange });
  const [, actionBarDispatch] = useActionBarContext();

  const notSupported = NOT_SUPPORTED_FOR_REQUEST_BODY.includes(
    method.toUpperCase()
  );

  const requestBody = useMemo(() => {
    const found = getRequestBody({ operationId });
    if (!found) return undefined;
    if (isReference(found)) throw new NoRefsHereError();
    return found;
  }, [getRequestBody, operationId]);

  const schema =
    requestBody?.content?.[format].schema || getPristineType("object");

  const handleEdit = (requestBody: OASRequestBodyObject) => {
    actionBarDispatch({
      type: "SET_PAGE",
      payload: {
        name: "request-body-edit",
        context: {
          format,
          operationId,
          requestBody,
        },
      },
    });
  };

  const handleAdd = () => {
    actionBarDispatch({
      type: "SET_PAGE",
      payload: {
        name: "request-body-add",
        context: {
          format: "application/json",
          operationId,
        },
      },
    });
  };

  const handleRemove = () => {
    // IMPORTANT: Reset active item since path to `QuickEditor` will become
    // invalid and will make editor crash.
    setActiveItem(undefined);
    remove({ operationId });
  };

  if (notSupported) return null;

  const isEditor = canEdit(workspaceRole);
  if (!isEditor && !requestBody) return null;
  return (
    <DocumentationCard>
      <DocumentationCardHeader workspaceRole={workspaceRole}>
        <DocumentationCardTitle>Request body</DocumentationCardTitle>
      </DocumentationCardHeader>
      <DocumentationCardContent>
        {requestBody && (
          <DocumentationCardItem
            key={`${operationId}-${method}-requestBody`}
            onClick={() => setActiveItem({ type: "requestBody" })}
            isLast
          >
            <DocumentationCardItemHeader>
              <div className="flex gap-2">
                <DocumentationCardTypeBadge
                  schema={schema}
                  isRequired={requestBody.required}
                />
                <DocumentationCardItemTitle>Body</DocumentationCardItemTitle>
              </div>
              {isEditor && (
                <BtnGroup>
                  <EditButton onClick={() => handleEdit(requestBody)}>
                    Edit request body
                  </EditButton>
                  <DeleteButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemove();
                    }}
                  >
                    Delete request body
                  </DeleteButton>
                </BtnGroup>
              )}
            </DocumentationCardItemHeader>
            <DocumentationCardItemContent>
              <DocumentationCardDescription>
                <EditorItemDescription
                  item={requestBody}
                  onAddDescriptionClick={() => handleEdit(requestBody)}
                  workspaceRole={workspaceRole}
                />
              </DocumentationCardDescription>
            </DocumentationCardItemContent>
          </DocumentationCardItem>
        )}
        {!requestBody && isEditor && (
          <DocumentationCardMoreButton btnTitle="Add new" onClick={handleAdd} />
        )}
      </DocumentationCardContent>
    </DocumentationCard>
  );
}
