import { PreviewRow } from "@/components/module-preview-pane/preview-schema-row";
import { generateOASComponentSchemaRows } from "@/lib/oas-tools/generate-oas-model-rows";
import { ComponentId } from "@/lib/oas-tools/oas-schema-utils";
import { OASComponentsObject } from "@/lib/types";
import { cn } from "@/lib/utils";
import { ComponentProps, PropsWithChildren, useMemo } from "react";

export function PreviewContainer({
  children,
  className,
  ...rest
}: PropsWithChildren<ComponentProps<"div">>) {
  return (
    <div
      style={{ fontFamily: "monospace" }}
      className={cn("pt-8 px-10 pb-8 font-mono", className)}
      {...rest}
    >
      {children}
    </div>
  );
}

export function ModelRender({
  componentId,
  componentsObject,
}: {
  componentId: ComponentId;
  componentsObject: OASComponentsObject;
}) {
  const rows = useMemo(() => {
    return generateOASComponentSchemaRows({
      initialLevel: 0,
      componentsObject,
      componentId,
    });
  }, [componentId, componentsObject]);

  return (
    <PreviewContainer>
      <pre>
        {rows.map((r, i) => {
          return (
            <PreviewRow
              row={r}
              key={`preview-row-${i}`}
              componentsObject={componentsObject}
            />
          );
        })}
      </pre>
    </PreviewContainer>
  );
}
