import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/_shadui/form";
import { Input } from "@/components/_shadui/input";
import { ButtonLoading } from "@/components/button-loading";
import { FormLabelRequired } from "@/components/form-label-required";
import { ActionBarFieldGroup } from "@/components/module-action-bar/elements";
import { usePatchWorkspace } from "@/queries/workspaces";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "@tanstack/react-router";
import { AxiosError } from "axios";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/_shadui/select";
import { WorkspaceAccessLevel } from "@/lib/main-rest-client/definitions";
import { BtnGroup } from "@/components/module-visual-editor/shared-components";

const formSchema = z.object({
  name: z.string(),
  slug: z.string(),
  accessLevel: z.enum(["public_write", "public_read", "private_read"] as const),
});

const descriptionMap: Record<WorkspaceAccessLevel, string> = {
  private_read: "Only invited members can see or edit the workspace.",
  public_read:
    "Everyone with a link can see the workspace. Only invited users can edit the workspace.",
  public_write: "Everyone can read and edit the workspace.",
};

type FormValues = z.infer<typeof formSchema>;

export function BaseForm({
  form,
  onSubmit,
  isLoading,
}: {
  form: ReturnType<typeof useForm<FormValues>>;
  onSubmit: SubmitHandler<FormValues>;
  isLoading?: boolean;
}) {
  const access_level = form.watch("accessLevel");
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <ActionBarFieldGroup>
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  <FormLabelRequired />
                  Name
                </FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="slug"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  <FormLabelRequired />
                  Slug
                </FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="accessLevel"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  <FormLabelRequired />
                  Access level
                </FormLabel>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                  disabled={field.disabled}
                >
                  <SelectTrigger className="w-[180px]">
                    <SelectValue placeholder="Access level" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="public_write">Public write</SelectItem>
                    <SelectItem value="public_read">Public read</SelectItem>
                    <SelectItem value="private_read">Private read</SelectItem>
                  </SelectContent>
                </Select>
                <FormDescription>
                  {descriptionMap[access_level]}
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
        </ActionBarFieldGroup>
        <BtnGroup className="justify-end">
          <ButtonLoading
            type="submit"
            variant="primary"
            isLoading={isLoading}
            disabled={form.formState.disabled}
          >
            Save
          </ButtonLoading>
        </BtnGroup>
      </form>
    </Form>
  );
}

export function FormPatchWorkspace({
  workspaceId,
  formValues,
  organizationSlug,
  isDisabled = false,
}: {
  workspaceId: string;
  formValues: FormValues;
  organizationSlug: string;
  isDisabled?: boolean;
}) {
  const patchWorkspaceMutation = usePatchWorkspace();
  const navigate = useNavigate();

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: formValues,
    disabled: isDisabled,
  });

  const onSubmit: SubmitHandler<FormValues> = async ({
    accessLevel,
    ...values
  }) => {
    try {
      await patchWorkspaceMutation.mutateAsync({
        ...values,
        workspaceId,
        access_level: accessLevel,
      });
      navigate({
        from: "/dash/$organizationSlug/$workspaceSlug/edit",
        to: "/dash/$organizationSlug/home",
        params: { organizationSlug },
      });
      toast.success("Changes saves successfully");
    } catch (error) {
      if (error instanceof AxiosError) {
        toast.error(error.response?.data?.message);
      }
      form.setError("slug", {
        message:
          "Slugs must be globally unique, lowercase, and can only include '-' as a special character. Please be creative!",
      });
    }
  };

  return (
    <BaseForm
      form={form}
      onSubmit={onSubmit}
      isLoading={patchWorkspaceMutation.isPending}
    />
  );
}
