import { Button } from "@/components/_shadui/button";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/components/_shadui/tabs";
import { ActiveElement } from "@/components/contexts/api-editor-context";
import {
  DocumentationCard,
  DocumentationCardContent,
  DocumentationCardDescription,
} from "@/components/documentation-card";
import { H1 } from "@/components/headings";
import { HttpMethodBadge } from "@/components/http-method-badge";
import { ColorFrame } from "@/components/module-api-editor";
import {
  ActiveOperationItem,
  EditorPreviewOperationActiveItem,
} from "@/components/module-api-editor/editor-preview-operation-active-item";
import { Toolbar } from "@/components/module-api-editor/toolbar";
import {
  EditorInputProps,
  WorkspaceRole,
} from "@/components/module-api-editor/types";
import { OperationPreview } from "@/components/module-preview-pane/operation-preview";
import { PathParametersCard } from "@/components/module-visual-editor/path-parameters-card";
import { QueryParametersCard } from "@/components/module-visual-editor/query-parameters-card";
import { RequestBodyCard } from "@/components/module-visual-editor/request-body-card";
import { ResponsesCard } from "@/components/module-visual-editor/responses-card";
import {
  BtnGroup,
  DeleteButton,
  EditButton,
} from "@/components/module-visual-editor/shared-components";
import { useOperation } from "@/hooks/use-operation";
import { httpColorMap } from "@/lib/oas-tools/style-helpers";
import { OASComponentsObject, SupportedHTTPVerbs } from "@/lib/types";
import { canEdit } from "@/lib/utils";
import { useMemo, useState } from "react";
import { useActionBarContext } from "../contexts/action-bar-context";

export function EditorPreviewOperation({
  value,
  onChange,
  isAuthed,
  hasOperations,
  workspaceSlug,
  organizationSlug,
  activeElement,
  componentsObject,
  workspaceRole,
}: {
  activeElement: ActiveElement;
  isAuthed: boolean;
  hasOperations: boolean;
  workspaceSlug: string | undefined;
  organizationSlug: string | undefined;
  componentsObject: OASComponentsObject;
  workspaceRole: WorkspaceRole;
} & EditorInputProps) {
  const [activeItem, setActiveItem] = useState<ActiveOperationItem | undefined>(
    undefined
  );

  const [, actionBarDispatch] = useActionBarContext();
  const { getOperationWithInfo, removeOperation } = useOperation({
    value,
    onChange,
  });

  if (activeElement.type !== "operation")
    throw new Error("Active element needs to be operation");

  const operationWithInfo = useMemo(
    () => getOperationWithInfo(activeElement.operationId),
    [activeElement.operationId, getOperationWithInfo]
  );

  const operation = operationWithInfo.operation;
  const method = operationWithInfo.method;
  const urlPath = operationWithInfo.urlPath;
  const path = `paths.${urlPath}.${method}`;

  if (!operation.operationId) throw new Error();

  const format = "application/json";

  const handleEditClick = (urlPath: string, method: SupportedHTTPVerbs) => {
    if (!operation.operationId) throw new Error("Operation does not have id");
    actionBarDispatch({
      type: "SET_PAGE",
      payload: {
        name: "edit-operation",
        context: {
          urlPath,
          method,
          operationId: operation.operationId,
        },
      },
    });
  };

  const handleDeleteClick = (urlPath: string, method: SupportedHTTPVerbs) => {
    removeOperation(urlPath, method);
  };

  const color = httpColorMap[method];
  if (!color) throw new Error("Unable to find color for method");

  const isEditor = canEdit(workspaceRole);
  return (
    <Tabs
      defaultValue="editor"
      className="h-full w-full flex flex-col px-4 pb-4 pt-2"
    >
      <div className="flex justify-between pb-3 grow-0 shrink-0">
        <TabsList>
          <TabsTrigger value="editor">Editor</TabsTrigger>
          <TabsTrigger value="plain">Document</TabsTrigger>
        </TabsList>
        <Toolbar
          key={JSON.stringify(activeElement)}
          value={value}
          onChange={onChange}
          isAuthed={isAuthed}
          hasOperations={hasOperations}
          organizationSlug={organizationSlug}
          workspaceSlug={workspaceSlug}
          workspaceRole={workspaceRole}
        />
      </div>
      <TabsContent
        value="editor"
        className="grow mt-0 overflow-auto rounded-md shadow-lg relative"
      >
        <div className="flex gap-norm items-start">
          <div className="max-w-screen-xl flex flex-col items-stretch gap-5 pt-2 grow">
            <ColorFrame
              className="flex justify-between items-center gap-4"
              color={color}
              onClick={() => handleEditClick(urlPath, method)}
            >
              <div className="flex gap-2 items-center">
                <HttpMethodBadge method={method} />{" "}
                <p className="font-mono">{urlPath}</p>
              </div>
              {isEditor && (
                <BtnGroup>
                  <EditButton
                    onClick={() => handleEditClick(urlPath, method)}
                  />
                  <DeleteButton
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleDeleteClick(urlPath, method);
                    }}
                  />
                </BtnGroup>
              )}
            </ColorFrame>
            <div className="pl-4">
              {operationWithInfo.operation.summary && (
                <H1 className="pb-1">{operationWithInfo.operation.summary}</H1>
              )}
              <DocumentationCardDescription className="">
                {operation.description || (
                  <>
                    Add a description{" "}
                    <Button
                      variant="link"
                      size="link"
                      onClick={() => handleEditClick(urlPath, method)}
                    >
                      here
                    </Button>
                  </>
                )}
              </DocumentationCardDescription>
            </div>
            <PathParametersCard
              operationId={operation.operationId}
              value={value}
              onChange={onChange}
              workspaceRole={workspaceRole}
              setActiveItem={setActiveItem}
            />
            <QueryParametersCard
              operationWithInfo={operationWithInfo}
              value={value}
              onChange={onChange}
              workspaceRole={workspaceRole}
              setActiveItem={setActiveItem}
            />
            <ResponsesCard
              operationId={operation.operationId}
              method={method}
              urlPath={urlPath}
              path={`${path}.responses`}
              format={format}
              value={value}
              onChange={onChange}
              workspaceRole={workspaceRole}
              setActiveItem={setActiveItem}
            />
            <RequestBodyCard
              operationId={operation.operationId}
              format={format}
              method={method}
              value={value}
              onChange={onChange}
              workspaceRole={workspaceRole}
              setActiveItem={setActiveItem}
            />
          </div>
          {activeItem && (
            <DocumentationCard className="shrink w-[350px]">
              <DocumentationCardContent className="fixed w-[350px]">
                <EditorPreviewOperationActiveItem
                  value={value}
                  onChange={onChange}
                  activeItem={activeItem}
                  operationId={operation.operationId}
                  workspaceRole={workspaceRole}
                  setActiveItem={setActiveItem}
                />
              </DocumentationCardContent>
            </DocumentationCard>
          )}
        </div>
      </TabsContent>
      <TabsContent
        value="plain"
        className="grow mt-0 overflow-auto bg-preview-background rounded-md shadow-lg relative"
      >
        {activeElement?.type === "operation" && (
          <OperationPreview
            activeElement={activeElement}
            componentsObject={componentsObject}
            value={value}
            onChange={onChange}
          />
        )}
      </TabsContent>
    </Tabs>
  );
}
