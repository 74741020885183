import { useMemo } from "react";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import { stringify } from "yaml";
import { useAPIEditorTools } from "@/components/contexts/api-editor-context-hooks";
import { EditorInputProps } from "@/components/module-api-editor/types";

export function VisualPreviewOpenAPI(props: EditorInputProps) {
  const { getOASOrError } = useAPIEditorTools(props);

  const asString = useMemo(() => {
    const oas = getOASOrError();
    return stringify(oas);
  }, [getOASOrError]);

  return <SwaggerUI spec={asString} />;
}
