import { ActionBarFormTitle } from "@/components/module-action-bar/elements";
import { EditorInputProps } from "@/components/module-api-editor/types";
import {
  BtnGroup,
  SubmitButton,
} from "@/components/module-visual-editor/shared-components";
import { SuggestCard } from "@/components/suggest-card";
import { useComponents } from "@/hooks/use-components";
import { useSchemaDtos } from "@/hooks/use-dtos";
import { createDtoFromOASSchema } from "@/lib/oas-tools/create-dto-from-schema";
import {
  dtoTemplateNames,
  getDtoTemplateValues,
} from "@/lib/oas-tools/dto-templates";
import { useMemo } from "react";
import { toast } from "sonner";
import { useActionBarContext } from "../contexts/action-bar-context";
import { ActionBarContent } from "./action-bar-content";

export function ComponentAddSuggestDtos({
  schemaName,
  value,
  onChange,
}: {
  schemaName: string;
} & EditorInputProps) {
  const { getComponentSchemaOrError } = useComponents({ value, onChange });
  const { add } = useSchemaDtos({ value, onChange });
  const [, dispatch] = useActionBarContext();

  const schemaWithInfo = useMemo(
    () => getComponentSchemaOrError(schemaName),
    [getComponentSchemaOrError, schemaName]
  );

  const handleAdd = ({
    dtoName,
    dtoDescription,
  }: {
    dtoName: string;
    dtoDescription: string;
  }) => {
    try {
      const dtoSchema = createDtoFromOASSchema(schemaWithInfo.schema);
      dtoSchema.description = dtoDescription;
      add(
        dtoName,
        {
          ...dtoSchema,
          description: dtoDescription,
        },
        schemaName
      );
      toast.success("DTO created");
    } catch (err) {
      toast.error("Failed to create DTO");
    }
  };

  const handleDone = () => {
    dispatch({
      type: "CLOSE",
    });
  };

  return (
    <ActionBarContent>
      <ActionBarFormTitle>
        Create data transfer objects (DTOs)
      </ActionBarFormTitle>
      <p className="text-sm pt-2">
        DTOs are used in request bodies or in list endpoints.
      </p>
      <div className="flex flex-col items-stretch gap-2 pb-2 overflow-auto py-5">
        {dtoTemplateNames.map((templateName) => {
          const templateValues = getDtoTemplateValues(templateName, schemaName);
          return (
            <SuggestCard
              key={templateName}
              title={templateValues.dtoName}
              description={templateValues.dtoDescription}
              onAdd={({ onSuccess }) => {
                handleAdd({
                  dtoDescription: templateValues.dtoDescription,
                  dtoName: templateValues.dtoName,
                });
                onSuccess();
              }}
            />
          );
        })}
      </div>
      <div className="pt-4">
        <BtnGroup className="justify-end">
          <SubmitButton onClick={handleDone} type="button">
            Done
          </SubmitButton>
        </BtnGroup>
      </div>
    </ActionBarContent>
  );
}
