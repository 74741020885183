import { Button } from "@/components/_shadui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/_shadui/form";
import { Input } from "@/components/_shadui/input";
import { Textarea } from "@/components/_shadui/textarea";
import { useActionBarContext } from "@/components/contexts/action-bar-context";
import { FormLabelRequired } from "@/components/form-label-required";
import {
  ActionBarFieldGroup,
  ActionBarForm,
  ActionBarFormTitle,
} from "@/components/module-action-bar/elements";
import { EditorInputProps } from "@/components/module-api-editor/types";
import { BtnGroup } from "@/components/module-visual-editor/shared-components";
import { useTags } from "@/hooks/use-tags";
import { OASTag } from "@/lib/types";
import { zodResolver } from "@hookform/resolvers/zod";
import { MoveRight } from "lucide-react";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";

const formSchema = z.object({
  name: z.string().min(1, "Required").toLowerCase(),
  description: z.string(),
});

type FormValues = z.infer<typeof formSchema>;

function TagForm({
  defaultValues,
  onSubmit,
  title,
  btnTitle,
}: {
  defaultValues: Partial<FormValues>;
  onSubmit: SubmitHandler<FormValues>;
  title: string;
  btnTitle: string;
}) {
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      ...defaultValues,
    },
  });

  return (
    <Form {...form}>
      <ActionBarForm onSubmit={form.handleSubmit(onSubmit)}>
        <ActionBarFormTitle>{title}</ActionBarFormTitle>
        <ActionBarFieldGroup>
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  <FormLabelRequired /> Name
                </FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Description</FormLabel>
                <FormControl>
                  <Textarea {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </ActionBarFieldGroup>
        <BtnGroup>
          <Button size="sm" type="submit">
            {btnTitle} <MoveRight />
          </Button>
        </BtnGroup>
      </ActionBarForm>
    </Form>
  );
}

export function EditTagForm({
  value,
  onChange,
  tagObject,
}: { tagObject: OASTag } & EditorInputProps) {
  const [, actionBarDispatch] = useActionBarContext();
  const { update } = useTags({ value, onChange });

  const handleSubmit: SubmitHandler<FormValues> = (values) => {
    try {
      update({
        tagName: tagObject.name,
        tagObject: { description: values.description, name: values.name },
      });
    } catch (err) {
      if (err instanceof Error) {
        return toast.error(err.message);
      }
    }
    actionBarDispatch({ type: "CLOSE" });
    toast.success("Updated tag");
  };

  return (
    <TagForm
      onSubmit={handleSubmit}
      btnTitle="Edit"
      title="Edit tag"
      defaultValues={{
        name: tagObject.name,
        description: tagObject.description || "",
      }}
    />
  );
}
