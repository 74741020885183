import { useAPIEditorTools } from "@/components/contexts/api-editor-context-hooks";
import {
  MetadataForm,
  MetadataFormValues,
} from "@/components/module-action-bar/metadata-form";
import { EditorInputProps } from "@/components/module-api-editor/types";
import { editMetadaInDefinition } from "@/lib/editor-mutations/oas-metadata";
import { useActionBarContext } from "../contexts/action-bar-context";
import { ActionBarContent } from "./action-bar-content";

export function MetadataEdit({ value, onChange }: EditorInputProps) {
  const { oas, setNewEditorState } = useAPIEditorTools({ value, onChange });
  const [, dispatch] = useActionBarContext();

  const onSubmit = (values: MetadataFormValues) => {
    if (!oas) return;
    const newDefintion = editMetadaInDefinition(oas, values);
    setNewEditorState({
      data: newDefintion,
      document_type: value.document_type,
    });
    dispatch({ type: "CLOSE" });
  };

  return (
    <ActionBarContent>
      <MetadataForm
        onSubmit={onSubmit}
        defaultValues={{
          description: oas?.info.description || "",
          title: oas?.info.title || "",
        }}
        title="Edit info"
        btnTitle="Edit"
      />
    </ActionBarContent>
  );
}
