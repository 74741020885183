import { ComponentId } from "@/lib/oas-tools/oas-schema-utils";
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  useContext,
  useMemo,
  useReducer,
} from "react";

type Action =
  | {
      type: "SET_LATEST_ADDED_PLACEHOLDER";
      payload: string;
    }
  | {
      type: "SET_RESOLVE_COMPONENTS";
      payload: ComponentId[];
    };

export type State = {
  latestPlaceholderId: string;
  resolveComponents: ComponentId[];
};

const SchemaEditorContext = createContext<
  readonly [State, Dispatch<Action>] | undefined
>(undefined);

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "SET_LATEST_ADDED_PLACEHOLDER":
      return { ...state, latestPlaceholderId: action.payload };
    case "SET_RESOLVE_COMPONENTS":
      return { ...state, resolveComponents: action.payload };
    default:
      throw new Error(
        "Unknown action in SchemaEditorContext of type " +
          (action as Action).type
      );
  }
}

const defaultInitialState: State = {
  latestPlaceholderId: "",
  resolveComponents: [],
};

export function SchemaEditorContextProvider({
  children,
}: PropsWithChildren<{ initialState?: Partial<State> }>) {
  const [state, dispatch] = useReducer(reducer, {
    ...defaultInitialState,
  });

  const value = useMemo(() => [state, dispatch] as const, [state]);

  return (
    <SchemaEditorContext.Provider value={value}>
      {children}
    </SchemaEditorContext.Provider>
  );
}

export function useSchemaEditorContext() {
  const value = useContext(SchemaEditorContext);
  if (value == null)
    throw new Error(
      `Trying to use SchemaEditorContext outside of the required provider`
    );
  return value;
}
