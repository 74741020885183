import { cn } from "@/lib/utils";
import { MoveLeftIcon } from "lucide-react";
import { PropsWithChildren } from "react";

interface ActionBarTitleProps {
  onBack?: () => unknown;
}

export function ActionBarTitle({
  onBack,
  children,
}: PropsWithChildren<ActionBarTitleProps>) {
  return (
    <div className="flex gap-2 px-3 py-4 justify-center border-b-1 border-border">
      {onBack && (
        <button
          onClick={onBack}
          className={cn(
            "text-text-secondary bg-background-secondary-hover w-8 grid place-items-center rounded-md",
            "ring-offset-background transition-colors focus-visible:outline-none",
            "focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2"
          )}
        >
          <MoveLeftIcon size={12} />
          <span className="sr-only">Go back</span>
        </button>
      )}
      <h2 className="text-lg grow">{children}</h2>
    </div>
  );
}
