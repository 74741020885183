import { apiClient, getTokens, refreshAccessToken } from "@/lib/http-utils";
import { PatchUserDto, UserDto } from "@/lib/main-rest-client/definitions";
import {
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

export const userMeQuery = queryOptions<UserDto, AxiosError>({
  queryKey: ["me"],
  queryFn: async () => {
    return (await apiClient.findMe()).data;
  },
  meta: {
    errorMessage: "There was an error fetching your user profile",
  },
});

export function useMe(
  options?: Omit<UseQueryOptions<UserDto, AxiosError>, "queryKey" | "queryFn">
) {
  return useQuery({ ...userMeQuery, ...options });
}

export function usePatchUser() {
  const queryClient = useQueryClient();
  return useMutation<UserDto, AxiosError, PatchUserDto & { userId: string }>({
    mutationFn: async ({ userId, ...values }) => {
      return (await apiClient.patchUser({ userId }, values)).data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["me"] });
    },
  });
}

export function useTokenRefreshMutation() {
  const queryClient = useQueryClient();
  return useMutation<string, AxiosError, void>({
    mutationFn: async () => {
      const tokens = getTokens();
      if (!("refreshToken" in tokens))
        throw new Error("Unable to local refresh token");
      const accessToken = await refreshAccessToken(tokens.refreshToken);
      apiClient.setTokenHeader(accessToken);
      return accessToken;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["me"] });
    },
  });
}
