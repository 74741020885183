import { useAPIEditorTools } from "@/components/contexts/api-editor-context-hooks";
import { EditorInputProps } from "@/components/module-api-editor/types";
import { DtoSchemaWithInfo, useSchemaDtos } from "@/hooks/use-dtos";
import {
  addComponentResponseToDefinition,
  addComponentResponseByCodeToOperationInDefinition,
  addComponentSchemaToDefinition,
  editComponentResponseInDefinition,
  editComponentSchemaInDefinition,
  getComponentResponseFromDefinition,
  getComponentResponsesFromDefinition,
  removeComponentResponseFromDefinition,
  removeComponentSchemaFromDefinition,
} from "@/lib/editor-mutations/oas-components";
import { DefinitionNotDefinedError } from "@/lib/errors";
import { TemplateResponseCodes } from "@/lib/oas-tools/https-verb-status-codes";
import { OASComponentsObject, OASResponseObject, OASSchema } from "@/lib/types";
import { toastError } from "@/lib/utils";
import { useCallback } from "react";
import { toast } from "sonner";

export type ComponentSchemaWithInfo = {
  schema: OASSchema;
  name: string;
  dtoSchemas: DtoSchemaWithInfo[];
};

export type ComponentResponseWithInfo = {
  idx?: number;
  responseName: string;
  responseObject: OASResponseObject;
};

export function useComponents(props: EditorInputProps) {
  const { oas, setNewEditorState, setActiveElement } = useAPIEditorTools(props);
  const { getDtoSchemasForSchema } = useSchemaDtos(props);
  if (!oas) throw new DefinitionNotDefinedError();

  const getComponentSchemas = useCallback((): ComponentSchemaWithInfo[] => {
    const entries = Object.entries(oas.components?.schemas || {});
    return entries
      .filter(([, schema]) => schema["x-fiddle-dto-info"] === undefined)
      .map(([componentName, schema], i) => {
        return {
          idx: i,
          schema,
          name: componentName,
          dtoSchemas: getDtoSchemasForSchema(componentName),
        };
      });
  }, [oas.components?.schemas, getDtoSchemasForSchema]);

  const getComponentResponses = useCallback((): ComponentResponseWithInfo[] => {
    const entries = Object.entries(
      getComponentResponsesFromDefinition({ definition: oas })
    );
    return entries.map(([responseName, responseObject], i) => ({
      idx: i,
      responseName,
      responseObject: responseObject as OASResponseObject,
    }));
  }, [oas]);

  const getComponentSchemaOrError = useCallback(
    (modelName: string) => {
      const componentSchemas = getComponentSchemas();
      const found = componentSchemas.find((e) => e.name === modelName);
      if (!found) throw new Error("Component schema not found");
      return found;
    },
    [getComponentSchemas]
  );

  const getComponentResponseOrError = useCallback(
    (responseName: string): ComponentResponseWithInfo => {
      const responseObject = getComponentResponseFromDefinition({
        definition: oas,
        responseName,
      });
      if (!responseObject) throw new Error("Component response not found");
      return { responseObject, responseName };
    },
    [oas]
  );

  const getComponentResponse = useCallback(
    (responseName: string): ComponentResponseWithInfo | undefined => {
      const responseObject = getComponentResponseFromDefinition({
        definition: oas,
        responseName,
      });
      if (!responseObject) return;
      return {
        responseName,
        responseObject: responseObject,
      };
    },
    [oas]
  );

  const addComponentSchema = useCallback(
    ({ schema, name }: { schema: OASSchema; name: string }) => {
      setNewEditorState({
        document_type: props.value.document_type,
        data: addComponentSchemaToDefinition({
          definition: oas,
          name,
          schema,
        }),
      });
    },
    [setNewEditorState, oas, props.value.document_type]
  );

  const addComponentResponse = useCallback(
    ({
      responseObject,
      name,
    }: {
      name: string;
      responseObject: OASResponseObject;
    }) => {
      setNewEditorState({
        document_type: props.value.document_type,
        data: addComponentResponseToDefinition({
          definition: oas,
          name,
          responseObject,
        }),
      });
    },
    [setNewEditorState, oas, props.value.document_type]
  );

  const removeComponentSchema = useCallback(
    (name: string) => {
      setActiveElement(undefined);
      setNewEditorState({
        document_type: props.value.document_type,
        data: removeComponentSchemaFromDefinition({
          definition: oas,
          schemaName: name,
        }),
      });
    },
    [oas, setNewEditorState, setActiveElement, props.value.document_type]
  );

  const removeComponentResponse = useCallback(
    (name: string) => {
      setActiveElement(undefined);
      setNewEditorState({
        document_type: props.value.document_type,
        data: removeComponentResponseFromDefinition({
          definition: oas,
          responseName: name,
        }),
      });
    },
    [setNewEditorState, oas, props.value.document_type, setActiveElement]
  );

  const editComponentSchema = useCallback(
    ({
      oldName,
      newName,
      schema,
    }: {
      oldName: string;
      newName: string;
      schema: OASSchema;
    }) => {
      setNewEditorState({
        document_type: props.value.document_type,
        data: editComponentSchemaInDefinition({
          oldName,
          newName,
          definition: oas,
          newComponentSchema: schema,
        }),
      });
      setActiveElement({ type: "model", modelName: newName });
    },
    [oas, setNewEditorState, props.value.document_type, setActiveElement]
  );

  const editComponentResponse = useCallback(
    ({
      oldName,
      newName,
      responseObject,
    }: {
      oldName: string;
      newName: string;
      responseObject: OASResponseObject;
    }) => {
      try {
        setNewEditorState({
          document_type: props.value.document_type,
          data: editComponentResponseInDefinition({
            oldName,
            newName,
            definition: oas,
            newResponseObject: responseObject,
          }),
        });
        setActiveElement({ type: "response", responseName: newName });
        toast.success("Response edited");
      } catch (err) {
        toastError(err);
      }
    },
    [oas, props.value.document_type, setNewEditorState, setActiveElement]
  );

  const addComponentResponseToOperation = useCallback(
    ({
      operationId,
      responseCode,
      onSuccess,
      skipCheckForExisting = false,
    }: {
      operationId: string;
      responseCode: TemplateResponseCodes;
      onSuccess?: () => unknown;
      skipCheckForExisting?: boolean;
    }) => {
      try {
        const newDefinition = addComponentResponseByCodeToOperationInDefinition(
          {
            operationId,
            responseCode,
            definition: oas,
            skipCheckForExisting,
          }
        );
        setNewEditorState({
          document_type: props.value.document_type,
          data: newDefinition,
        });
        onSuccess?.();
        toast.success("Added as component");
      } catch (err) {
        toastError(err);
      }
    },
    [setNewEditorState, oas, props.value.document_type]
  );

  const componentsObject: OASComponentsObject = oas.components || {};

  return {
    getComponentSchemas,
    getComponentResponses,
    addComponentSchema,
    addComponentResponse,
    editComponentSchema,
    editComponentResponse,
    removeComponentSchema,
    removeComponentResponse,
    getComponentResponseOrError,
    getComponentResponse,
    getComponentSchemaOrError,
    addComponentResponseToOperation,
    componentsObject,
  };
}
