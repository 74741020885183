import { Button } from "@/components/_shadui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/_shadui/tooltip";
import { useActionBarContext } from "@/components/contexts/action-bar-context";
import { useAPIEditorTools } from "@/components/contexts/api-editor-context-hooks";
import {
  DocumentationCard,
  DocumentationCardContent,
  DocumentationCardDescription,
  DocumentationCardHeader,
  DocumentationCardItem,
  DocumentationCardItemContent,
  DocumentationCardItemHeader,
  DocumentationCardItemTitle,
  DocumentationCardMoreButton,
  DocumentationCardTitle,
  DocumentationCardTypeBadge,
} from "@/components/documentation-card";
import {
  EditorItemDescription,
  HideEmptyListWhenNonEditor,
} from "@/components/module-api-editor";
import { SetActiveItem } from "@/components/module-api-editor/editor-preview-operation-active-item";
import {
  EditorInputProps,
  WorkspaceRole,
} from "@/components/module-api-editor/types";
import {
  BtnGroup,
  ComponentButton,
  DeleteButton,
  EditButton,
  LinkButton,
} from "@/components/module-visual-editor/shared-components";
import { useComponentParameters } from "@/hooks/use-component-parameters";
import { ParameterWithInfo, useParameters } from "@/hooks/use-parameters";
import { OperationWithInfo } from "@/lib/editor-mutations/oas-operations";
import { deref, isReference } from "@/lib/oas-tools/oas-tag-helpers";
import { canEdit, DEFAULT_ICON_SIZE_SM, NormIcons } from "@/lib/utils";
import { useMemo } from "react";

export function QueryParametersCard({
  value,
  onChange,
  operationWithInfo,
  workspaceRole,
  setActiveItem,
}: {
  operationWithInfo: OperationWithInfo;
  workspaceRole: WorkspaceRole;
  setActiveItem: SetActiveItem;
} & EditorInputProps) {
  const operation = operationWithInfo.operation;
  const operationId = operation.operationId || "";

  const { getParameters, remove, resolveParameterObject } = useParameters({
    value,
    onChange,
  });
  const { addOrAttatchExistingParameterAsComponent } = useComponentParameters({
    value,
    onChange,
  });
  const [, actionBarDispatch] = useActionBarContext();
  const { setActiveElement } = useAPIEditorTools({ value, onChange });

  const handleRemoveParameter = (parameter: ParameterWithInfo) => {
    // IMPORTANT: Reset active item so path in `QuickEditor`
    // does not become invalid
    setActiveItem(undefined);
    remove({ parameterIdx: parameter.idx, operationId });
  };

  const handleEditClick = (parameterWithInfo: ParameterWithInfo) => {
    actionBarDispatch({
      type: "SET_PAGE",
      payload: {
        name: "edit-parameter",
        onInteractOutside: (e) => {
          e.preventDefault();
        },
        context: {
          operationId,
          parameterWithInfo,
        },
      },
    });
  };

  const handleAdd = () => {
    actionBarDispatch({
      type: "SET_PAGE",
      payload: {
        name: "add-parameter",
        onInteractOutside: (e) => {
          e.preventDefault();
        },
        context: {
          operationId,
          urlPath: operationWithInfo.urlPath,
        },
      },
    });
  };

  const queryParameters = useMemo(
    () => getParameters(operationId, "query"),
    [getParameters, operationId]
  );

  const handleComponentAddClick = () => {
    actionBarDispatch({
      type: "SET_PAGE",
      payload: {
        name: "add-component-parameter-to-operation",
        context: {
          urlPath: operationWithInfo.urlPath,
          operationId,
          type: "query",
        },
      },
    });
  };

  const handleReferenceClick = (refName: string) => {
    setActiveElement({ type: "component-parameter", label: refName });
  };

  const handleAddOrAttachToComponentClick = (parameterIdx: number) => {
    addOrAttatchExistingParameterAsComponent({ operationId, parameterIdx });
  };

  const isEditor = canEdit(workspaceRole);
  return (
    <HideEmptyListWhenNonEditor
      list={queryParameters}
      workspaceRole={workspaceRole}
    >
      <DocumentationCard>
        <DocumentationCardHeader
          onAdd={handleAdd}
          workspaceRole={workspaceRole}
        >
          <DocumentationCardTitle>Query parameters</DocumentationCardTitle>
        </DocumentationCardHeader>
        <DocumentationCardContent>
          {queryParameters.map((queryParameter, i) => {
            const refName = isReference(queryParameter.parameter)
              ? deref(queryParameter.parameter.$ref)
              : undefined;
            const resolved = resolveParameterObject(queryParameter.parameter);
            return (
              <DocumentationCardItem
                key={resolved.name + "-" + "query"}
                onClick={() =>
                  setActiveItem({ type: "parameter", idx: queryParameter.idx })
                }
                isLast={i === queryParameters.length - 1}
              >
                <DocumentationCardItemHeader>
                  <div className="flex gap-2 items-center">
                    <DocumentationCardTypeBadge
                      schema={resolved.schema}
                      isRequired={resolved.required}
                    />
                    {refName && (
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <LinkButton
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              handleReferenceClick(refName);
                            }}
                          />
                        </TooltipTrigger>
                        <TooltipContent>Go to &lt;{refName}&gt;</TooltipContent>
                      </Tooltip>
                    )}
                    <DocumentationCardItemTitle>
                      {resolved.name}
                    </DocumentationCardItemTitle>
                  </div>
                  {isEditor && (
                    <BtnGroup>
                      {!refName && (
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <ComponentButton
                              onClick={(evt) => {
                                evt.stopPropagation();
                                evt.preventDefault();
                                handleAddOrAttachToComponentClick(
                                  queryParameter.idx
                                );
                              }}
                            >
                              <span className="sr-only">
                                Turn into component
                              </span>
                            </ComponentButton>
                          </TooltipTrigger>
                          <TooltipContent>
                            Attach to existing component with the same name or
                            turn into new component.
                          </TooltipContent>
                        </Tooltip>
                      )}
                      <EditButton
                        onClick={() => handleEditClick(queryParameter)}
                      >
                        Edit query parameter
                      </EditButton>
                      <DeleteButton
                        onClick={(evt) => {
                          evt.stopPropagation();
                          handleRemoveParameter(queryParameter);
                        }}
                      >
                        Delte parameter
                      </DeleteButton>
                    </BtnGroup>
                  )}
                </DocumentationCardItemHeader>
                <DocumentationCardItemContent>
                  <DocumentationCardDescription>
                    <EditorItemDescription
                      item={resolved}
                      workspaceRole={workspaceRole}
                      isRef={!!refName}
                      onAddDescriptionClick={() =>
                        handleEditClick(queryParameter)
                      }
                    />
                  </DocumentationCardDescription>
                </DocumentationCardItemContent>
              </DocumentationCardItem>
            );
          })}
          {isEditor && (
            <BtnGroup className="items-end">
              <DocumentationCardMoreButton
                btnTitle="Add new"
                onClick={handleAdd}
              />
              <Button
                role="button"
                size="xs"
                variant="secondary"
                onClick={handleComponentAddClick}
              >
                <NormIcons.Component
                  className="mr-2"
                  size={DEFAULT_ICON_SIZE_SM}
                />
                Add component
              </Button>
            </BtnGroup>
          )}
        </DocumentationCardContent>
      </DocumentationCard>
    </HideEmptyListWhenNonEditor>
  );
}
