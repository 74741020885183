import {
  findComposedKey,
  isArraySchema,
  isComposedSchema,
  isMixedSchema,
  isObjectSchema,
  isReferenceSchema,
  isSimpleSchema,
} from "@/lib/oas-tools/oas-tag-helpers";
import { OASReferenceObject, OASSchema } from "@/lib/types";
import { useCallback } from "react";

export function PreviewReferenceTag({ schema }: { schema: OASSchema }) {
  const Child = useCallback(
    ({ _schema }: { _schema: OASSchema | OASReferenceObject }) => {
      if (isReferenceSchema(_schema))
        throw new Error("Reference schemas cannot point to reference schemas");
      if (isMixedSchema(_schema)) return <span>mixed</span>;
      if (isArraySchema(_schema))
        return (
          <span>
            Array[
            <Child _schema={_schema.items} />]
          </span>
        );
      if (isSimpleSchema(_schema)) return <span>{_schema.type}</span>;
      if (isObjectSchema(_schema)) return <span>object</span>;
      if (isComposedSchema(_schema)) {
        return <span>{findComposedKey(_schema)}</span>;
      }
    },
    []
  );

  return (
    <span className="">
      <Child _schema={schema} />
    </span>
  );
}
