import { UserAvatar } from "@/components";
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/_shadui/drawer";
import { Link } from "@tanstack/react-router";
import { LogOutIcon, UserIcon } from "lucide-react";
import { ComponentProps, PropsWithChildren } from "react";

function SelectList({ children }: PropsWithChildren) {
  return <ul className="flex flex-col items-stretch pt-4">{children}</ul>;
}

function SelectItem({ children, ...rest }: ComponentProps<"li">) {
  return (
    <li
      role="button"
      tabIndex={0}
      className="flex transition-all cursor-pointer rounded-md text-sm hover:bg-ho px-2 py-1.5 aria-selected:bg-accent hover:bg-accent aria-selected:text-accent-foreground data-[disabled=true]:pointer-events-none data-[disabled=true]:opacity-50"
      {...rest}
    >
      {children}
    </li>
  );
}

export function DashboardAvatarDrawer() {
  return (
    <Drawer direction="right">
      <DrawerTrigger>
        <UserAvatar />
      </DrawerTrigger>
      <DrawerContent className="top-0 mt-0 ml-[80%] rounded-t-none rounded-[10px]">
        <DrawerHeader>
          <DrawerTitle>Options</DrawerTitle>
          <SelectList>
            <Link to="/dash/onboarding">
              <SelectItem>
                <UserIcon className="mr-2 h-4 w-4" />
                Go to dashboard
              </SelectItem>
            </Link>
            <Link to="/force-logout">
              <SelectItem>
                <LogOutIcon className="mr-2 h-4 w-4" />
                Logout
              </SelectItem>
            </Link>
          </SelectList>
        </DrawerHeader>
      </DrawerContent>
    </Drawer>
  );
}
