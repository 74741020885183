import { Logo } from "@/components";
import { DashboardAvatarDrawer } from "@/components/avatar-drawer";
import { Link } from "@tanstack/react-router";

export function DashboardHeader({
  organizationSlug,
}: {
  organizationSlug: string;
}) {
  return (
    <div className="flex items-center justify-between py-2">
      <div className="ml-2">
        <Link to="/dash/$organizationSlug/home" params={{ organizationSlug }}>
          <Logo width={80} />
        </Link>
      </div>
      <DashboardAvatarDrawer />
    </div>
  );
}
