import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/_shadui/alert-dialog";
import { Button } from "@/components/_shadui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/_shadui/card";
import { ColorBadge } from "@/components/_shadui/color-badge";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/_shadui/tooltip";
import { H3, H4 } from "@/components/headings";
import { BtnGroup } from "@/components/module-visual-editor/shared-components";
import { companyInfo } from "@/lib/const";
import {
  OrganizationDto,
  UserWorkspaceDto,
} from "@/lib/main-rest-client/definitions";
import {
  canAdmin,
  cn,
  DEFAULT_ICON_SIZE,
  generateWorkspaceName,
  getWorkspaceUrl,
  NormIcons,
  setClipboard,
  slugify,
} from "@/lib/utils";
import {
  useCreateFullWorkspace,
  useDeleteWorkspace,
} from "@/queries/workspaces";
import { Link } from "@tanstack/react-router";
import { ComponentProps } from "react";
import { toast } from "sonner";

// IMPORTANT! Don't unmount this component too early
// the query to create a workspace need to dispatch three queries
// If the component is unmounted early the queries don't complete
function NoWorkspace({ className, ...rest }: ComponentProps<typeof Card>) {
  const createWorkspaceMutation = useCreateFullWorkspace();

  const handleClick = () => {
    const workspaceName = generateWorkspaceName();
    createWorkspaceMutation.mutate({
      createWorkspaceDto: {
        name: workspaceName,
        slug: slugify(workspaceName),
        access_level: "public_read",
      },
    });
  };

  return (
    <Card
      role="button"
      className={cn("hover:bg-accent", className)}
      onClick={handleClick}
      {...rest}
    >
      <CardHeader>
        <CardDescription className="text-center py-8 text-muted-foreground pb-2 flex-col flex items-center gap-2">
          Workspaces organize endpoints for your projects.
          <Button size="sm">
            <NormIcons.Add size={DEFAULT_ICON_SIZE} className="mr-2" />
            Create workspace
          </Button>
        </CardDescription>
      </CardHeader>
    </Card>
  );
}

function WorkspaceCard({
  workspace,
  organizationDto,
}: {
  workspace: UserWorkspaceDto;
  organizationDto: OrganizationDto;
}) {
  const workspaceDeleteMutation = useDeleteWorkspace();

  const handleDelete = (id: string) => {
    workspaceDeleteMutation.mutate({ workspaceId: id });
  };

  const handleCopy = async () => {
    const workspaceLink = getWorkspaceUrl({
      workspaceSlug: workspace.slug,
      organizationSlug: organizationDto.slug,
    });
    await setClipboard(workspaceLink);
    toast.success("Copied workspace url.");
  };

  return (
    <Card className="">
      <CardHeader>
        <div className="flex justify-between pb-4 items-start">
          <div className="pb-8">
            <H3 className="text-muted-foreground pb-2">Workspace</H3>
            <CardTitle className="break-all font-mono pb-2">
              {workspace.name}
            </CardTitle>
          </div>
          <BtnGroup className="gap-2">
            <Link
              to="/dash/$organizationSlug/$workspaceSlug/edit"
              params={{
                workspaceSlug: workspace.slug,
                organizationSlug: organizationDto.slug,
              }}
            >
              <Button variant="ghost" size="icon-sm">
                <NormIcons.Edit size={DEFAULT_ICON_SIZE} />
              </Button>
            </Link>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button size="icon-sm" variant="ghost" onClick={handleCopy}>
                  <NormIcons.Copy size={DEFAULT_ICON_SIZE} />
                </Button>
              </TooltipTrigger>
              <TooltipContent>Copy workspace link</TooltipContent>
            </Tooltip>
            {canAdmin(workspace.role) && (
              <AlertDialog>
                <AlertDialogTrigger asChild>
                  <Button variant="ghost" className="shrink-0" size="icon-sm">
                    <NormIcons.Delete size={DEFAULT_ICON_SIZE} />
                  </Button>
                </AlertDialogTrigger>
                <AlertDialogContent>
                  <AlertDialogHeader>
                    <AlertDialogTitle>Delete this workspace?</AlertDialogTitle>
                    <AlertDialogDescription>
                      This action cannot be undone. This will permanently delete
                      your workspace and remove your data from our servers.
                    </AlertDialogDescription>
                  </AlertDialogHeader>
                  <AlertDialogFooter>
                    <AlertDialogCancel>Cancel</AlertDialogCancel>
                    <AlertDialogAction
                      onClick={() => handleDelete(workspace.id)}
                    >
                      Delete
                    </AlertDialogAction>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
            )}
          </BtnGroup>
        </div>
        <div className="grid grid-cols-2 gap-2">
          <H4 className="text-muted-foreground">Organizaiton slug</H4>
          <div>
            <ColorBadge color="gray">{workspace.organization_slug}</ColorBadge>
          </div>
          <H4 className="text-muted-foreground">Your role</H4>
          <div>
            {workspace.role === "reader" && (
              <ColorBadge color="green">Reader</ColorBadge>
            )}
            {workspace.role === "editor" && (
              <ColorBadge color="blue">Editor</ColorBadge>
            )}
            {workspace.role === "admin" && (
              <ColorBadge color="orange">Admin</ColorBadge>
            )}
          </div>
          <H4 className="text-muted-foreground">Workspace privacy</H4>
          <div>
            {workspace.access_level === "public_write" && (
              <ColorBadge color="yellow">Public write</ColorBadge>
            )}
            {workspace.access_level === "public_read" && (
              <ColorBadge color="blue">Public read</ColorBadge>
            )}
            {workspace.access_level === "private_read" && (
              <ColorBadge color="green">Private read</ColorBadge>
            )}
          </div>
        </div>
      </CardHeader>
      <CardContent>
        <Link
          to="/editor/$organizationSlug/$workspaceSlug"
          params={{
            organizationSlug: workspace.organization_slug,
            workspaceSlug: workspace.slug,
          }}
        >
          <Button className="w-full" variant="outline" size="sm">
            Open
          </Button>
        </Link>
      </CardContent>
    </Card>
  );
}

type WorkspaceListProps = {
  workspaceList: UserWorkspaceDto[];
  organizationDto: OrganizationDto;
};

export function WorkspaceList({
  workspaceList,
  organizationDto,
}: WorkspaceListProps) {
  const createEmptyWorkspaceMutation = useCreateFullWorkspace();

  const hasReachedLimit = workspaceList.length >= 5;

  return (
    <div>
      {/* DO NOT UNMOUNT THIS COMPONENT EARLY */}
      <NoWorkspace className={cn({ hidden: workspaceList.length > 0 })} />
      {/* END */}
      <div className={cn("pt-4", { hidden: workspaceList.length === 0 })}>
        <div className="flex justify-between pb-4">
          <span>
            {hasReachedLimit && (
              <p className="flex gap-2 text-muted-foreground items-center">
                <NormIcons.Info size={DEFAULT_ICON_SIZE} />
                You have reached the maximum of 5 free workspaces.
                <a
                  href={`mailto:${companyInfo.supportEmail}`}
                  className="underline text-brand"
                >
                  {" "}
                  Contact us
                </a>{" "}
                for an upgrade.
              </p>
            )}
          </span>
          <Button
            disabled={hasReachedLimit}
            size="sm"
            variant="primary"
            onClick={() => {
              const id = generateWorkspaceName();
              createEmptyWorkspaceMutation.mutate({
                createWorkspaceDto: {
                  name: id,
                  slug: id,
                  access_level: "public_read",
                },
              });
            }}
          >
            <NormIcons.Add size={DEFAULT_ICON_SIZE} className="mr-2" />
            Create new
          </Button>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
          {workspaceList.map((workspace) => (
            <WorkspaceCard
              key={workspace.id}
              workspace={workspace}
              organizationDto={organizationDto}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
