import {
  Alert,
  AlertDescription,
  AlertTitle,
} from "@/components/_shadui/alert";
import { Button } from "@/components/_shadui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/_shadui/card";
import { BtnGroup } from "@/components/module-visual-editor/shared-components";
import { UserWorkspaceDto } from "@/lib/main-rest-client/definitions";
import { DEFAULT_ICON_SIZE, NormIcons } from "@/lib/utils";
import { useCloneWorkspace, useUserWorkspaces } from "@/queries/workspaces";

export function WorkspaceSettingsCloneCard({
  userWorkspace,
}: {
  userWorkspace: UserWorkspaceDto;
}) {
  const cloneWorkspaceQuery = useCloneWorkspace();
  const userWorkspaces = useUserWorkspaces();

  const handleClick = () => {
    cloneWorkspaceQuery.mutate({ workspaceId: userWorkspace.id });
  };

  if (!userWorkspaces.data) return null;

  const isOutOfWorkspacesForLicense = userWorkspaces.data.length >= 5;

  return (
    <Card>
      <CardHeader>
        <div className="flex justify-between gap-norm">
          <div>
            <CardTitle>Clone workspace</CardTitle>
            <CardDescription>
              Create a cloned version of this workspace and add it to your
              organization.
            </CardDescription>
          </div>
          <BtnGroup className="justify-end">
            <Button
              onClick={handleClick}
              variant="outline"
              disabled={
                cloneWorkspaceQuery.isPending || isOutOfWorkspacesForLicense
              }
            >
              <NormIcons.Add size={DEFAULT_ICON_SIZE} className="mr-2" />
              Clone
            </Button>
          </BtnGroup>
        </div>
      </CardHeader>
      {isOutOfWorkspacesForLicense && (
        <CardContent>
          <Alert>
            <AlertTitle>Heads up!</AlertTitle>
            <AlertDescription>
              You've reached the maximum number of workspaces for this
              organization.
            </AlertDescription>
          </Alert>
        </CardContent>
      )}
    </Card>
  );
}
