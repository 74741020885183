import { apiClient } from "@/lib/http-utils";
import {
  AcceptWorkspaceInvitationDto,
  CreateWorkspaceInvitationDto,
} from "@/lib/main-rest-client/definitions";
import { userWorkspaceKeys, workspaceKeys } from "@/queries/workspaces";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { toast } from "sonner";

export function useCreateWorkspaceInvitation() {
  return useMutation<unknown, AxiosError, CreateWorkspaceInvitationDto>({
    mutationFn: async (createWorkspaceInvitationDto) => {
      return (
        await apiClient.createWorkspaceInvitation(createWorkspaceInvitationDto)
      ).data;
    },
    onSuccess: () => {
      toast.success("Invitation created");
    },
  });
}

export function useAcceptWorkspaceInvitaiton() {
  const queryClient = useQueryClient();
  return useMutation<void, AxiosError, AcceptWorkspaceInvitationDto>({
    mutationFn: async (acceptWorkspaceInvitationDto) => {
      return (
        await apiClient.acceptWorkspaceInvitaiton(acceptWorkspaceInvitationDto)
      ).data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: workspaceKeys.all });
      queryClient.invalidateQueries({ queryKey: userWorkspaceKeys.all });
      toast.success("Invitation accepted");
    },
    onError: () => {
      toast.error("Oh no! We were unable to process your invitation");
    },
  });
}
