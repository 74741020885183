import { ReloadIcon } from "@radix-ui/react-icons";

import { Button } from "@/components/_shadui/button";
import { ComponentProps } from "react";
import { cn } from "@/lib/utils";
import { SubmitButton } from "@/components/module-visual-editor/shared-components";

export function ButtonLoading({
  isLoading,
  children,
  ...rest
}: ComponentProps<typeof Button> & { isLoading?: boolean }) {
  return (
    <SubmitButton {...rest}>
      <ReloadIcon
        aria-busy={!!isLoading}
        className={cn("hidden mr-2 h-4 w-4 animate-spin", {
          hidden: !isLoading,
        })}
      />
      {children}
    </SubmitButton>
  );
}
