import {
  getTypeOptions,
  simplePropertyTypes,
  TypeOptions,
} from "@/lib/oas-tools/oas-schema-utils";
import {
  findComposedKey,
  isComposedSchema,
  isReferenceSchema,
} from "@/lib/oas-tools/oas-tag-helpers";
import {
  OASArraySchemaObject,
  OASComponentsObject,
  OASMixedSchemaObject,
  OASNonArraySchemaObject,
  OASReferenceObject,
  OASSchema,
} from "@/lib/types";
import { globalVariables } from "../const";

export const propertyTypes = [
  ...simplePropertyTypes,
  "array",
  "object",
] as const;
export type PropertyTypes = (typeof propertyTypes)[number];

export const composedPropertyTypes = ["allOf", "anyOf", "oneOf"] as const;
export type ComposedPropertyTypes = (typeof composedPropertyTypes)[number];

export const allPropertyTypes = [
  ...propertyTypes,
  ...composedPropertyTypes,
] as const;
export type AllPropertyTypes = (typeof allPropertyTypes)[number];
export type PadProperty = "first" | "last" | undefined;

export type OASSchemaRowType =
  | "reference-row"
  | "mixed-row"
  | "composed-row"
  | "simple-row"
  | "array-row"
  | "object-row";

export type OASSchemaRow = {
  kind: "oas-schema-row";
  typeOptions: TypeOptions; // What types can this row be changed to
  onDelete?: () => unknown;
  rowType: OASSchemaRowType;
  schema: OASSchema | OASReferenceObject;
  level: number;
  propertyName?: string;
  path: string;
  isRequired: boolean;
  /**
   * In some cases we hide the required toggle. We also disallow users from using the
   * required toggle.
   *
   * Example: jobs = allOf<object<Value1> OR array<Value1>>. Jobs is required + inherits
   * required tag to its subrows. However, showing the required badge on subrows is confusing.
   */
  hideRequiredToggle: boolean;
  isRemovable: boolean;
  rootSchema: OASSchema;
  isPlaceholder: boolean;
  isDisabled: boolean;
  isReadOnly: boolean;
  padProperty: PadProperty; // used to render the object tree
  extraInsetLevel: number;
};

type OASSchemaRowConfig<T extends OASSchema = OASSchema> = {
  kind: "oas-schema-row";
  schema: T;
  level: number;
  propertyName?: string;
  path: string;
  isRequired: boolean;
  /**
   * In some cases we hide the required toggle. We also disallow users from using the
   * required toggle.
   *
   * Example: jobs = allOf<object<Value1> OR array<Value1>>. Jobs is required + inherits
   * required tag to its subrows. However, showing the required badge on subrows is confusing.
   */
  hideRequiredToggle: boolean;
  isRemovable: boolean;
  rootSchema: OASSchema;
  isPlaceholder: boolean;
  isDisabled: boolean;
  padProperty: PadProperty; // used to render the object tree
  extraInsetLevel: number;
};

function addPropertyNameToPath(path: string, propertyName?: string) {
  if (path.startsWith(".")) path = path.slice(1);
  if (propertyName && path === "") return `properties.${propertyName}`;
  return propertyName != null ? `${path}.properties.${propertyName}` : path;
}

export function generateOASSchemaRows({
  schema,
  isDisabled,
  initialLevel = 0,
  componentsObject = {},
  options: { allowTopLevelReferences },
  extraInsetLevel = 0,
  isReadOnly,
}: {
  schema: OASSchema;
  isDisabled: boolean;
  isReadOnly: boolean;
  initialLevel: number;
  componentsObject?: OASComponentsObject;
  options: {
    // in most places the editor accepts OASchema & OASReferenceObject/
    // this is not true for all places. e.g. 'components.schemas' only
    // accepts OASSchema. Since the editor does not now what OAS property
    // is edited, we manually have to configure if the top-most object can
    // point to a reference.
    allowTopLevelReferences: boolean;
  };
  extraInsetLevel?: number;
}) {
  const rows: OASSchemaRow[] = [];

  function addReferenceRow({
    level,
    propertyName,
    path,
    schema,
    isDisabled,
    rootSchema,
    ...rest
  }: OASSchemaRowConfig<OASReferenceObject>) {
    const newPath = addPropertyNameToPath(path, propertyName);
    const typeOptions = getTypeOptions({
      rootSchema: rootSchema,
      schema,
      path: newPath,
      componentsObject,
      allowTopLevelReferences,
    });
    rows.push({
      level,
      propertyName: propertyName,
      path: newPath,
      rowType: "reference-row",
      schema: schema,
      typeOptions,
      isDisabled,
      isReadOnly,
      rootSchema,
      ...rest,
    });
  }

  function addComposedRow({
    level,
    propertyName,
    path,
    schema,
    isDisabled,
    rootSchema,
    ...rest
  }: OASSchemaRowConfig<OASNonArraySchemaObject>) {
    const newPath = addPropertyNameToPath(path, propertyName);
    const typeOptions = getTypeOptions({
      rootSchema,
      schema,
      path: newPath,
      componentsObject,
      allowTopLevelReferences,
    });
    rows.push({
      level,
      propertyName,
      path: newPath,
      rowType: "composed-row",
      schema,
      typeOptions,
      rootSchema,
      isDisabled,
      isReadOnly,
      ...rest,
    });
  }

  function addMixedTypeRow({
    level,
    propertyName,
    path,
    schema,
    isDisabled,
    rootSchema,
    ...rest
  }: OASSchemaRowConfig<OASMixedSchemaObject>) {
    const newPath = addPropertyNameToPath(path, propertyName);
    const typeOptions = getTypeOptions({
      rootSchema,
      schema,
      path: newPath,
      componentsObject,
      allowTopLevelReferences,
    });
    rows.push({
      level,
      propertyName,
      path: newPath,
      schema,
      rowType: "mixed-row",
      typeOptions,
      isDisabled,
      isReadOnly,
      rootSchema,
      ...rest,
    });
  }

  function addArrayRow({
    level,
    propertyName,
    path,
    schema,
    isDisabled,
    rootSchema,
    ...rest
  }: OASSchemaRowConfig<OASArraySchemaObject>) {
    const newPath = addPropertyNameToPath(path, propertyName);
    const typeOptions = getTypeOptions({
      rootSchema,
      schema,
      path: newPath,
      componentsObject,
      allowTopLevelReferences,
    });
    rows.push({
      level,
      propertyName,
      path: newPath,
      schema,
      rowType: "array-row",
      typeOptions,
      isDisabled,
      isReadOnly,
      rootSchema,
      ...rest,
    });
  }

  function addObjectRow({
    level,
    propertyName,
    path,
    schema,
    isDisabled,
    rootSchema,
    ...rest
  }: OASSchemaRowConfig<OASNonArraySchemaObject>) {
    const newPath = addPropertyNameToPath(path, propertyName);
    const typeOptions = getTypeOptions({
      rootSchema,
      schema,
      path: newPath,
      componentsObject,
      allowTopLevelReferences,
    });
    rows.push({
      level,
      propertyName,
      path: newPath,
      schema,
      rowType: "object-row",
      typeOptions,
      isDisabled,
      isReadOnly,
      rootSchema,
      ...rest,
    });
  }

  function addSimpleRow({
    level,
    propertyName,
    path,
    schema,
    rootSchema,
    isDisabled,
    ...rest
  }: OASSchemaRowConfig<OASNonArraySchemaObject>) {
    const newPath = addPropertyNameToPath(path, propertyName);
    const typeOptions = getTypeOptions({
      rootSchema,
      schema,
      path: newPath,
      componentsObject,
      allowTopLevelReferences,
    });
    rows.push({
      level,
      propertyName,
      path: newPath,
      schema,
      rowType: "simple-row",
      typeOptions,
      rootSchema,
      isDisabled,
      isReadOnly,
      ...rest,
    });
  }

  function getRequiredFields(schema: OASSchema): string[] {
    return schema.required || [];
  }

  function getPadPropertyFromArr(
    arr: Array<unknown>,
    idx: number
  ): PadProperty {
    if (idx === arr.length - 1) return "last";
    if (idx === 0) return "first";
    return undefined;
  }

  function resolveObjectRows({
    schema,
    level,
    path,
    propertyName,
    isRequired: _isRequired,
    padProperty,
    ...rest
  }: OASSchemaRowConfig<OASNonArraySchemaObject>) {
    // objects are allowed to be empty
    if (!schema.properties) return;
    const requiredFields = getRequiredFields(schema);
    const newPath = addPropertyNameToPath(path, propertyName);
    const entries = Object.entries(schema.properties).sort(([keyA], [keyB]) => {
      if (keyA.startsWith(globalVariables.editorPlaceholderToken)) return 1;
      if (keyA.toLowerCase() === "id" || keyA.toLowerCase() === "uuid")
        return -1;
      return keyA > keyB ? 1 : -1;
    });
    let i = 0;
    for (const [key, value] of entries) {
      rootRow({
        propertyName: key,
        level: level + 1,
        schema: value,
        path: newPath,
        isRequired: requiredFields.includes(key),
        padProperty: padProperty || getPadPropertyFromArr(entries, i),
        ...rest,
      });
      i++;
    }
  }

  function resolveArrayRows({
    schema,
    level,
    path,
    propertyName,
    padProperty,
    ...rest
  }: OASSchemaRowConfig<OASArraySchemaObject>) {
    const newPath = addPropertyNameToPath(path, propertyName);
    const options = {
      propertyName: undefined, // Array rows cannot have proeprty type
      level: level + 1,
      path: newPath + ".items",
      ...rest,
    };

    const _padProperty = padProperty;
    if (!schema.items || "$ref" in schema.items) return;
    if (schema.items.oneOf || schema.items.allOf || schema.items.anyOf) {
      resolveComposedRows({
        schema: schema.items as OASNonArraySchemaObject,
        padProperty: _padProperty,
        ...options,
      });
      return;
    }
    if (!schema.items.type) {
      throw new Error("Array items have no type");
    }
    if (typeof schema.items.type === "string") {
      if (schema.items.type === "object") {
        resolveObjectRows({
          schema: schema.items,
          padProperty: _padProperty,
          ...options,
        });
        return;
      } else if (schema.items.type === "array") {
        resolveArrayRows({
          schema: schema.items,
          padProperty: _padProperty,
          ...options,
        });
        return;
      }
    }
  }

  function resolveComposedRows({
    schema,
    level,
    path,
    propertyName,
    hideRequiredToggle: _,
    ...rest
  }: OASSchemaRowConfig<OASNonArraySchemaObject>) {
    const newPath = addPropertyNameToPath(path, propertyName);
    const composedKey = findComposedKey(schema);
    if (!composedKey) throw new Error("Schema is not composed");
    let i = 0;
    for (const nestedSchema of schema[composedKey]!) {
      rootRow({
        schema: nestedSchema,
        path: `${newPath}.${composedKey}[${i}]`,
        level: level + 1,
        hideRequiredToggle: true,
        ...rest,
      });
      i++;
    }
  }

  function rootRow({
    schema,
    hideRequiredToggle: _hideRequiredToggle,
    ...rest
  }: OASSchemaRowConfig<OASSchema | OASReferenceObject>) {
    // the root object is not removable
    // instead there's a separate action (onRemoveRootRow) that fires
    // when we try to delete the root row
    rest.isRemovable = !rest.path && !rest.propertyName ? false : true;
    if (isReferenceSchema(schema)) {
      addReferenceRow({
        schema: schema as OASReferenceObject,
        hideRequiredToggle: rest.propertyName ? false : true,
        ...rest,
      });
      return;
    }
    if (isComposedSchema(schema)) {
      addComposedRow({
        schema: schema as OASNonArraySchemaObject,
        hideRequiredToggle: true,
        ...rest,
      });
      return resolveComposedRows({
        schema: schema as OASNonArraySchemaObject,
        hideRequiredToggle: true,
        ...rest,
      });
    }

    if (!("type" in schema)) throw new Error("Schema has no type property");
    if (Array.isArray(schema.type)) {
      addMixedTypeRow({
        schema: schema as OASMixedSchemaObject,
        hideRequiredToggle: false,
        ...rest,
      });
      return;
    }

    switch (schema.type) {
      case "array":
        addArrayRow({
          schema,
          ...rest,
          hideRequiredToggle: rest.propertyName ? false : true,
        });
        resolveArrayRows({
          schema,
          hideRequiredToggle: true,
          ...rest,
        });
        break;
      case "object":
        addObjectRow({
          schema,
          hideRequiredToggle: rest.propertyName ? false : true,
          ...rest,
        });
        resolveObjectRows({
          schema,
          hideRequiredToggle: false,
          ...rest,
        });
        break;
      case "boolean":
      case "integer":
      case "null":
      case "number":
      case "string":
        addSimpleRow({
          schema,
          hideRequiredToggle: rest.propertyName ? false : true,
          ...rest,
        });
        break;
    }
  }

  rootRow({
    kind: "oas-schema-row",
    schema,
    level: initialLevel,
    path: "",
    isRequired: false,
    hideRequiredToggle: true,
    isRemovable: true,
    rootSchema: schema,
    isPlaceholder: false,
    isDisabled: !!isDisabled,
    padProperty: undefined,
    extraInsetLevel,
  });

  return rows;
}
