import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/_shadui/form";
import { Input } from "@/components/_shadui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/_shadui/select";
import { Switch } from "@/components/_shadui/switch";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/components/_shadui/tabs";
import { Textarea } from "@/components/_shadui/textarea";
import { FormLabelRequired } from "@/components/form-label-required";
import { SchemaEditor } from "@/components/module-schema-editor/schema-editor";
import {
  BtnGroup,
  SubmitButton,
} from "@/components/module-visual-editor/shared-components";
import { SchemaEditorPreviewContainer } from "@/components/preview-container";
import { generateExampleFromQueryParameter } from "@/lib/oas-tools/generate-example-from-schema";
import {
  getPristineType,
  supportedQueryParameterFormats,
} from "@/lib/oas-tools/oas-schema-utils";
import { appRegex } from "@/lib/regex";
import {
  OASComponentsObject,
  OASParameterObject,
  OASReferenceObject,
  OASSchema,
  SupportedQuerySerializationStyles,
} from "@/lib/types";
import { zodResolver } from "@hookform/resolvers/zod";
import merge from "lodash/merge";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";
import { ActionBarContent } from "./action-bar-content";
import {
  ActionBarFieldGroup,
  ActionBarForm,
  ActionBarFormTitle,
} from "./elements";

const supportedQueryStylesHelpText: Record<
  SupportedQuerySerializationStyles,
  string
> = {
  deepObject:
    "Use index syntax to define object properties. This style applies to schemas of type 'object'. ",
  form: "(Default) Use form-urlencoded syntax to serialize values.",
  pipeDelimited:
    "Separate individual values with pipe characters. Requires 'explode' set to false. This style applies to array-based schemas.",
  spaceDelimited:
    "Separate individual values with spaces. Requires explode=false. This style applies to array-based schemas.",
};

const formValues = z.object({
  required: z.boolean(),
  explode: z.boolean().default(false),
  name: z
    .string()
    .regex(appRegex.parameterName.expression, appRegex.parameterName.message),
  in: z.enum(["query", "path"]),
  style: z.enum(supportedQueryParameterFormats),
  description: z.string().trim(),
  schema: z.record(z.string(), z.any()) as z.ZodType<
    OASSchema | OASReferenceObject
  >,
});
export type ParameterFormValues = z.infer<typeof formValues>;

export function ParameterForm({
  defaultValues,
  onSubmit,
  title,
  componentsObject,
  btnTitle,
}: {
  defaultValues: Partial<ParameterFormValues>;
  onSubmit: (values: ParameterFormValues) => unknown;
  title: string;
  componentsObject: OASComponentsObject;
  btnTitle: "Create" | "Edit";
}) {
  const whereIn = defaultValues.in || "query";
  const form = useForm<ParameterFormValues>({
    resolver: zodResolver(formValues),
    defaultValues: merge(
      {
        required: true,
        explode: false,
        name: "",
        style: "form",
        in: whereIn,
        description: "",
        schema: getPristineType("string"),
      },
      defaultValues
    ),
  });
  const style = form.watch("style");
  const explode = form.watch("explode");
  const name = form.watch("name");
  const schema = form.watch("schema");
  const preview = useMemo(() => {
    const values = form.getValues();
    try {
      return generateExampleFromQueryParameter({
        parameter: {
          name,
          explode,
          style,
          schema: schema as OASParameterObject["schema"],
          required: values.required,
          in: whereIn,
        },
      });
    } catch (err) {
      if (err instanceof Error) return err.message;
      toast.error("Unable to generate preview");
      return undefined;
    }
  }, [whereIn, form, style, explode, name, schema]);

  return (
    <ActionBarContent>
      <Form {...form}>
        <ActionBarForm
          onSubmit={(e) => {
            form.handleSubmit(onSubmit)(e);
          }}
        >
          <ActionBarFormTitle>{title}</ActionBarFormTitle>
          {preview && (
            <SchemaEditorPreviewContainer title="Preview">
              {preview}
            </SchemaEditorPreviewContainer>
          )}
          <ActionBarFieldGroup>
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    <FormLabelRequired />
                    Parameter name
                  </FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="flex gap-2 items-center">
                    Description
                  </FormLabel>
                  <FormControl>
                    <Textarea {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </ActionBarFieldGroup>
          <Tabs defaultValue="schema">
            <TabsList>
              <TabsTrigger value="schema">Schema</TabsTrigger>
              <TabsTrigger value="form">Serialization</TabsTrigger>
            </TabsList>
            <TabsContent value="schema" className="h-[320px]">
              <SchemaEditorPreviewContainer title="Schema">
                <SchemaEditor
                  value={form.getValues()["schema"]}
                  onChange={(schema) => form.setValue("schema", schema)}
                  allowTopLevelReferences={true}
                  onRemoveRootSchema={() =>
                    form.setValue("schema", getPristineType("string"))
                  }
                  componentsObject={componentsObject}
                />
              </SchemaEditorPreviewContainer>
            </TabsContent>
            <TabsContent value="form" className="min-h-[320px]">
              <ActionBarFieldGroup>
                <FormField
                  control={form.control}
                  name="required"
                  render={({ field }) => {
                    return (
                      <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4">
                        <div className="space-y-0.5">
                          <FormLabel className="text-base">Required</FormLabel>
                          <FormDescription>
                            Paramter must be present
                          </FormDescription>
                        </div>
                        <FormControl>
                          <Switch
                            checked={field.value}
                            onCheckedChange={field.onChange}
                          />
                        </FormControl>
                      </FormItem>
                    );
                  }}
                />
                <FormField
                  control={form.control}
                  name="style"
                  render={({ field }) => {
                    return (
                      <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4 gap-2">
                        <div className="space-y-0.5 basis-[400px]">
                          <FormLabel className="text-base">Style</FormLabel>
                          <FormDescription>
                            {supportedQueryStylesHelpText[style]}
                          </FormDescription>
                        </div>
                        <Select
                          onValueChange={field.onChange}
                          defaultValue={field.value}
                        >
                          <FormControl>
                            <SelectTrigger className="basis-[150px] grow-0 shrink-0">
                              <SelectValue placeholder="Select style" />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {supportedQueryParameterFormats.map((f) => (
                              <SelectItem value={f} key={f}>
                                {f}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </FormItem>
                    );
                  }}
                />
                <FormField
                  control={form.control}
                  name="explode"
                  render={({ field }) => {
                    return (
                      <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4 gap-2">
                        <div className="space-y-0.5 basis-[300px]">
                          <FormLabel className="text-base">Explode</FormLabel>
                          <FormDescription>
                            Modifies the serialization style. Only applies to
                            parameters of type object and array.
                          </FormDescription>
                        </div>
                        <FormControl>
                          <Switch
                            checked={field.value}
                            onCheckedChange={field.onChange}
                          />
                        </FormControl>
                      </FormItem>
                    );
                  }}
                />
              </ActionBarFieldGroup>
            </TabsContent>
          </Tabs>
          <BtnGroup className="justify-end">
            <SubmitButton>{btnTitle}</SubmitButton>
          </BtnGroup>
        </ActionBarForm>
      </Form>
    </ActionBarContent>
  );
}
