import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/_shadui/form";
import { Input } from "@/components/_shadui/input";
import { ButtonLoading } from "@/components/button-loading";
import { FormLabelRequired } from "@/components/form-label-required";
import { BtnGroup } from "@/components/module-visual-editor/shared-components";
import { FieldGroup } from "@/forms";
import { usePatchUser } from "@/queries/users";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";

const formSchema = z.object({
  username: z.string(),
});

type FormValues = z.infer<typeof formSchema>;

function BaseForm({
  form,
  onSubmit,
  isLoading,
}: {
  form: ReturnType<typeof useForm<FormValues>>;
  onSubmit: SubmitHandler<FormValues>;
  isLoading?: boolean;
}) {
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <FieldGroup className="pb-4">
          <FormField
            control={form.control}
            name="username"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  <FormLabelRequired />
                  Full name
                </FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </FieldGroup>
        <BtnGroup className="justify-end">
          <ButtonLoading type="submit" isLoading={isLoading}>
            Save
          </ButtonLoading>
        </BtnGroup>
      </form>
    </Form>
  );
}

export function FormPatchUser({
  defaultValues,
  userId,
}: {
  defaultValues: Partial<FormValues>;
  userId: string;
}) {
  const patchUserMutation = usePatchUser();
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues,
  });

  const onSubmit: SubmitHandler<FormValues> = async (values) => {
    try {
      await patchUserMutation.mutateAsync({ userId, ...values });
      toast.success("Updated successfully!");
    } catch (errors) {
      form.setError("username", { message: "Invalid data" });
    }
  };

  return (
    <BaseForm
      form={form}
      onSubmit={onSubmit}
      isLoading={patchUserMutation.isPending}
    />
  );
}
