import { ColorBadge } from "@/components/_shadui/color-badge";
import { useActionBarContext } from "@/components/contexts/action-bar-context";
import { ActiveElement } from "@/components/contexts/api-editor-context";
import { DocumentationCardDescription } from "@/components/documentation-card";
import { EditorItemDescription } from "@/components/module-api-editor";
import { Toolbar } from "@/components/module-api-editor/toolbar";
import {
  EditorInputProps,
  WorkspaceRole,
} from "@/components/module-api-editor/types";
import {
  BtnGroup,
  DeleteButton,
  EditButton,
} from "@/components/module-visual-editor/shared-components";
import { SchemaQuickEditor } from "@/components/schema-quick-editor";
import { useComponents } from "@/hooks/use-components";
import {
  AllPropertyTypes,
  getResponseTypeName,
} from "@/lib/oas-tools/oas-schema-utils";
import { typeColorMap } from "@/lib/oas-tools/style-helpers";
import { SupportedContentFormats } from "@/lib/types";
import { canEdit } from "@/lib/utils";
import { useMemo } from "react";

export function EditorPreviewResponse({
  value,
  onChange,
  activeElement,
  hasOperations,
  workspaceSlug,
  organizationSlug,
  isAuthed,
  workspaceRole,
}: {
  isAuthed: boolean;
  workspaceSlug: string | undefined;
  organizationSlug: string | undefined;
  activeElement: Extract<ActiveElement, { type: "response" }>;
  hasOperations: boolean;
  workspaceRole: WorkspaceRole;
} & EditorInputProps) {
  const [, actionBarDispatch] = useActionBarContext();

  const { getComponentResponseOrError, removeComponentResponse } =
    useComponents({
      value,
      onChange,
    });

  const { responseName, responseObject } = useMemo(
    () => getComponentResponseOrError(activeElement.responseName),
    [activeElement.responseName, getComponentResponseOrError]
  );

  const typeName = getResponseTypeName(responseObject);
  const format: SupportedContentFormats = "application/json";

  const color = typeColorMap[typeName as AllPropertyTypes] || "blue";

  const handleEditClick = () => {
    actionBarDispatch({
      type: "SET_PAGE",
      payload: {
        name: "edit-component-response",
        context: {
          responseName,
          responseObject,
        },
      },
    });
  };

  const handleRemoveClick = () => {
    removeComponentResponse(responseName);
  };

  const isEditor = canEdit(workspaceRole);
  return (
    <div className="max-w-screen-xl px-5">
      <div className="flex justify-end pb-3">
        <Toolbar
          hasOperations={hasOperations}
          isAuthed={isAuthed}
          onChange={onChange}
          value={value}
          workspaceSlug={workspaceSlug}
          organizationSlug={organizationSlug}
          workspaceRole={workspaceRole}
        />
      </div>
      <div className="grow flex flex-col gap-4 items-stretch pt-2">
        <div className="flex justify-between pb-1">
          <div className="flex gap-4">
            <ColorBadge color={color}>{typeName}</ColorBadge>{" "}
            <p>{responseName}</p>
          </div>
          {isEditor && (
            <BtnGroup>
              <EditButton onClick={handleEditClick} />
              <DeleteButton onClick={handleRemoveClick} />
            </BtnGroup>
          )}
        </div>
      </div>
      <div className="pt-3">
        <DocumentationCardDescription className="">
          <EditorItemDescription
            item={responseObject}
            onAddDescriptionClick={handleEditClick}
            workspaceRole={workspaceRole}
          />
        </DocumentationCardDescription>
        <SchemaQuickEditor
          onChange={onChange}
          value={value}
          title={`${responseName} schema`}
          className=" min-h-[80px] w-full mt-norm"
          path={`components.responses.${responseName}.content.${format}.schema`}
          allowTopLevelReferences={true}
          workspaceRole={workspaceRole}
        />
      </div>
    </div>
  );
}
