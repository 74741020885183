import { MetadataFormValues } from "@/components/module-action-bar/metadata-form";
import { OASDefinition } from "@/lib/types";
import cloneDeep from "lodash/cloneDeep";
import set from "lodash/set";

export function editMetadaInDefinition(
  definition: Readonly<OASDefinition>,
  { description, title }: MetadataFormValues
): OASDefinition {
  const cp = cloneDeep(definition);

  set(cp, "info.title", title);
  set(cp, "info.description", description);

  return cp;
}
