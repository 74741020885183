import {
  createContext,
  Dispatch,
  PropsWithChildren,
  useContext,
  useMemo,
  useReducer,
} from "react";

export type Action = {
  type: "SET_SHOW_NESTED_COMPONENTS";
  payload: boolean;
};

export type State = {
  showNestedComponents: boolean;
};

const defaultState: State = {
  showNestedComponents: false,
};

const PreviewContext = createContext<
  readonly [State, Dispatch<Action>] | undefined
>(undefined);

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "SET_SHOW_NESTED_COMPONENTS":
      return {
        ...state,
        showNestedComponents: action.payload,
      };
    default:
      const _actionType: never = action.type;
      throw new Error(
        `Unknown action dispatched in OperationPreviewContext with type: ${_actionType}`
      );
  }
}

export function PreviewProvider({
  children,
  initialState,
}: PropsWithChildren<{
  initialState?: Partial<State>;
}>) {
  const [state, dispatch] = useReducer(reducer, {
    ...defaultState,
    ...initialState,
  });

  const value = useMemo(() => [state, dispatch] as const, [state]);
  return (
    <PreviewContext.Provider value={value}>{children}</PreviewContext.Provider>
  );
}

export function usePreviewContext() {
  const ctx = useContext(PreviewContext);
  if (!ctx)
    throw new Error(
      "Trying to use OperationPreviewContext outside OperationPreviewProvider"
    );
  return ctx;
}
