import { ColorBadge } from "@/components/_shadui/color-badge";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/_shadui/tooltip";
import { DEFAULT_ICON_SIZE_SM, NormIcons } from "@/lib/utils";

export function ErrorFieldValue({
  tooltipMessage,
}: {
  tooltipMessage: string;
}) {
  return (
    <Tooltip>
      <TooltipTrigger>
        <ColorBadge color="red">
          <NormIcons.Error size={DEFAULT_ICON_SIZE_SM} /> Error
        </ColorBadge>
      </TooltipTrigger>
      <TooltipContent>{tooltipMessage}</TooltipContent>
    </Tooltip>
  );
}
