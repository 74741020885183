import {
  Command,
  CommandGroup,
  CommandItem,
  CommandList,
} from "@/components/_shadui/command";
import { Dialog } from "@/components/_shadui/dialog";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/_shadui/popover";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/components/_shadui/tabs";
import { ChangeTypeOption } from "@/components/module-schema-editor/useChangeType";
import { TypeOptions } from "@/lib/oas-tools/oas-schema-utils";
import { ComponentProps, PropsWithChildren } from "react";

interface SelectTypeModalProps extends ComponentProps<typeof Dialog> {
  typeOptions: TypeOptions;
  onSelect: (optionsName: ChangeTypeOption) => unknown;
  isDisabled: boolean;
  defaultTab?: "propertyTypes" | "schemas" | "dtos";
  isModelsDisabled: boolean;
}
export function SelectTypeModal({
  children,
  typeOptions,
  onSelect,
  onOpenChange,
  isDisabled,
  open,
  defaultTab = "propertyTypes",
  isModelsDisabled,
  ...rest
}: PropsWithChildren<SelectTypeModalProps>) {
  const isOpen = isDisabled ? false : open;
  const { propertyTypes, dtos, schemas } = typeOptions;
  return (
    <Dialog {...rest} open={isOpen}>
      <Popover onOpenChange={onOpenChange} open={isOpen} modal={true}>
        <PopoverTrigger asChild>{children}</PopoverTrigger>
        <PopoverContent className="px-0 py-1">
          <Tabs defaultValue={defaultTab}>
            <TabsList className="overflow-y-hidden">
              {propertyTypes.length > 0 && (
                <TabsTrigger className="text-xs px-1" value="propertyTypes">
                  Types
                </TabsTrigger>
              )}
              {schemas.length > 0 && (
                <TabsTrigger className="text-xs px-1" value="schemas">
                  Models
                </TabsTrigger>
              )}
              {dtos.length > 0 && (
                <TabsTrigger className="text-xs px-1" value="dtos">
                  DTOs
                </TabsTrigger>
              )}
            </TabsList>
            <TabsContent value="propertyTypes">
              <Command>
                <CommandList className="h-[200px]">
                  <CommandGroup>
                    {propertyTypes.map((t) => (
                      <CommandItem
                        key={t}
                        onSelect={() => {
                          onSelect({ type: "type", typeOption: t });
                          onOpenChange && onOpenChange(false);
                        }}
                      >
                        <span>{t}</span>
                      </CommandItem>
                    ))}
                  </CommandGroup>
                </CommandList>
              </Command>
            </TabsContent>
            <TabsContent value="schemas">
              {isModelsDisabled ? (
                <small className="text-muted-foreground">
                  Models cannot be used here
                </small>
              ) : schemas.length > 0 ? (
                <Command>
                  <CommandList className="h-[200px]">
                    <CommandGroup>
                      {schemas.map((schemaWithInfo) => (
                        <CommandItem
                          key={schemaWithInfo.name}
                          onSelect={() => {
                            onSelect({
                              type: "model",
                              componentId: {
                                type: "schema",
                                id: schemaWithInfo.name,
                              },
                            });
                            onOpenChange && onOpenChange(false);
                          }}
                        >
                          <span>{schemaWithInfo.name}</span>
                        </CommandItem>
                      ))}
                    </CommandGroup>
                  </CommandList>
                </Command>
              ) : (
                <small className="text-muted-foreground">
                  Models show up here after you create them
                </small>
              )}
            </TabsContent>
            <TabsContent value="dtos">
              {isModelsDisabled ? (
                <small className="text-muted-foreground">
                  Models cannot be used here
                </small>
              ) : dtos.length > 0 ? (
                <Command>
                  <CommandList className="h-[200px]">
                    <CommandGroup>
                      {dtos.map((dtoWithInfo) => (
                        <CommandItem
                          key={dtoWithInfo.name}
                          onSelect={() => {
                            onSelect({
                              type: "model",
                              componentId: {
                                type: "dto",
                                id: dtoWithInfo.name,
                              },
                            });
                            onOpenChange && onOpenChange(false);
                          }}
                        >
                          <span>{dtoWithInfo.name}</span>
                        </CommandItem>
                      ))}
                    </CommandGroup>
                  </CommandList>
                </Command>
              ) : (
                <small className="text-muted-foreground">
                  Models show up here after you create them
                </small>
              )}
            </TabsContent>
          </Tabs>
        </PopoverContent>
      </Popover>
    </Dialog>
  );
}
