import { useCallback } from "react";
import { renderToString } from "react-dom/server";
import html2canvas from "html2canvas";
import { ExportCard } from "@/components/export-card";

interface ExportOptions {
  apiDefinition: string;
  cardWidth: number;
  cardPadding: number;
  backgroundPadding: number;
}

export const useExportOperationAsPng = () => {
  const exportAsPng = useCallback(
    async ({
      apiDefinition,
      cardWidth = 800,
      cardPadding = 20,
      backgroundPadding = 100,
    }: ExportOptions) => {
      // Create a temporary container
      const container = document.createElement("div");
      container.style.position = "absolute";
      container.style.left = "-9999px";
      container.style.top = "-9999px";
      document.body.appendChild(container);

      // Render the card using JSX and ReactDOMServer
      const cardHtml = renderToString(
        <ExportCard
          apiDefinition={apiDefinition}
          cardWidth={cardWidth}
          cardPadding={cardPadding}
        />
      );

      container.innerHTML = cardHtml;

      try {
        // Capture the card as canvas
        const cardCanvas = await html2canvas(
          container.firstChild as HTMLElement,
          {
            backgroundColor: null,
            scale: 2, // Increase quality
          }
        );

        // Create the final canvas with gradient background
        const finalCanvas = document.createElement("canvas");
        const ctx = finalCanvas.getContext("2d");

        if (!ctx) {
          throw new Error("Could not get canvas context");
        }

        // Set canvas size
        finalCanvas.width = cardCanvas.width + backgroundPadding * 2;
        finalCanvas.height = cardCanvas.height + backgroundPadding * 2;

        // Create gradient background
        const gradient = ctx.createLinearGradient(0, 0, 0, finalCanvas.height);
        gradient.addColorStop(0, "#412be7");
        gradient.addColorStop(1, "#787a7e");
        ctx.fillStyle = gradient;
        ctx.fillRect(0, 0, finalCanvas.width, finalCanvas.height);

        // Draw the API card centered on the background
        ctx.drawImage(cardCanvas, backgroundPadding, backgroundPadding);

        // Convert to PNG
        const dataUrl = finalCanvas.toDataURL("image/png");

        // Create download link
        const link = document.createElement("a");
        link.href = dataUrl;
        link.download = "api-definition.png";
        link.click();
      } catch (error) {
        console.error("Error exporting PNG:", error);
      } finally {
        // Clean up
        document.body.removeChild(container);
      }
    },
    []
  );

  return { exportAsPng };
};
