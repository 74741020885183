import { Button } from "@/components/_shadui/button";
import { ColorBadge } from "@/components/_shadui/color-badge";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/_shadui/tooltip";
import { useActionBarContext } from "@/components/contexts/action-bar-context";
import { useAPIEditorTools } from "@/components/contexts/api-editor-context-hooks";
import { H3 } from "@/components/headings";
import { HttpMethodBadge } from "@/components/http-method-badge";
import {
  EditorInputProps,
  WorkspaceRole,
} from "@/components/module-api-editor/types";
import {
  BtnGroup,
  DeleteButton,
  EditButton,
} from "@/components/module-visual-editor/shared-components";
import {
  ComponentParameterWithInfo,
  useComponentParameters,
} from "@/hooks/use-component-parameters";
import {
  ComponentResponseWithInfo,
  ComponentSchemaWithInfo,
  useComponents,
} from "@/hooks/use-components";
import { OperationIdUndefinedError } from "@/lib/errors";
import {
  getResponseTypeName,
  getSchemaTypeName,
} from "@/lib/oas-tools/oas-schema-utils";
import { typeColorMap } from "@/lib/oas-tools/style-helpers";
import { OASOperation, SupportedHTTPVerbs } from "@/lib/types";
import { canEdit, cn, DEFAULT_ICON_SIZE_SM, NormIcons } from "@/lib/utils";
import { ComponentProps } from "react";
import { useOperation } from "../../hooks/use-operation";

export function ActiveComponentButton({ isActive }: { isActive: boolean }) {
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          size="icon-sm"
          className="shrink-0"
          variant={isActive ? "default" : "secondary"}
        >
          <NormIcons.Active size={DEFAULT_ICON_SIZE_SM} />
        </Button>
      </TooltipTrigger>
      <TooltipContent>Active component</TooltipContent>
    </Tooltip>
  );
}

export function ItemLi({
  children,
  className,
  isActive,
  ...rest
}: ComponentProps<"li"> & { isActive: boolean }) {
  return (
    <li
      className={cn(
        "flex items-center py-1.5 px-2 hover:bg-accent cursor-pointer rounded-md transition-all gap-2",
        { "bg-accent": isActive },
        className
      )}
      {...rest}
    >
      {children}
    </li>
  );
}

export function FileTreeSpan() {
  return <span className="text-muted-foreground inline-block">└</span>;
}

function DeleteButtonWithTooltip({
  srTitle,
  tooltipTitle,
  className,
  ...rest
}: { srTitle: string; tooltipTitle: string } & ComponentProps<"button">) {
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <DeleteButton className={className} {...rest}>
          <span className="sr-only">{srTitle}</span>
        </DeleteButton>
      </TooltipTrigger>
      <TooltipContent>
        <p>{tooltipTitle}</p>
      </TooltipContent>
    </Tooltip>
  );
}

export function SectionUl({
  children,
  title,
  className,
  onAddClick,
  workspaceRole,
  ...rest
}: {
  title?: string;
  onAddClick?: () => unknown;
  workspaceRole: WorkspaceRole;
} & ComponentProps<"ul">) {
  const showAddButton = onAddClick && canEdit(workspaceRole);
  return (
    <div>
      <div className="flex justify-between items-end pl-4 pr-1 pb-1">
        {title && (
          <H3 className="text-sm text-muted-foreground font-bold">{title}</H3>
        )}
        {showAddButton && (
          <Button
            size="xs"
            variant="secondary"
            className="font-mono mr-2"
            onClick={onAddClick}
          >
            Add new
          </Button>
        )}
      </div>
      <div className="flex flex-col items-stretch justify-between">
        <ul className={cn("pr-1 pl-2", className)} {...rest}>
          {children}
          {showAddButton && (
            <ItemLi onClick={onAddClick} isActive={false}>
              <FileTreeSpan />
              <div
                className="flex gap-2 items-center grow pl-norm-mg"
                role="button"
              >
                <Button variant="secondary" size="xs">
                  Add new
                </Button>
              </div>
            </ItemLi>
          )}
        </ul>
      </div>
    </div>
  );
}

interface VisualPreviewRouteProps {
  method: SupportedHTTPVerbs;
  urlPath: string;
  operation: OASOperation;
  workspaceRole: WorkspaceRole;
}

export function OperationItem({
  value,
  onChange,
  method,
  operation,
  urlPath,
  workspaceRole,
}: VisualPreviewRouteProps & EditorInputProps) {
  if (!operation.operationId) throw OperationIdUndefinedError;
  const { setActiveElement, activeElement } = useAPIEditorTools({
    value,
    onChange,
  });
  const [, actionBarDispatch] = useActionBarContext();
  const { removeOperation } = useOperation({ value, onChange });

  const handleRemove = () => {
    removeOperation(urlPath, method);
  };

  const handleClick = () => {
    if (!operation.operationId) throw new Error("Operation has no id");
    setActiveElement({
      type: "operation",
      operationId: operation.operationId!,
    });
  };

  const handleEditClick = (urlPath: string, method: SupportedHTTPVerbs) => {
    if (!operation.operationId) throw new Error("Operation does not have id");
    actionBarDispatch({
      type: "SET_PAGE",
      payload: {
        name: "edit-operation",
        context: {
          urlPath,
          method,
          operationId: operation.operationId,
        },
      },
    });
  };

  const isActive =
    activeElement?.type === "operation" &&
    activeElement.operationId === operation.operationId;

  const showEditButtons = canEdit(workspaceRole);

  return (
    <ItemLi onClick={handleClick} isActive={isActive}>
      <div className="grow" role="button">
        {operation.summary ? (
          <div className="flex items-center">
            <div className="flex gap-2 grow basis-0">
              <ActiveComponentButton isActive={isActive} />
              <HttpMethodBadge method={method} />
              <div className="whitespace-nowrap w-1 grow text-ellipsis overflow-hidden">
                <span className="text-sm" style={{ unicodeBidi: "plaintext" }}>
                  {operation.summary}
                </span>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex gap-2 items-center grow pl-norm-mg">
            <ActiveComponentButton isActive={isActive} />
            <HttpMethodBadge method={method} />
            <div
              className="whitespace-nowrap w-1 grow text-ellipsis overflow-hidden"
              style={{ direction: "rtl" }}
            >
              <span
                className="text-sm"
                style={{ unicodeBidi: "plaintext" }}
              >{`${urlPath}`}</span>
            </div>
          </div>
        )}
      </div>
      {showEditButtons && (
        <BtnGroup>
          <EditButton
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleEditClick(urlPath, method);
            }}
          />
          <DeleteButtonWithTooltip
            srTitle="Delete operation"
            tooltipTitle="Delete operation"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleRemove();
            }}
          />
        </BtnGroup>
      )}
    </ItemLi>
  );
}

export function SchemaItem({
  componentSchemaWithInfo,
  value,
  onChange,
  workspaceRole,
}: {
  componentSchemaWithInfo: ComponentSchemaWithInfo;
  workspaceRole: WorkspaceRole;
} & EditorInputProps) {
  const { removeComponentSchema } = useComponents({ value, onChange });
  const [, actionBarDispatch] = useActionBarContext();
  const typeName = getSchemaTypeName(componentSchemaWithInfo.schema);
  const { activeElement, setActiveElement } = useAPIEditorTools({
    value,
    onChange,
  });

  const isActive =
    activeElement?.type !== "model"
      ? false
      : componentSchemaWithInfo.name === activeElement.modelName;

  const color = typeColorMap[typeName];
  if (!color) throw new Error("Can't find color for type");

  const handleClick = (modelName: string) => {
    setActiveElement({
      type: "model",
      modelName,
    });
  };

  const showEditButtons = canEdit(workspaceRole);

  const handleEditClick = (
    componentSchemaWithInfo: ComponentSchemaWithInfo
  ) => {
    actionBarDispatch({
      type: "SET_PAGE",
      payload: {
        name: "edit-component-schema",
        context: {
          schemaName: componentSchemaWithInfo.name,
          componentSchemaObject: componentSchemaWithInfo.schema,
        },
      },
    });
  };

  const handleRemove = (componentSchemaWithInfo: ComponentSchemaWithInfo) => {
    removeComponentSchema(componentSchemaWithInfo.name);
  };

  return (
    <ItemLi
      isActive={isActive}
      onClick={() => handleClick(componentSchemaWithInfo.name)}
    >
      <div className="flex gap-2 items-center grow pl-norm-mg" role="button">
        <ActiveComponentButton isActive={isActive} />
        <ColorBadge color={typeColorMap[typeName] || "blue"}>
          {typeName}
        </ColorBadge>
        <span className="text-sm grow text-ellipsis overflow-hidden w-1 whitespace-nowrap">
          {componentSchemaWithInfo.name}
        </span>
      </div>
      {showEditButtons && (
        <BtnGroup>
          <EditButton
            onClick={() => handleEditClick(componentSchemaWithInfo)}
          />
          <DeleteButtonWithTooltip
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleRemove(componentSchemaWithInfo);
            }}
            srTitle="Delete model"
            tooltipTitle="Delete model"
          />
        </BtnGroup>
      )}
    </ItemLi>
  );
}

export function ResponseItem({
  value,
  onChange,
  workspaceRole,
  componentResponseWithInfo: { responseObject, responseName },
}: {
  componentResponseWithInfo: ComponentResponseWithInfo;
  workspaceRole: WorkspaceRole;
} & EditorInputProps) {
  const { removeComponentResponse } = useComponents({ value, onChange });
  const [, actionBarDispatch] = useActionBarContext();

  const typeName = getResponseTypeName(responseObject);

  const { activeElement, setActiveElement } = useAPIEditorTools({
    value,
    onChange,
  });

  const isActive =
    activeElement?.type === "response" &&
    activeElement.responseName === responseName;

  const handleClick = () => {
    setActiveElement({
      type: "response",
      responseName,
    });
  };

  const handleEditClick = () => {
    actionBarDispatch({
      type: "SET_PAGE",
      payload: {
        name: "edit-component-response",
        context: {
          responseName,
          responseObject,
        },
      },
    });
  };

  const handleRemove = () => {
    removeComponentResponse(responseName);
  };

  const showEditButtons = canEdit(workspaceRole);

  return (
    <ItemLi isActive={isActive} onClick={handleClick}>
      <div className="flex gap-2 items-center grow pl-norm-mg" role="button">
        <ActiveComponentButton isActive={isActive} />
        <ColorBadge color={"blue"}>{typeName}</ColorBadge>
        <span className="text-sm grow text-ellipsis overflow-hidden w-1 whitespace-nowrap">
          {responseName}
        </span>
      </div>
      {showEditButtons && (
        <BtnGroup>
          <EditButton onClick={handleEditClick} />
          <DeleteButtonWithTooltip
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleRemove();
            }}
            srTitle="Delete model"
            tooltipTitle="Delete model"
          />
        </BtnGroup>
      )}
    </ItemLi>
  );
}

export function ComponentParameterItem({
  value,
  onChange,
  workspaceRole,
  componentParameterWithInfo,
}: {
  workspaceRole: WorkspaceRole;
  componentParameterWithInfo: ComponentParameterWithInfo;
} & EditorInputProps) {
  const { label, parameter } = componentParameterWithInfo;

  const { removeComponentParameter, resolveParameter } = useComponentParameters(
    {
      value,
      onChange,
    }
  );

  const resolved = resolveParameter(componentParameterWithInfo.parameter);

  const [, actionBarDispatch] = useActionBarContext();

  const { activeElement, setActiveElement } = useAPIEditorTools({
    value,
    onChange,
  });

  const isActive =
    activeElement?.type === "component-parameter" &&
    activeElement.label === label;

  const handleClick = () => {
    setActiveElement({
      type: "component-parameter",
      label,
    });
  };

  const handleEditClick = () => {
    actionBarDispatch({
      type: "SET_PAGE",
      payload: {
        name: "edit-component-parameter",
        context: {
          label,
          parameter,
        },
      },
    });
  };

  const handleRemove = () => {
    removeComponentParameter(label);
  };

  const showEditButtons = canEdit(workspaceRole);

  return (
    <ItemLi isActive={isActive} onClick={handleClick}>
      <div className="flex gap-2 items-center grow pl-norm-mg" role="button">
        <ActiveComponentButton isActive={isActive} />
        <ColorBadge color={resolved.in === "path" ? "cyan" : "purple"}>
          {resolved.in}
        </ColorBadge>
        <span className="text-sm grow text-ellipsis overflow-hidden w-1 whitespace-nowrap">
          {label}
        </span>
      </div>
      {showEditButtons && (
        <BtnGroup>
          <EditButton onClick={handleEditClick} />
          <DeleteButtonWithTooltip
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleRemove();
            }}
            srTitle="Delete model"
            tooltipTitle="Delete model"
          />
        </BtnGroup>
      )}
    </ItemLi>
  );
}
