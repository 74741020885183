import { ColorBadeColors } from "@/components/_shadui/color-badge";
import { AllPropertyTypes } from "@/lib/oas-tools/oas-schema-utils";
import { SupportedHTTPVerbs } from "@/lib/types";

export const typeColorMap: Record<AllPropertyTypes | "mixed", ColorBadeColors> =
  {
    string: "green",
    number: "pink",
    boolean: "emerald",
    integer: "orange",
    object: "cyan",
    array: "yellow",
    allOf: "indigo",
    oneOf: "blue",
    anyOf: "teal",
    mixed: "violet",
    null: "blue",
  };

export const httpColorMap: Record<SupportedHTTPVerbs, ColorBadeColors> = {
  delete: "red",
  get: "green",
  head: "yellow",
  options: "gray",
  patch: "indigo",
  post: "blue",
  put: "teal",
  trace: "emerald",
};
