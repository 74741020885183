import { OASParameterObject, OASReferenceObject, OASSchema } from "@/lib/types";

export function generateExampleFromQueryParameter(input: {
  parameter: OASParameterObject;
}): string {
  const { parameter } = input;
  const schema = parameter.schema;
  const { style, explode, name, in: paramIn } = parameter;

  if (!schema) throw new Error("Parameter schema is not defined");

  function generateValue(schema: OASSchema): any {
    if (Array.isArray(schema.type)) {
      for (const type of schema.type) {
        if (type === "array") continue;
        return generateValue({ ...schema, type });
      }
      return "string";
    }

    switch (schema.type) {
      case "string":
        return schema.enum ? schema.enum[0] : "string";
      case "number":
      case "integer":
        return 1;
      case "boolean":
        return true;
      case "array":
        if (schema.items) {
          return [generateValue(schema.items), generateValue(schema.items)];
        }
        return ["item1", "item2"];
      case "object":
        if (schema.properties) {
          const result: Record<string, any> = {};
          for (const [key, prop] of Object.entries(schema.properties)) {
            result[key] = generateValue(prop);
          }
          return result;
        }
        return {};
      default:
        if (schema.oneOf) return generateValue(schema.oneOf[0]);
        if (schema.anyOf) return generateValue(schema.anyOf[0]);
        if (schema.allOf) {
          const result: Record<string, any> = {};
          for (const subSchema of schema.allOf) {
            Object.assign(result, generateValue(subSchema));
          }
          return result;
        }
        return "unknown";
    }
  }

  function isDeeplyNested(obj: any): boolean {
    if (typeof obj !== "object" || obj === null) return false;
    for (const value of Object.values(obj)) {
      if (typeof value === "object" && value !== null) return true;
    }
    return false;
  }

  function serializeValue(value: any): string {
    if (Array.isArray(value)) {
      return value.map(serializeValue).join(",");
    }
    if (typeof value === "object" && value !== null) {
      return Object.entries(value)
        .map(([k, v]) => `${k},${serializeValue(v)}`)
        .join(",");
    }
    return String(value);
  }

  const value = generateValue(schema as OASSchema | OASReferenceObject);

  if (paramIn === "path") {
    return serializeValue(value);
  }

  switch (style) {
    case "form":
      if (explode) {
        if (Array.isArray(value)) {
          return (
            "?" + value.map((v) => `${name}=${serializeValue(v)}`).join("&")
          );
        }
        if (typeof value === "object" && value !== null) {
          return (
            "?" +
            Object.entries(value)
              .map(([k, v]) => `${k}=${serializeValue(v)}`)
              .join("&")
          );
        }
        return `?${name}=${serializeValue(value)}`;
      } else {
        if (
          typeof value === "object" &&
          value !== null &&
          isDeeplyNested(value)
        ) {
          throw new Error(
            "Cannot generate a clear example for a deeply nested object with form style and explode=false"
          );
        }
        return `?${name}=${serializeValue(value)}`;
      }
    case "spaceDelimited":
      if (Array.isArray(value)) {
        if (explode) {
          return (
            "?" + value.map((v) => `${name}=${serializeValue(v)}`).join("&")
          );
        } else {
          return `?${name}=${value.map(serializeValue).join("%20")}`;
        }
      }
      return `?${name}=${serializeValue(value)}`;
    case "pipeDelimited":
      if (Array.isArray(value)) {
        if (explode) {
          return (
            "?" + value.map((v) => `${name}=${serializeValue(v)}`).join("&")
          );
        } else {
          return `?${name}=${value.map(serializeValue).join("|")}`;
        }
      }
      return `?${name}=${serializeValue(value)}`;
    case "deepObject":
      if (typeof value === "object" && value !== null) {
        return (
          "?" +
          Object.entries(value)
            .map(([k, v]) => `${name}[${k}]=${serializeValue(v)}`)
            .join("&")
        );
      }
      return `?${name}=${serializeValue(value)}`;
    default:
      throw new Error("Unsupported style");
  }
}
