import { ColorBadge } from "@/components/_shadui/color-badge";
import { useActionBarContext } from "@/components/contexts/action-bar-context";
import { ActiveElement } from "@/components/contexts/api-editor-context";
import { DocumentationCardDescription } from "@/components/documentation-card";
import { EditorItemDescription } from "@/components/module-api-editor";
import { Toolbar } from "@/components/module-api-editor/toolbar";
import {
  EditorInputProps,
  WorkspaceRole,
} from "@/components/module-api-editor/types";
import {
  BtnGroup,
  DeleteButton,
  EditButton,
} from "@/components/module-visual-editor/shared-components";
import { SchemaQuickEditor } from "@/components/schema-quick-editor";
import { useComponentParameters } from "@/hooks/use-component-parameters";
import {
  AllPropertyTypes,
  getComponentParameterTypeName,
} from "@/lib/oas-tools/oas-schema-utils";
import { typeColorMap } from "@/lib/oas-tools/style-helpers";
import { canEdit } from "@/lib/utils";
import { useMemo } from "react";

export function EditorPreviewComponentParameter({
  value,
  onChange,
  activeElement,
  hasOperations,
  workspaceSlug,
  organizationSlug,
  isAuthed,
  workspaceRole,
}: {
  isAuthed: boolean;
  workspaceSlug: string | undefined;
  organizationSlug: string | undefined;
  activeElement: Extract<ActiveElement, { type: "component-parameter" }>;
  hasOperations: boolean;
  workspaceRole: WorkspaceRole;
} & EditorInputProps) {
  const [, actionBarDispatch] = useActionBarContext();

  const { getComponentParameterByLabel, removeComponentParameter } =
    useComponentParameters({
      value,
      onChange,
    });

  const componentParameter = useMemo(() => {
    const found = getComponentParameterByLabel(activeElement.label);
    if (!found) throw new Error("Component response not found");
    return found;
  }, [activeElement.label, getComponentParameterByLabel]);

  const typeName = getComponentParameterTypeName(componentParameter);

  const color = typeColorMap[typeName as AllPropertyTypes] || "blue";

  const handleEditClick = () => {
    actionBarDispatch({
      type: "SET_PAGE",
      payload: {
        name: "edit-component-parameter",
        context: {
          label: activeElement.label,
          parameter: componentParameter,
        },
      },
    });
  };

  const handleRemoveClick = () => {
    removeComponentParameter(activeElement.label);
  };

  const isEditor = canEdit(workspaceRole);

  return (
    <div className="max-w-screen-xl px-5">
      <div className="flex justify-end pb-3">
        <Toolbar
          hasOperations={hasOperations}
          isAuthed={isAuthed}
          onChange={onChange}
          value={value}
          workspaceSlug={workspaceSlug}
          organizationSlug={organizationSlug}
          workspaceRole={workspaceRole}
        />
      </div>
      <div className="grow flex flex-col gap-4 items-stretch pt-2">
        <div className="flex justify-between pb-1">
          <div className="flex gap-4">
            <BtnGroup>
              <ColorBadge color={color}>{typeName}</ColorBadge>{" "}
              <ColorBadge
                color={componentParameter.in === "path" ? "cyan" : "purple"}
              >
                {componentParameter.in}
              </ColorBadge>
            </BtnGroup>
            <p>{activeElement.label}</p>
          </div>
          {isEditor && (
            <BtnGroup>
              <EditButton onClick={handleEditClick} />
              <DeleteButton onClick={handleRemoveClick} />
            </BtnGroup>
          )}
        </div>
      </div>
      <div className="pt-3">
        <DocumentationCardDescription className="">
          <EditorItemDescription
            item={componentParameter}
            onAddDescriptionClick={handleEditClick}
            workspaceRole={workspaceRole}
          />
        </DocumentationCardDescription>
        <SchemaQuickEditor
          onChange={onChange}
          workspaceRole={workspaceRole}
          value={value}
          title={`${activeElement.label} schema`}
          className=" min-h-[80px] w-full mt-norm"
          path={`components.parameters.${activeElement.label}.schema`}
          allowTopLevelReferences={false}
        />
      </div>
    </div>
  );
}
