import { useAPIEditorTools } from "@/components/contexts/api-editor-context-hooks";
import { EditorPreviewComponentParameter } from "@/components/module-api-editor/editor-preview-component-parameter";
import { EditorPreviewCreateClient } from "@/components/module-api-editor/editor-preview-create-client";
import { EditorPreviewGeneralInformation } from "@/components/module-api-editor/editor-preview-general-information";
import { EditorPreviewModel } from "@/components/module-api-editor/editor-preview-model";
import { EditorPreviewOperation } from "@/components/module-api-editor/editor-preview-operation";
import { EditorPreviewResponse } from "@/components/module-api-editor/editor-preview-response";
import { Toolbar } from "@/components/module-api-editor/toolbar";
import {
  EditorInputProps,
  WorkspaceRole,
} from "@/components/module-api-editor/types";
import { NothingSelected } from "@/components/module-preview-pane/operation-preview";
import { useComponents } from "@/hooks/use-components";

export function PreviewPanel({
  value,
  onChange,
  isAuthed,
  hasOperations,
  organizationSlug,
  workspaceSlug,
  workspaceRole,
}: {
  isAuthed: boolean;
  hasOperations: boolean;
  organizationSlug?: string;
  workspaceSlug?: string;
  workspaceRole: WorkspaceRole;
} & EditorInputProps) {
  const { activeElement } = useAPIEditorTools({ value, onChange });
  const { componentsObject } = useComponents({ value, onChange });

  if (!activeElement)
    return (
      <div className="h-full flex flex-col items-stretch">
        <div className="flex justify-end items-center h-[48px] pr-4">
          <Toolbar
            organizationSlug={organizationSlug}
            isAuthed={isAuthed}
            onChange={onChange}
            value={value}
            hasOperations={hasOperations}
            workspaceSlug={workspaceSlug}
            workspaceRole={workspaceRole}
          />
        </div>
        <NothingSelected />
      </div>
    );

  return (
    <div className="h-full w-full">
      {activeElement.type === "general_information" ? (
        <EditorPreviewGeneralInformation
          value={value}
          onChange={onChange}
          workspaceSlug={workspaceSlug}
          organizationSlug={organizationSlug}
          isAuthed={isAuthed}
          hasOperations={hasOperations}
          workspaceRole={workspaceRole}
        />
      ) : activeElement.type === "create_client" ? (
        <EditorPreviewCreateClient
          value={value}
          onChange={onChange}
          workspaceSlug={workspaceSlug}
          organizationSlug={organizationSlug}
          isAuthed={isAuthed}
          hasOperations={hasOperations}
          workspaceRole={workspaceRole}
        />
      ) : activeElement.type === "response" ? (
        <EditorPreviewResponse
          value={value}
          onChange={onChange}
          workspaceSlug={workspaceSlug}
          organizationSlug={organizationSlug}
          isAuthed={isAuthed}
          activeElement={activeElement}
          hasOperations={hasOperations}
          workspaceRole={workspaceRole}
        />
      ) : activeElement.type === "component-parameter" ? (
        <EditorPreviewComponentParameter
          value={value}
          onChange={onChange}
          workspaceSlug={workspaceSlug}
          organizationSlug={organizationSlug}
          isAuthed={isAuthed}
          activeElement={activeElement}
          hasOperations={hasOperations}
          workspaceRole={workspaceRole}
        />
      ) : activeElement?.type === "model" ? (
        <EditorPreviewModel
          value={value}
          onChange={onChange}
          activeElement={activeElement}
          isAuthed={isAuthed}
          hasOperations={hasOperations}
          workspaceSlug={workspaceSlug}
          organizationSlug={organizationSlug}
          workspaceRole={workspaceRole}
        />
      ) : (
        <EditorPreviewOperation
          key={JSON.stringify(activeElement)}
          activeElement={activeElement}
          componentsObject={componentsObject}
          isAuthed={isAuthed}
          hasOperations={hasOperations}
          workspaceSlug={workspaceSlug}
          organizationSlug={organizationSlug}
          value={value}
          onChange={onChange}
          workspaceRole={workspaceRole}
        />
      )}
    </div>
  );
}
