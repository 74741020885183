import { useAPIEditorTools } from "@/components/contexts/api-editor-context-hooks";
import { EditorInputProps } from "@/components/module-api-editor/types";
import {
  getRequestBodyFromDefinition,
  getRequestBodyOrErrorFromDefinition,
  removeRequestBodyFromDefinition,
  addRequestBodyToDefinition,
} from "@/lib/editor-mutations/oas-request-body";
import { OASRequestBodyObject } from "@/lib/types";
import { toastError } from "@/lib/utils";
import { useCallback } from "react";
import { toast } from "sonner";

export function useRequestBody({ value, onChange }: EditorInputProps) {
  const { setNewEditorState } = useAPIEditorTools({ value, onChange });

  const getRequestBodyOrError = useCallback(
    ({ operationId }: { operationId: string }) => {
      return getRequestBodyOrErrorFromDefinition({
        definition: value.data,
        operationId,
      });
    },
    [value.data]
  );

  const getRequestBody = useCallback(
    ({ operationId }: { operationId: string }) => {
      return getRequestBodyFromDefinition({
        operationId,
        definition: value.data,
      });
    },
    [value.data]
  );

  const update = useCallback(
    ({
      operationId,
      requestBody,
    }: {
      requestBody: OASRequestBodyObject;
      operationId: string;
    }) => {
      try {
        const newDefinition = addRequestBodyToDefinition({
          operationId,
          definition: value.data,
          requestBody,
        });
        setNewEditorState({
          data: newDefinition,
          document_type: value.document_type,
        });
        toast.success("Update successful.");
      } catch (err) {
        toastError(err);
      }
    },
    [setNewEditorState, value.data, value.document_type]
  );

  const add = useCallback(
    ({
      requestBody,
      operationId,
    }: {
      requestBody: OASRequestBodyObject;
      operationId: string;
    }) => {
      try {
        const newDefinition = addRequestBodyToDefinition({
          operationId,
          requestBody,
          definition: value.data,
        });
        setNewEditorState({
          data: newDefinition,
          document_type: value.document_type,
        });
      } catch (err) {
        toastError(err);
      }
    },
    [setNewEditorState, value.data, value.document_type]
  );

  const remove = useCallback(
    ({ operationId }: { operationId: string }) => {
      const newDefinition = removeRequestBodyFromDefinition({
        operationId,
        definition: value.data,
      });
      setNewEditorState({
        document_type: value.document_type,
        data: newDefinition,
      });
    },
    [value.data, setNewEditorState, value.document_type]
  );

  return {
    getRequestBodyOrError,
    getRequestBody,
    update,
    add,
    remove,
  };
}
