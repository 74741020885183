import { Card, CardHeader } from "@/components/_shadui/card";
import { ColorBadge } from "@/components/_shadui/color-badge";
import { ActionBarFormTitle } from "@/components/module-action-bar/elements";
import { EditorInputProps } from "@/components/module-api-editor/types";
import { useComponentParameters } from "@/hooks/use-component-parameters";
import { useParameters } from "@/hooks/use-parameters";
import { useMemo } from "react";
import { useActionBarContext } from "../contexts/action-bar-context";
import { ActionBarContent } from "./action-bar-content";
import { Button } from "@/components/_shadui/button";
import { DEFAULT_ICON_SIZE, NormIcons } from "@/lib/utils";

export function AddComponentParamterToOperation({
  value,
  onChange,
  operationId,
  where,
  urlPath,
}: {
  where: "query";
  urlPath: string;
  operationId: string;
} & EditorInputProps) {
  const { getComponentParametersWithInfo } = useComponentParameters({
    value,
    onChange,
  });
  const { addParameter } = useParameters({ value, onChange });
  const [, dispatch] = useActionBarContext();

  const parameterList = useMemo(
    () =>
      getComponentParametersWithInfo().filter((p) => p.parameter.in === where),
    [getComponentParametersWithInfo, where]
  );

  const handleAdd = (label: string) => {
    addParameter({
      parameter: { $ref: `#/components/parameters/${label}` },
      operationId,
      urlPath,
    });
    dispatch({
      type: "CLOSE",
    });
  };

  const handleClickCreateParamter = () => {
    dispatch({
      type: "SET_PAGE",
      payload: {
        name: "add-component-parameter",
      },
    });
  };

  return (
    <ActionBarContent>
      <ActionBarFormTitle>Select component parameter</ActionBarFormTitle>
      <div className="min-h-[300px] max-h-[500px] overflow-auto">
        <div>
          {parameterList.length === 0 && (
            <div>
              <p className="text-sm text-muted-foreground pb-3">
                Component parameters show up here after they were created
              </p>
              <Button
                size="sm"
                variant="primary"
                onClick={handleClickCreateParamter}
              >
                <NormIcons.Add size={DEFAULT_ICON_SIZE} className="mr-2" />
                Create parameter
              </Button>
            </div>
          )}
          {parameterList.map((componentParamterWithInfo) => {
            const parameter = componentParamterWithInfo.parameter;
            return (
              <Card
                onClick={() => handleAdd(parameter.name)}
                className="border-none"
              >
                <Card className="bg-primary-foreground hover:bg-accent cursor-pointer border-none transition-all">
                  <CardHeader className="py-2 px-2">
                    <div
                      className="flex gap-2 items-center grow pl-norm-mg"
                      role="button"
                    >
                      {" "}
                      {" - "}
                      <ColorBadge
                        color={parameter.in === "path" ? "yellow" : "fuchsia"}
                      >
                        {parameter.in}
                      </ColorBadge>
                      <span className="text-sm grow text-ellipsis overflow-hidden w-1 whitespace-nowrap">
                        {parameter.name}
                      </span>
                    </div>
                  </CardHeader>
                </Card>
              </Card>
            );
          })}
        </div>
      </div>
    </ActionBarContent>
  );
}
