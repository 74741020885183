import { OASSchema } from "@/lib/types";

export function findReferencedComponents(obj: OASSchema): Array<string> {
  const components: Set<string> = new Set();

  function traverse(item: any, depth: number = 0) {
    if (depth >= 40) {
      return; // Stop recursion if depth limit is reached
    }

    if (typeof item !== "object" || item === null) {
      return;
    }

    if ("$ref" in item && typeof item.$ref === "string") {
      components.add(item.$ref);
    }

    for (const key in item) {
      traverse(item[key], depth + 1);
    }
  }

  traverse(obj);
  return Array.from(components);
}
