import { OASReferenceObject, OASSchema } from "@/lib/types";

function convertOpenAPIToDTOSchema(
  schema: OASSchema | OASReferenceObject
): OASSchema {
  if ("$ref" in schema) {
    return { type: "string" };
  }

  if (schema.type === "object" && schema.properties) {
    const dtoProperties: { [key: string]: OASSchema } = {};
    for (const [key, value] of Object.entries(schema.properties)) {
      if ("$ref" in value) {
        const refParts = value.$ref.split("/");
        const refName = refParts[refParts.length - 1];
        const idFieldName = `${refName.charAt(0).toLowerCase() + refName.slice(1)}Id`;
        dtoProperties[idFieldName] = { type: "string" };
      } else {
        dtoProperties[key] = convertOpenAPIToDTOSchema(value);
      }
    }
    return { type: "object", properties: dtoProperties };
  }

  if (schema.type === "array") {
    return { type: "array", items: convertOpenAPIToDTOSchema(schema.items) };
  }

  // For primitive types or unknown types, return as is
  return { type: schema.type };
}

export function createDtoFromOASSchema(schema: OASSchema): OASSchema {
  return convertOpenAPIToDTOSchema(schema);
}
