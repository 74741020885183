import { EditorState } from "@/components/module-api-editor/types";
import { OASDefinition } from "@/lib/types";
import { createContext, PropsWithChildren, useMemo, useReducer } from "react";

export const WorkspaceEditorContext = createContext<
  readonly [WorkspaceEditorContextState, React.Dispatch<Action>] | undefined
>(undefined);

export type LegacyEditorState = {
  oas: OASDefinition;
};

export type ActiveElement =
  | {
      type: "operation";
      operationId: string;
    }
  | {
      type: "model";
      modelName: string;
    }
  | {
      type: "response";
      responseName: string;
    }
  | {
      type: "component-parameter";
      label: string;
    }
  | {
      type: "general_information";
    }
  | {
      type: "create_client";
    };

type Action =
  | {
      type: "SET_ACTIVE_ELEMENT";
      payload: ActiveElement | undefined;
    }
  | {
      type: "SET_HISTORY";
      payload: EditorState[];
    };

export type WorkspaceEditorContextState = {
  activeElement: ActiveElement | undefined;
  history: EditorState[];
};

const initialState: Omit<WorkspaceEditorContextState, "workspace"> = {
  // controls the accordion and must be an empty string for <null>
  activeElement: undefined,
  history: [],
};

function reducer(
  state: WorkspaceEditorContextState,
  action: Action
): WorkspaceEditorContextState {
  switch (action.type) {
    case "SET_ACTIVE_ELEMENT":
      return { ...state, activeElement: action.payload };
    case "SET_HISTORY":
      return {
        ...state,
        history: action.payload,
      };
    default:
      throw new Error(
        `Unknown action in APIEditorContext: ${JSON.stringify(action, null, 2).toString()}`
      );
  }
}

export const WorkspaceEditorProvider = ({
  children,
  initialState: initialProvidedState,
}: PropsWithChildren<{
  initialState?: Partial<WorkspaceEditorContextState>;
}>) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    ...initialProvidedState,
  });
  const value = useMemo(() => [state, dispatch] as const, [state]);
  return (
    <WorkspaceEditorContext.Provider value={value}>
      {children}
    </WorkspaceEditorContext.Provider>
  );
};
