import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/_shadui/form";
import { Label } from "@/components/_shadui/label";
import { Textarea } from "@/components/_shadui/textarea";
import { useAPIEditorTools } from "@/components/contexts/api-editor-context-hooks";
import { ActionBarForm } from "@/components/module-action-bar/elements";
import { EditorInputProps } from "@/components/module-api-editor/types";
import {
  BtnGroup,
  SubmitButton,
} from "@/components/module-visual-editor/shared-components";
import { useForm } from "react-hook-form";
import { useActionBarContext } from "../contexts/action-bar-context";
import { ActionBarContent } from "./action-bar-content";

type FormValues = {
  data: string;
};

export function Debug({ value, onChange }: EditorInputProps) {
  const { setNewEditorState } = useAPIEditorTools({ value, onChange });
  const [, dispatch] = useActionBarContext();

  const form = useForm<FormValues>({
    defaultValues: {
      data: "",
    },
  });

  const onSubmit = (values: FormValues) => {
    setNewEditorState({
      data: JSON.parse(values.data),
      document_type: value.document_type,
    });
    dispatch({ type: "CLOSE" });
  };

  return (
    <ActionBarContent>
      <Label>Add editor state</Label>
      <Form {...form}>
        <ActionBarForm
          onSubmit={(e) => {
            form.handleSubmit(onSubmit)(e);
          }}
        >
          <FormField
            control={form.control}
            name="data"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="flex gap-2 items-center">
                  Description
                </FormLabel>
                <FormControl>
                  <Textarea {...field} />
                </FormControl>
              </FormItem>
            )}
          />
          <BtnGroup>
            <SubmitButton>Submit</SubmitButton>
          </BtnGroup>
        </ActionBarForm>
      </Form>
    </ActionBarContent>
  );
}
