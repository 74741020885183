import { NoRefsHereError } from "@/lib/errors";
import { generateExampleFromQueryParameter } from "@/lib/oas-tools/generate-example-from-schema";
import { findReferencedComponents } from "@/lib/oas-tools/oas-find-references";
import { getComponentTypeFromString } from "@/lib/oas-tools/oas-schema-utils";
import { deref, isReference } from "@/lib/oas-tools/oas-tag-helpers";
import { isEmpty } from "lodash";
import { HttpStatus, httpStatusMap } from "../helpers";
import {
  OASComponentsObject,
  OASOperation,
  OASReferenceObject,
  OASSchema,
  SupportedContentFormats,
  SupportedHTTPVerbs,
} from "../types";
import { generateOASSchemaRows, OASSchemaRow } from "./generate-schema-rows";

class UnsupportedReferenceTypeError extends Error {
  constructor(msg: string = "Reference types are not supported") {
    super(msg);
  }
}
type OASRowType =
  | "header"
  | "text"
  | "response-code"
  | "heading"
  | "description"
  | "model-header"
  | "separator";
export type OASRowFormat = "bold" | "muted" | undefined;

export type OASRow = {
  kind: "oas-row"; // more global type to between schema/operation/definition rows
  propertyName: string | undefined;
  text: string;
  format: OASRowFormat;
  rowType: OASRowType; // row type within kind
  level: number;
  extraInsetLevel: number;
};

export type PreviewRow = OASRow | OASSchemaRow;

function generateHeaderRows(
  level: number,
  operation: OASOperation,
  method: SupportedHTTPVerbs,
  urlPath: string
): PreviewRow[] {
  const rows: PreviewRow[] = [
    {
      kind: "oas-row",
      propertyName: undefined,
      rowType: "header",
      text: `${method} ${urlPath}`,
      format: undefined,
      level,
      extraInsetLevel: 0,
    },
    {
      kind: "oas-row",
      propertyName: undefined,
      rowType: "separator",
      text: "",
      format: undefined,
      level,
      extraInsetLevel: 0,
    },
  ];
  if (operation.description) {
    rows.push({
      kind: "oas-row",
      propertyName: undefined,
      rowType: "text",
      text: operation.description,
      format: "muted",
      level,
      extraInsetLevel: 0,
    });
    rows.push({
      kind: "oas-row",
      propertyName: undefined,
      rowType: "separator",
      text: "",
      format: undefined,
      level,
      extraInsetLevel: 0,
    });
  }
  return rows;
}

export function generateSchemaRows(
  schema: OASSchema | OASReferenceObject,
  level: number,
  components: OASComponentsObject,
  extraInsetLevel: number
): PreviewRow[] {
  return generateOASSchemaRows({
    schema,
    isDisabled: false, // TODO: can we remove this from the rows?
    isReadOnly: false, // TODO: can we remove this from the rows?
    initialLevel: level,
    componentsObject: components,
    extraInsetLevel,
    options: {
      allowTopLevelReferences: true,
    },
  });
}

function generateParameterRows(
  level: number,
  operation: OASOperation,
  components: OASComponentsObject
): PreviewRow[] {
  let rows: PreviewRow[] = [];
  if (!operation.parameters?.length) return rows;
  rows.push({
    kind: "oas-row",
    propertyName: undefined,
    format: undefined,
    text: "Parameters",
    level,
    rowType: "heading",
    extraInsetLevel: 0,
  });
  rows.push({
    kind: "oas-row",
    format: undefined,
    text: "",
    propertyName: undefined,
    level,
    rowType: "separator",
    extraInsetLevel: 0,
  });
  operation.parameters.forEach((p) => {
    if (isReference(p)) {
      const resolved = components?.parameters?.[deref(p.$ref)];
      if (!resolved || isReference(resolved)) return;
      rows.push({
        kind: "oas-row",
        propertyName: undefined,
        format: undefined,
        text: `- ${resolved.name} - ${resolved.in.toLowerCase()}`,
        level,
        rowType: "text",
        extraInsetLevel: 0,
      });
      rows.push({
        kind: "oas-row",
        propertyName: undefined,
        format: "muted",
        text: `Type: ${deref(p.$ref)}`,
        level,
        rowType: "text",
        extraInsetLevel: 1,
      });
    } else {
      rows.push({
        kind: "oas-row",
        propertyName: undefined,
        format: undefined,
        text: `- ${p.name} - ${p.in.toLowerCase()}`,
        level,
        rowType: "text",
        extraInsetLevel: 0,
      });
      if (p.description) {
        rows.push({
          kind: "oas-row",
          propertyName: undefined,
          format: "muted",
          text: p.description,
          level,
          rowType: "description",
          extraInsetLevel: 1,
        });
      }
      if (p.schema) {
        try {
          rows.push({
            kind: "oas-row",
            propertyName: undefined,
            format: "muted",
            text: `Example: ${generateExampleFromQueryParameter({ parameter: p })}`,
            level,
            rowType: "text",
            extraInsetLevel: 1,
          });
        } catch (err) {
          // TODO: Do something
        }
        rows.push(createTypeRow(level, 1));
        rows = rows.concat(
          generateSchemaRows(p.schema, level + 1, components, 1)
        );
      }
    }
    rows.push({
      kind: "oas-row",
      format: undefined,
      text: "",
      propertyName: undefined,
      level,
      rowType: "separator",
      extraInsetLevel: 0,
    });
  });

  return rows;
}

function generateEmptyLine(level: number, text: string = " "): PreviewRow[] {
  return [
    {
      kind: "oas-row",
      propertyName: undefined,
      format: undefined,
      text: text,
      level,
      rowType: "text",
      extraInsetLevel: 0,
    },
  ];
}

export function createTypeRow(
  level: number,
  extraInsetLevel: number
): PreviewRow {
  return {
    kind: "oas-row",
    text: "Type:",
    format: "muted",
    level: level,
    propertyName: undefined,
    rowType: "text",
    extraInsetLevel,
  };
}

function generateRequestBodyRows(
  level: number,
  operation: OASOperation,
  format: SupportedContentFormats,
  components: OASComponentsObject
): PreviewRow[] {
  let rows: PreviewRow[] = [];
  if (!operation.requestBody) return rows;

  rows.push({
    kind: "oas-row",
    propertyName: undefined,
    format: undefined,
    text: "Request body",
    level,
    rowType: "heading",
    extraInsetLevel: 0,
  });
  rows.push({
    kind: "oas-row",
    propertyName: undefined,
    format: undefined,
    text: "",
    level,
    rowType: "separator",
    extraInsetLevel: 0,
  });
  if (operation.requestBody) {
    if ("$ref" in operation.requestBody)
      throw new UnsupportedReferenceTypeError();
    const schema = operation.requestBody.content?.[format]?.schema;
    if (!schema) return rows;
    rows.push(createTypeRow(level, 0));
    rows = rows.concat(generateSchemaRows(schema, level + 1, components, 0));
  }
  rows.push({
    kind: "oas-row",
    format: undefined,
    text: "",
    propertyName: undefined,
    level,
    rowType: "separator",
    extraInsetLevel: 0,
  });
  return rows;
}

function generateResponseListRows(
  level: number,
  operation: OASOperation,
  format: SupportedContentFormats,
  components: OASComponentsObject
): PreviewRow[] {
  let rows: PreviewRow[] = [];
  const responses = operation.responses;
  if (!responses || isEmpty(responses)) return rows;
  rows.push({
    kind: "oas-row",
    format: undefined,
    text: "Responses",
    propertyName: undefined,
    level,
    rowType: "heading",
    extraInsetLevel: 0,
  });
  rows.push({
    kind: "oas-row",
    format: undefined,
    text: "",
    propertyName: undefined,
    level,
    rowType: "separator",
    extraInsetLevel: 0,
  });
  let i = 0;
  const responseEntries = Object.entries(responses);
  for (const [status, response] of responseEntries) {
    const isLast = i === responseEntries.length - 1;

    const isRef = isReference(response);

    // Check if we have a schema before adding anything
    const httpMessage =
      httpStatusMap[+status as HttpStatus]?.message.toUpperCase();
    rows.push({
      kind: "oas-row",
      format: undefined,
      text: httpMessage
        ? `- ${status} ${httpMessage}`
        : "- Unknown response code",
      propertyName: undefined,
      level,
      rowType: "response-code",
      extraInsetLevel: 0,
    });
    if (response.description) {
      rows.push({
        kind: "oas-row",
        format: "muted",
        text: response.description,
        propertyName: undefined,
        level,
        rowType: "response-code",
        extraInsetLevel: 1,
      });
    }

    if (isRef) {
      rows.push({
        kind: "oas-row",
        propertyName: undefined,
        format: "muted",
        text: `Type: ${deref(response.$ref)}`,
        level,
        rowType: "text",
        extraInsetLevel: 1,
      });
    } else {
      const schema = response.content?.[format].schema;
      if (!schema) {
        rows.push({
          kind: "oas-row",
          propertyName: undefined,
          format: undefined,
          text: "Type: null",
          level,
          rowType: "text",
          extraInsetLevel: 1,
        });
      } else {
        rows.push(createTypeRow(level, 1));
        rows = rows.concat(
          generateSchemaRows(schema, level + 1, components, 1)
        );
      }
    }
    if (!isLast) {
      rows = rows.concat(generateEmptyLine(level, " "));
    }
    i++;
  }
  return rows;
}

function generateComponentSchemaRows(
  componentName: string,
  initialLevel: number,
  componentsObject: OASComponentsObject
) {
  let rows: PreviewRow[] = [];

  const schema = componentsObject["schemas"]?.[componentName];
  if (!schema) return rows;

  rows.push({
    kind: "oas-row",
    rowType: "text",
    text: componentName,
    format: "bold",
    level: initialLevel,
    propertyName: undefined,
    extraInsetLevel: 0,
  });
  rows.push(createTypeRow(initialLevel, 1));
  rows = [
    ...rows,
    ...generateSchemaRows(schema, initialLevel + 1, componentsObject, 1),
  ];

  return rows;
}

function generateComponentParameterRows(
  parameterName: string,
  initialLevel: number,
  componentsObject: OASComponentsObject
) {
  let rows: PreviewRow[] = [];

  const parameterObject = componentsObject["parameters"]?.[parameterName];
  if (!parameterObject) return rows;
  if (isReference(parameterObject)) throw new NoRefsHereError();

  const schema = parameterObject.schema;
  if (!schema) return rows;

  rows.push({
    kind: "oas-row",
    rowType: "text",
    text: parameterName,
    format: "bold",
    level: initialLevel,
    propertyName: undefined,
    extraInsetLevel: 0,
  });

  rows.push(createTypeRow(initialLevel, 1));
  rows = [
    ...rows,
    ...generateSchemaRows(schema, initialLevel + 1, componentsObject, 1),
  ];

  return rows;
}

function generateResponseComponentRows(
  responseComponentName: string,
  initialLevel: number,
  componentsObject: OASComponentsObject,
  format: SupportedContentFormats
) {
  let rows: PreviewRow[] = [];

  const responseObject = componentsObject["responses"]?.[responseComponentName];
  if (!responseObject) return rows;
  if (isReference(responseObject)) throw new NoRefsHereError();

  const schema = responseObject.content?.[format].schema;

  if (!schema) return rows;

  rows.push({
    kind: "oas-row",
    rowType: "text",
    text: responseComponentName,
    format: "bold",
    level: initialLevel,
    propertyName: undefined,
    extraInsetLevel: 0,
  });

  if (responseObject.description) {
    rows.push({
      kind: "oas-row",
      propertyName: undefined,
      rowType: "text",
      text: responseObject.description,
      format: "muted",
      level: initialLevel,
      extraInsetLevel: 0,
    });
  }

  rows.push(createTypeRow(initialLevel, 1));

  rows = [
    ...rows,
    ...generateSchemaRows(schema, initialLevel + 1, componentsObject, 1),
  ];

  return rows;
}

export function generateComponentStringRows(
  componentsObject: OASComponentsObject | undefined,
  initialLevel: number,
  componentString: string
): PreviewRow[] {
  if (!componentsObject) throw new Error("Workspace has no componentsObject");
  const type = getComponentTypeFromString(componentString);
  const componentName = deref(componentString);

  switch (type) {
    case "parameter":
      return generateComponentParameterRows(
        componentName,
        initialLevel,
        componentsObject
      );
    case "response":
      return generateResponseComponentRows(
        componentName,
        initialLevel,
        componentsObject,
        "application/json"
      );
    case "schema":
      return generateComponentSchemaRows(
        componentName,
        initialLevel,
        componentsObject
      );
    default:
      const typeName: never = type;
      throw new Error("Unsupported component type of " + typeName);
  }
}

function generateNestedComponentRows({
  initialLevel,
  operation,
  showNestedComponents,
  componentsObject,
}: {
  initialLevel: number;
  operation: OASOperation;
  showNestedComponents: boolean;
  componentsObject: OASComponentsObject;
}): PreviewRow[] {
  if (!showNestedComponents) return [];

  let rows: PreviewRow[] = [];

  const componentStrings = findReferencedComponents(operation) || [];

  if (!componentStrings.length) return rows;
  rows.push({
    kind: "oas-row",
    rowType: "separator",
    text: "",
    format: undefined,
    level: initialLevel,
    propertyName: undefined,
    extraInsetLevel: 0,
  });
  rows.push({
    kind: "oas-row",
    rowType: "heading",
    text: "Nested models",
    format: undefined,
    level: initialLevel,
    propertyName: undefined,
    extraInsetLevel: 0,
  });
  rows.push({
    kind: "oas-row",
    rowType: "separator",
    text: "",
    format: undefined,
    level: initialLevel,
    propertyName: undefined,
    extraInsetLevel: 0,
  });

  componentStrings.forEach((componentString, i) => {
    const componentRows = generateComponentStringRows(
      componentsObject,
      initialLevel,
      componentString
    );

    rows = rows.concat(componentRows);

    if (i !== componentStrings.length - 1) {
      rows.push({
        kind: "oas-row",
        rowType: "text",
        text: " ",
        format: undefined,
        level: initialLevel,
        propertyName: undefined,
        extraInsetLevel: 0,
      });
    }
  });
  return rows;
}

export function generateOASOperationRows({
  initialLevel,
  operation,
  method,
  urlPath,
  format,
  components,
  showNestedComponents,
}: {
  initialLevel: number;
  operation: OASOperation;
  method: SupportedHTTPVerbs;
  urlPath: string;
  format: SupportedContentFormats;
  components: OASComponentsObject;
  showNestedComponents: boolean;
}): PreviewRow[] {
  const headerRows = generateHeaderRows(
    initialLevel,
    operation,
    method,
    urlPath
  );
  const parameterRows = generateParameterRows(0, operation, components);
  const requestBodyRows = generateRequestBodyRows(
    0,
    operation,
    format,
    components
  );
  const responseListRows = generateResponseListRows(
    0,
    operation,
    format,
    components
  );

  const nestedComponentsRows = generateNestedComponentRows({
    initialLevel: 0,
    operation,
    componentsObject: components,
    showNestedComponents,
  });

  return headerRows.concat(
    parameterRows,
    requestBodyRows,
    responseListRows,
    nestedComponentsRows
  );
}
