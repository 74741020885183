import { resolveParamterFromDefinition } from "@/lib/editor-mutations/oas-parameters";
import { URL_PATH_REGEX } from "./regex";
import { OASDefinition, OASOperation, OASParameterObject } from "./types";

export function getParametersByType(
  operation: OASOperation,
  where: "path" | "query",
  definition: OASDefinition
) {
  return [
    ...(operation.parameters || []).filter((p) => {
      const resolvedParamter = resolveParamterFromDefinition({
        definition,
        parameter: p,
      });
      return resolvedParamter.in === where;
    }),
  ];
}

export function getParametersFromUrlPath(
  urlPath: string
): OASParameterObject[] {
  const found = urlPath.match(/{([\w_.-]+)}/g);
  if (!found?.length) return [];
  return found.map((r) => ({
    name: r.replace(/[{}]/g, ""),
    in: "path",
    required: true,
    schema: {
      type: "string",
    },
  }));
}
export const INVALID_PATH_MESSAGE =
  "Invalid url path. Example: /{variable}/foo. Special characters are not allowed";
export function validateUrlPath(urlPath: string) {
  if (!URL_PATH_REGEX.test(urlPath)) throw new Error(INVALID_PATH_MESSAGE);
  return urlPath;
}
