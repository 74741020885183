import {
  removeSchemaRefsFromDefinition,
  updateSchemaRefsInDefinition,
} from "@/lib/editor-mutations/oas-references";
import { getDtoXFields } from "@/lib/oas-tools/x-fields";
import { OASDefinition, OASSchema } from "@/lib/types";
import cloneDeep from "lodash/cloneDeep";
import set from "lodash/set";
import unset from "lodash/unset";

function createDtoPath(dtoName: string) {
  return `components.schemas.${dtoName}`;
}

function getBaseSchemaOrError(
  definition: OASDefinition,
  baseSchemaName: string
) {
  const schema = definition.components?.schemas?.[baseSchemaName];
  if (!schema) throw new Error("Base schema not found");
  if (schema["x-fiddle-dto-info"])
    throw new Error("Base schema is also a DTO. This is forbidden");
  return schema;
}

export function addDtoToDefinition({
  definition,
  dtoName,
  dtoSchema,
  baseSchemaName,
}: {
  definition: OASDefinition;
  dtoName: string;
  dtoSchema: OASSchema;
  baseSchemaName: string;
}) {
  getBaseSchemaOrError(definition, baseSchemaName);
  const cp = cloneDeep(definition);
  const path = createDtoPath(dtoName);
  return set(cp, path, { ...dtoSchema, ...getDtoXFields(baseSchemaName) });
}

export function updateDtoInDefinition({
  oldDtoName,
  newDtoName,
  definition,
  dtoSchema,
  baseSchemaName,
}: {
  oldDtoName: string;
  newDtoName: string;
  definition: OASDefinition;
  dtoSchema: OASSchema;
  baseSchemaName: string;
}) {
  getBaseSchemaOrError(definition, baseSchemaName);

  let newDefinition = definition;

  if (oldDtoName !== newDtoName) {
    newDefinition = removeDtoFromDefinition(
      updateSchemaRefsInDefinition({
        definition: newDefinition,
        oldRefName: oldDtoName,
        newRefName: newDtoName,
        type: "schemas",
      }),
      oldDtoName
    );
  }
  return addDtoToDefinition({
    definition: newDefinition,
    dtoName: newDtoName,
    dtoSchema: dtoSchema,
    baseSchemaName,
  });
}

export function removeDtoFromDefinition(
  definition: OASDefinition,
  dtoName: string
) {
  const schema = definition.components?.schemas?.[dtoName];
  if (!schema || !schema["x-fiddle-dto-info"])
    throw new Error("Trying to remove invalid DTO");
  const cp = cloneDeep(definition);
  const path = createDtoPath(dtoName);
  unset(cp, path);
  return removeSchemaRefsFromDefinition(cp, dtoName, "schemas");
}
