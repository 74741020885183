import { isArraySchema, isObjectSchema } from "@/lib/oas-tools/oas-tag-helpers";
import { OASArraySchemaObject, OASSchema } from "@/lib/types";
import { omit } from "lodash";

export const SUPPORTED_PAGINATION_STYLES = [
  "offset",
  "page",
  "cursor",
] as const;
export const SUPPORTED_CASE_STYLES = ["snake", "camel"] as const;

const propertyMap = {
  cursor: {
    nextCursor: {
      camel: "nextCursor",
      snake: "next_cursor",
    },
    hasMore: {
      camel: "hasMore",
      snake: "has_more",
    },
  },
  page: {
    totalPages: {
      camel: "totalPages",
      snake: "total_pages",
    },
    currentPage: {
      camel: "currentPage",
      snake: "current_page",
    },
    perPage: {
      camel: "perPage",
      snake: "per_page",
    },
    totalItems: {
      camel: "totalItems",
      snake: "total_items",
    },
  },
  offset: {
    total: {
      camel: "total",
      snake: "total",
    },
    limit: {
      camel: "limit",
      snake: "limit",
    },
    offset: {
      camel: "offset",
      snake: "offset",
    },
  },
};

/**
 * Get the schema for pagination
 *
 * IF: schema is already paginated -> get nested schema
 * IF: schema is not of type array -> transform to array type
 * @param schema
 * @returns schema to paginate
 */
function getBasePaginationSchema(schema: OASSchema): OASArraySchemaObject {
  if (isArraySchema(schema)) {
    return schema;
  }
  if (
    isObjectSchema(schema) &&
    schema.properties?.["items"] &&
    isArraySchema(schema.properties?.["items"])
  ) {
    const items = schema.properties.items;
    return items;
  }
  return {
    type: "array",
    items:
      // pagination will re-apply the status field
      schema.type === "object" && schema?.properties?.status
        ? omit(schema, "properties.status")
        : schema,
  };
}

const statusSchema: OASSchema = {
  type: "string",
  enum: ["success"],
  default: "success",
};

// add items manually to this schema
export function addPaginationToArraySchema(
  schema: OASSchema,
  paginationStyle: (typeof SUPPORTED_PAGINATION_STYLES)[number],
  caseStyle: (typeof SUPPORTED_CASE_STYLES)[number]
): OASSchema {
  const arraySchema = getBasePaginationSchema(schema);
  // if (paginationStyle === "none") return arraySchema;
  if (paginationStyle === "cursor")
    return {
      type: "object",
      properties: {
        [propertyMap.cursor.nextCursor[caseStyle]]: {
          type: "string",
        },
        [propertyMap.cursor.hasMore[caseStyle]]: {
          type: "boolean",
        },
        items: arraySchema,
        status: statusSchema,
      },
    };
  if (paginationStyle === "offset") {
    return {
      type: "object",
      properties: {
        [propertyMap.offset.offset[caseStyle]]: {
          type: "integer",
        },
        [propertyMap.offset.limit[caseStyle]]: {
          type: "integer",
        },
        [propertyMap.offset.total[caseStyle]]: {
          type: "integer",
        },
        items: arraySchema,
        status: statusSchema,
      },
    };
  }
  if (paginationStyle === "page") {
    return {
      type: "object",
      properties: {
        [propertyMap.page.totalPages[caseStyle]]: {
          type: "integer",
        },
        [propertyMap.page.currentPage[caseStyle]]: {
          type: "integer",
        },
        [propertyMap.page.perPage[caseStyle]]: {
          type: "integer",
        },
        [propertyMap.page.totalItems[caseStyle]]: {
          type: "integer",
        },
        items: arraySchema,
        status: statusSchema,
      },
    };
  }

  const _never: never = paginationStyle;
  throw new Error(`Unknown paginationStyle: ${_never} `);
}
