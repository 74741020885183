import { Button } from "@/components/_shadui/button";
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/_shadui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/_shadui/select";
import { ActionBarFieldGroup } from "@/components/module-action-bar/elements";
import {
  addPaginationToArraySchema,
  SUPPORTED_CASE_STYLES,
  SUPPORTED_PAGINATION_STYLES,
} from "@/lib/oas-tools/add-pagination-to-oas-schema";
import { OASSchema } from "@/lib/types";
import { DEFAULT_ICON_SIZE, NormIcons } from "@/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import merge from "lodash/merge";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";

const applyPaginationFormSchema = z.object({
  paginationStyle: z.enum(SUPPORTED_PAGINATION_STYLES),
  caseStyle: z.enum(SUPPORTED_CASE_STYLES),
});
export type ApplyPaginationFormValues = z.infer<
  typeof applyPaginationFormSchema
>;

export default function ApplyPaginationForm({
  schema,
  defaultValues,
  onChangeSchema,
}: {
  schema: OASSchema;
  defaultValues: Partial<ApplyPaginationFormValues>;
  onChangeSchema: (schema: OASSchema) => unknown;
}) {
  const form = useForm<ApplyPaginationFormValues>({
    resolver: zodResolver(applyPaginationFormSchema),
    defaultValues: merge(
      {
        paginationStyle: "page",
        caseStyle: "snake",
      },
      defaultValues
    ),
  });

  const handlePaginationChange = (
    schema: OASSchema,
    paginationStyle: ApplyPaginationFormValues["paginationStyle"],
    caseStyle: ApplyPaginationFormValues["caseStyle"]
  ) => {
    const newSchema = addPaginationToArraySchema(
      schema,
      paginationStyle,
      caseStyle
    );
    onChangeSchema(newSchema);
    toast.success("Pagination style applied");
  };
  return (
    <div className="rounded-lg border p-4">
      <p className="text-sm pb-4 text-muted-foreground">
        Add pagination to this response. Applying pagination converts schemas
        into an array-based types.
      </p>
      <ActionBarFieldGroup>
        <FormField
          control={form.control}
          name="caseStyle"
          render={({ field }) => {
            return (
              <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4 gap-3">
                <div className="space-y-0.5 basis-[500px]">
                  <FormLabel className="text-base">Case style</FormLabel>
                  <FormDescription>
                    Pagination fields can be added as snake_case or camelCase.
                  </FormDescription>
                  <FormMessage />
                </div>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select style" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {SUPPORTED_CASE_STYLES.map((f) => (
                      <SelectItem value={f} key={f}>
                        {f}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </FormItem>
            );
          }}
        />
        <FormField
          control={form.control}
          name="paginationStyle"
          render={({ field }) => {
            return (
              <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4">
                <div className="space-y-0.5 basis-[500px]">
                  <FormLabel className="text-base">Pagination</FormLabel>
                  <FormDescription>Choose a pagination style</FormDescription>
                  <FormMessage />
                </div>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select style" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {SUPPORTED_PAGINATION_STYLES.map((f) => (
                      <SelectItem value={f} key={f}>
                        {f}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </FormItem>
            );
          }}
        />
      </ActionBarFieldGroup>
      <div className="text-end pt-4">
        <Button
          size="sm"
          variant="secondary"
          type="button"
          onClick={() => {
            const values = form.getValues();
            try {
              handlePaginationChange(
                schema,
                values.paginationStyle,
                values.caseStyle
              );
            } catch (err) {
              if (err instanceof Error) {
                toast.error(err.message);
              }
            }
          }}
        >
          Apply pagination{" "}
          <NormIcons.Magic className="ml-2" size={DEFAULT_ICON_SIZE} />
        </Button>
      </div>
    </div>
  );
}
