import { Button } from "@/components/_shadui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/_shadui/form";
import { Input } from "@/components/_shadui/input";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/_shadui/select";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/components/_shadui/tabs";
import { Textarea } from "@/components/_shadui/textarea";
import { useActionBarContext } from "@/components/contexts/action-bar-context";
import { useAPIEditorTools } from "@/components/contexts/api-editor-context-hooks";
import { FormLabelRequired } from "@/components/form-label-required";
import {
  ActionBarFieldGroup,
  ActionBarForm,
  ActionBarFormTitle,
} from "@/components/module-action-bar/elements";
import { EditorInputProps } from "@/components/module-api-editor/types";
import { SchemaEditor } from "@/components/module-schema-editor/schema-editor";
import {
  BtnGroup,
  SubmitButton,
} from "@/components/module-visual-editor/shared-components";
import { SchemaEditorPreviewContainer } from "@/components/preview-container";
import { useComponents } from "@/hooks/use-components";
import { useSchemaDtos } from "@/hooks/use-dtos";
import { createDtoFromOASSchema } from "@/lib/oas-tools/create-dto-from-schema";
import {
  dtoTemplateNamesSchema,
  getDtoTemplateValues,
} from "@/lib/oas-tools/dto-templates";
import {
  getPristineType,
  unwrapSchemaFromArray,
  wrapSchemaInArray,
  zodSchemaSchema,
} from "@/lib/oas-tools/oas-schema-utils";
import { isArraySchema } from "@/lib/oas-tools/oas-tag-helpers";
import { OASComponentsObject } from "@/lib/types";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMemo } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";

const formSchema = z.object({
  schema: zodSchemaSchema,
  baseSchemaName: z.string().min(1, "Required"),
  name: z.string().min(1, "Required"),
  description: z.string().optional(),
  format: z.enum(["application/json"]),
});
export type FormValues = z.infer<typeof formSchema>;

export function DtoForm({
  defaultValues,
  onSubmit,
  componentsObject,
  isEditing,
  title,
  btnTitle,
  value,
  onChange,
}: {
  defaultValues?: Partial<FormValues>;
  onSubmit: SubmitHandler<FormValues>;
  componentsObject: OASComponentsObject;
  isEditing: boolean;
  title: string;
  btnTitle: string;
} & EditorInputProps) {
  const { getComponentSchemas, getComponentSchemaOrError } = useComponents({
    value,
    onChange,
  });
  const componentObjectSchemasWithInfo = useMemo(() => {
    return getComponentSchemas();
  }, [getComponentSchemas]);

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      baseSchemaName: "",
      schema: getPristineType("object"),
      format: "application/json",
      ...defaultValues,
    },
  });

  const schema = form.watch("schema");
  const baseSchemaName = form.watch("baseSchemaName");

  const hasArraySchape = isArraySchema(schema);

  const handleBulkChange = () => {
    const newSchema = hasArraySchape
      ? unwrapSchemaFromArray(schema)
      : wrapSchemaInArray(schema);
    form.setValue("schema", newSchema);
    toast.success(
      hasArraySchape ? "Unwrapped DTO schema" : "Wrapped DTO schema in array"
    );
  };

  return (
    <Form {...form}>
      <ActionBarForm onSubmit={form.handleSubmit(onSubmit)}>
        <ActionBarFormTitle>{title}</ActionBarFormTitle>
        <ActionBarFieldGroup>
          <FormField
            control={form.control}
            name="baseSchemaName"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>
                    <FormLabelRequired />
                    Base schema
                  </FormLabel>
                  <Select
                    disabled={isEditing}
                    onValueChange={(v) => {
                      field.onChange(v);
                      if (!form.formState.dirtyFields["schema"]) {
                        const schemaWithInfo = getComponentSchemaOrError(v);
                        try {
                          const schema = createDtoFromOASSchema(
                            schemaWithInfo.schema
                          );
                          toast.success("DTO created");
                          form.setValue("schema", schema, {
                            shouldDirty: true,
                            shouldTouch: true,
                            shouldValidate: true,
                          });
                        } catch (err) {
                          toast.error("Unable to create dto schema");
                        }
                      }
                    }}
                    defaultValue={String(field.value)}
                  >
                    <FormControl>
                      <SelectTrigger className="w-[180px]">
                        <SelectValue />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectGroup>
                        {componentObjectSchemasWithInfo.map(
                          (componentSchemaWithInfo) => {
                            return (
                              <SelectItem
                                key={componentSchemaWithInfo.name}
                                value={componentSchemaWithInfo.name}
                              >
                                {componentSchemaWithInfo.name}
                              </SelectItem>
                            );
                          }
                        )}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              );
            }}
          />
          {(baseSchemaName || isEditing) && (
            <>
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      <FormLabelRequired />
                      Object name
                    </FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="description"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Description</FormLabel>
                    <FormControl>
                      <Textarea {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Tabs defaultValue="schema">
                <TabsList>
                  <TabsTrigger value="schema">Schema</TabsTrigger>
                  <TabsTrigger value="advanced">Advanced</TabsTrigger>
                </TabsList>
                <TabsContent value="schema">
                  <ActionBarFieldGroup>
                    <SchemaEditorPreviewContainer
                      title="Schema"
                      className="min-h-[150px]"
                    >
                      <Controller
                        name="schema"
                        control={form.control}
                        render={({ field: { onChange, value } }) => (
                          <SchemaEditor
                            componentsObject={componentsObject}
                            allowTopLevelReferences={false}
                            value={value}
                            onChange={(schema) => {
                              onChange(schema);
                            }}
                            onRemoveRootSchema={() => {
                              form.setValue(
                                "schema",
                                getPristineType("object")
                              );
                            }}
                          />
                        )}
                      />
                    </SchemaEditorPreviewContainer>
                  </ActionBarFieldGroup>
                </TabsContent>
                <TabsContent value="advanced">
                  <ActionBarFieldGroup>
                    <div className="flex flex-row items-center justify-between rounded-lg border p-4 gap-3">
                      <div className="space-y-0.5 basis-[500px]">
                        <FormLabel className="text-base">Template</FormLabel>
                        <FormDescription>
                          Create from template. This overwrites existing
                          entries.
                        </FormDescription>
                        <FormMessage />
                      </div>
                      <Select
                        disabled={isEditing}
                        onValueChange={(v) => {
                          if (v === "") return;
                          const value = dtoTemplateNamesSchema.parse(v);
                          const defaultValues = getDtoTemplateValues(
                            value,
                            baseSchemaName
                          );
                          form.setValue(
                            "description",
                            defaultValues.dtoDescription
                          );
                          form.setValue("name", defaultValues.dtoName);
                        }}
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectGroup>
                            <SelectItem value="create">Create</SelectItem>
                            <SelectItem value="update">Update</SelectItem>
                            <SelectItem value="partial update">
                              Partial update
                            </SelectItem>
                            <SelectItem value="summary">Summary</SelectItem>
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                    </div>
                    <div className="flex flex-row items-center justify-between rounded-lg border p-4 gap-3">
                      <div className="space-y-0.5 basis-[500px]">
                        <FormLabel className="text-base">
                          {hasArraySchape ? "Unbulk" : "Bulk"}
                        </FormLabel>
                        <FormDescription>
                          {hasArraySchape
                            ? "Remove the outer array from the DTO schema"
                            : "Wrap the DTO schema in an array"}
                        </FormDescription>
                        <FormMessage />
                      </div>
                      <Button
                        type="button"
                        size="sm"
                        variant="secondary"
                        onClick={handleBulkChange}
                      >
                        {hasArraySchape ? "Remove bulk" : "Apply bulk"}
                      </Button>
                    </div>
                  </ActionBarFieldGroup>
                </TabsContent>
              </Tabs>
            </>
          )}
        </ActionBarFieldGroup>
        <BtnGroup className="justify-end">
          <SubmitButton>{btnTitle}</SubmitButton>
        </BtnGroup>
      </ActionBarForm>
    </Form>
  );
}

export function FormDtoEdit({
  dtoName,
  value,
  onChange,
}: { dtoName: string } & EditorInputProps) {
  const [, actionBarDispatch] = useActionBarContext();
  const { componentsObject } = useAPIEditorTools({
    value,
    onChange,
  });
  const { update, getSchemaDtoOrError, getBaseSchemaNameOrError } =
    useSchemaDtos({ value, onChange });

  const schema = useMemo(
    () => getSchemaDtoOrError(dtoName),
    [dtoName, getSchemaDtoOrError]
  );

  const onSubmit: SubmitHandler<FormValues> = (values) => {
    update(
      dtoName,
      values.name,
      {
        ...schema,
        description: values.description,
      },
      values.baseSchemaName
    );
    actionBarDispatch({ type: "CLOSE" });
  };

  return (
    <DtoForm
      title="Edit data transfer object"
      btnTitle="Edit"
      onSubmit={onSubmit}
      value={value}
      onChange={onChange}
      componentsObject={componentsObject}
      isEditing={true}
      defaultValues={{
        format: "application/json",
        schema,
        baseSchemaName: getBaseSchemaNameOrError(schema),
        name: dtoName,
      }}
    />
  );
}

export function FormDtoAdd({
  defaultValues,
  value,
  onChange,
}: {
  defaultValues: Partial<FormValues>;
} & EditorInputProps) {
  const [, actionBarDispatch] = useActionBarContext();
  const { getComponentSchemaOrError } = useComponents({ value, onChange });
  const { componentsObject } = useAPIEditorTools({ value, onChange });
  const { add } = useSchemaDtos({ value, onChange });
  const onSubmit: SubmitHandler<FormValues> = (values) => {
    add(
      values.name,
      {
        ...values.schema,
        description: values.description,
      },
      values.baseSchemaName
    );
    actionBarDispatch({ type: "CLOSE" });
  };
  const defaultSchema = useMemo(() => {
    if (!defaultValues.baseSchemaName) return getPristineType("object");
    const componentSchemaWithInfo = getComponentSchemaOrError(
      defaultValues.baseSchemaName
    );
    return createDtoFromOASSchema(componentSchemaWithInfo.schema);
  }, [defaultValues.baseSchemaName, getComponentSchemaOrError]);

  return (
    <DtoForm
      title="Create data transfer object"
      btnTitle="Create"
      onSubmit={onSubmit}
      componentsObject={componentsObject}
      isEditing={false}
      value={value}
      onChange={onChange}
      defaultValues={{
        ...defaultValues,
        schema: defaultSchema,
      }}
    />
  );
}
