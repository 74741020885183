import { Button } from "@/components/_shadui/button";
import { useActionBarContext } from "@/components/contexts/action-bar-context";
import { ActiveElement } from "@/components/contexts/api-editor-context";
import { useAPIEditorTools } from "@/components/contexts/api-editor-context-hooks";
import { usePreviewContext } from "@/components/contexts/operation-preview-context";
import { H3 } from "@/components/headings";
import { IconButton } from "@/components/icon-button";
import {
  EditorInputProps,
  WorkspaceRole,
} from "@/components/module-api-editor/types";
import { PreviewContainer as PreviewContainerWrapper } from "@/components/module-preview-pane/model-preview";
import { useOperation } from "@/hooks/use-operation";
import { testIds } from "@/lib/const";
import { OperationWithInfo } from "@/lib/editor-mutations/oas-operations";
import { generateOASOperationRows } from "@/lib/oas-tools/generate-oas-operation-rows";
import { OASComponentsObject } from "@/lib/types";
import { canEdit, cn, NormIcons, toastError } from "@/lib/utils";
import { ComponentProps, forwardRef, useMemo } from "react";
import { PreviewRow } from "./preview-schema-row";

export function OperationRender({
  operationWithInfo,
  components,
}: {
  operationWithInfo: OperationWithInfo;
  components: OASComponentsObject;
}) {
  const [state] = usePreviewContext();
  const rows = useMemo(() => {
    const { method, urlPath, operation } = operationWithInfo;
    try {
      return generateOASOperationRows({
        initialLevel: 0,
        operation,
        method,
        urlPath,
        format: "application/json",
        components,
        showNestedComponents: state.showNestedComponents,
      });
    } catch (err) {
      toastError(err);
      return [];
    }
  }, [operationWithInfo, components, state.showNestedComponents]);

  return (
    <PreviewContainerWrapper>
      <pre>
        {rows.map((r, i) => {
          return (
            <PreviewRow
              row={r}
              key={`preview-row-${i}`}
              componentsObject={components}
            />
          );
        })}
      </pre>
    </PreviewContainerWrapper>
  );
}

function PreviewContainer({
  children,
  className,
  ...rest
}: ComponentProps<"div">) {
  return (
    <div
      className={cn(
        "mx-4 mb-4 mt-2 grow overflow-auto bg-preview-background rounded-md shadow-lg flex justify-center items-center",
        className
      )}
      {...rest}
    >
      {children}
    </div>
  );
}

export function NothingSelected() {
  return (
    <PreviewContainer>
      <div className="text-center">
        <p className="text-4xl pb-2">⚡️</p>
        <p className="text-muted-foreground text-sm pb-norm font-mono">
          Select a model or operation
          <br /> to display a preview.
        </p>
      </div>
    </PreviewContainer>
  );
}

export function NoOperationInWorkspace({
  value,
  onChange,
  workspaceRole,
}: EditorInputProps & { workspaceRole: WorkspaceRole }) {
  const { resetToExample } = useAPIEditorTools({ value, onChange });
  const [, actionBarDispatch] = useActionBarContext();
  const handleClick = () => {
    actionBarDispatch({ type: "SET_PAGE", payload: { name: "add-operation" } });
  };
  return (
    <PreviewContainer>
      <div
        className="h-full w-full flex justify-center items-center"
        data-testid={testIds.emptyLoadExample}
      >
        <div className="max-w-[500px] text-muted-foreground text-center text-sm">
          <H3 className="text-4xl pb-3">🧑🏽‍💻</H3>
          <p className="font-mono pb-1 px-3">
            API-Fiddle is a playground for REST(ish) API design. Design better
            APIs with DTOs, versioning, and suggested responses.
          </p>
          {canEdit(workspaceRole) ? (
            <div className="flex gap-2 justify-center pt-3">
              <IconButton Icon={NormIcons.Add} onClick={handleClick}>
                Add operation
              </IconButton>
              <Button
                size="sm"
                variant="primary"
                onClick={() => resetToExample("bookStore")}
              >
                📚 Load example
              </Button>
            </div>
          ) : (
            <div className="flex gap-2 justify-center pt-3">
              <span className="text-muted-foreground">
                You have read permissions to this workspace
              </span>
            </div>
          )}
        </div>
      </div>
    </PreviewContainer>
  );
}

export const OperationPreview = forwardRef<
  HTMLDivElement,
  {
    activeElement: ActiveElement;
    componentsObject: OASComponentsObject;
  } & EditorInputProps
>(({ activeElement, componentsObject, value, onChange }, _ref) => {
  const { getOperationWithInfo } = useOperation({ value, onChange });
  if (activeElement.type !== "operation")
    throw new Error("Active element needs to be operation");

  const operationWithInfo = useMemo(
    () => getOperationWithInfo(activeElement.operationId),
    [activeElement.operationId, getOperationWithInfo]
  );

  return (
    <OperationRender
      operationWithInfo={operationWithInfo}
      components={componentsObject}
    />
  );
});
