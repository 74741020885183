import { ColorBadge } from "@/components/_shadui/color-badge";
import {
  EditorInputProps,
  WorkspaceRole,
} from "@/components/module-api-editor/types";
import {
  ActiveComponentButton,
  ComponentParameterItem,
  ItemLi,
  OperationItem,
  ResponseItem,
  SchemaItem,
  SectionUl,
} from "@/components/module-visual-editor/editor-tree-navigation-items";
import {
  BtnGroup,
  DeleteButton,
  EditButton,
} from "@/components/module-visual-editor/shared-components";
import { useComponents } from "@/hooks/use-components";
import { useOperation } from "@/hooks/use-operation";
import { useTags } from "@/hooks/use-tags";
import { OperationWithInfo } from "@/lib/editor-mutations/oas-operations";
import { capitalize, supportedHttpVerbs } from "@/lib/helpers";
import { OASTag, SupportedHTTPVerbs } from "@/lib/types";
import { useMemo } from "react";
import { useActionBarContext } from "../contexts/action-bar-context";
import { useAPIEditorTools } from "../contexts/api-editor-context-hooks";
import { useComponentParameters } from "@/hooks/use-component-parameters";
import { canEdit } from "@/lib/utils";
import { HideEmptyListWhenNonEditor } from "@/components/module-api-editor";

function TagTreeObject({
  tagName,
  workspaceRole,
  ...props
}: EditorInputProps & { tagName: string; workspaceRole: WorkspaceRole }) {
  const [, actionBarDispatch] = useActionBarContext();
  const { get, remove } = useTags(props);

  const handleEditClick = (tagName: string) => {
    const tagObject = get(tagName);
    if (!tagObject) throw new Error("Cannot find tag object");
    actionBarDispatch({
      type: "SET_PAGE",
      payload: {
        name: "edit-tag",
        context: {
          tagObject,
        },
      },
    });
  };

  const handleRemoveClick = (tagName: string) => {
    const tagObject = get(tagName);
    if (!tagObject) throw new Error("Cannot find tag object");
    remove(tagName);
  };

  const showEditButtons = tagName !== "default" && canEdit(workspaceRole);
  return (
    <div className="flex justify-between items-end pb-0 mb-2 pr-3 pl-3 border-border">
      <ColorBadge color="gray">{capitalize(tagName)}</ColorBadge>{" "}
      {showEditButtons && (
        <BtnGroup>
          <EditButton onClick={() => handleEditClick(tagName)} />
          <DeleteButton onClick={() => handleRemoveClick(tagName)} />
        </BtnGroup>
      )}
    </div>
  );
}

export function EditorTreeNavigation({
  value,
  onChange,
  workspaceRole,
}: EditorInputProps & { workspaceRole: WorkspaceRole }) {
  const [, actionBarDispatch] = useActionBarContext();
  const { getAll } = useTags({ value, onChange });
  const { getOperationsWithInfo } = useOperation({ value, onChange });
  const { getComponentSchemas, getComponentResponses } = useComponents({
    value,
    onChange,
  });
  const { getComponentParametersWithInfo } = useComponentParameters({
    value,
    onChange,
  });
  const { setActiveElement, activeElement } = useAPIEditorTools({
    value,
    onChange,
  });

  const componentSchemasWithInfo = useMemo(
    () => getComponentSchemas(),
    [getComponentSchemas]
  );

  const componentResponsesWithInfo = useMemo(
    () => getComponentResponses(),
    [getComponentResponses]
  );

  const componentParametersWithInfo = useMemo(
    () => getComponentParametersWithInfo(),
    [getComponentParametersWithInfo]
  );

  const navigationTree = useMemo(() => {
    const allOperations = getOperationsWithInfo();
    const operations = [...allOperations];

    operations.sort((a, b) => {
      const indexA = supportedHttpVerbs.indexOf(
        a.method.toLowerCase() as SupportedHTTPVerbs
      );
      const indexB = supportedHttpVerbs.indexOf(
        b.method.toLowerCase() as SupportedHTTPVerbs
      );

      // If method not found in the order list, assign a large index value
      const orderA = indexA === -1 ? supportedHttpVerbs.length : indexA;
      const orderB = indexB === -1 ? -supportedHttpVerbs.length : indexB;

      return orderA - orderB;
    });

    const res: Record<string, OperationWithInfo[]> = {};

    operations.forEach((operationWithInfo) => {
      if (!operationWithInfo.operation.tags?.length) {
        if (!res.default) res.default = [];
        res.default.push(operationWithInfo);
      } else {
        const firstTag = operationWithInfo.operation.tags[0];
        if (!res[firstTag]) res[firstTag] = [];
        res[firstTag].push(operationWithInfo);
      }
    });

    const sortedList = Object.entries(res).sort(([tagA], [tagB]) => {
      if (tagA === "default") {
        return -1;
      } else {
        return tagB.localeCompare(tagA);
      }
    });
    return { sortedList, map: res };
  }, [getOperationsWithInfo]);

  const unusedTags = useMemo(() => {
    const allTags = getAll();
    const unusedTags: OASTag[] = [];
    allTags.forEach((tag) => {
      if (!navigationTree.map[tag.name]) unusedTags.push(tag);
    });
    return unusedTags;
  }, [getAll, navigationTree.map]);

  const isGeneralInformationActive = !!(
    activeElement && activeElement.type === "general_information"
  );
  const isCreateClientActive = !!(
    activeElement && activeElement.type === "create_client"
  );

  return (
    <div className="grow overflow-auto flex flex-col items-stretch gap-4 pb-12">
      <SectionUl title="General" workspaceRole={workspaceRole}>
        <ItemLi
          isActive={isGeneralInformationActive}
          onClick={() => {
            setActiveElement({
              type: "general_information",
            });
          }}
        >
          <ActiveComponentButton isActive={isGeneralInformationActive} />
          <span className="inline-block text-primary text-sm">
            General information
          </span>
        </ItemLi>
        <ItemLi
          isActive={isCreateClientActive}
          onClick={() => {
            setActiveElement({
              type: "create_client",
            });
          }}
        >
          <ActiveComponentButton isActive={isCreateClientActive} />
          <span className="inline-block text-primary text-sm">
            Swagger & client
          </span>
        </ItemLi>
      </SectionUl>
      <SectionUl
        workspaceRole={workspaceRole}
        title="Operations"
        className="px-0"
        onAddClick={() => {
          actionBarDispatch({
            type: "SET_PAGE",
            payload: {
              name: "add-operation",
            },
          });
        }}
      >
        {navigationTree.sortedList.map(([tagName, operationWithInfoArr]) => {
          return (
            <div key={tagName} className="pt-2">
              <TagTreeObject
                value={value}
                onChange={onChange}
                workspaceRole={workspaceRole}
                tagName={tagName}
              />
              <SectionUl workspaceRole={workspaceRole}>
                {operationWithInfoArr.map(({ method, urlPath, operation }) => (
                  <OperationItem
                    key={`visual-route-${method}-${urlPath}`}
                    method={method}
                    urlPath={urlPath}
                    operation={operation}
                    workspaceRole={workspaceRole}
                    value={value}
                    onChange={onChange}
                  />
                ))}
              </SectionUl>
            </div>
          );
        })}
        <HideEmptyListWhenNonEditor
          workspaceRole={workspaceRole}
          list={unusedTags}
        >
          {unusedTags.map((tagObject) => (
            <TagTreeObject
              key={tagObject.name}
              value={value}
              onChange={onChange}
              tagName={tagObject.name}
              workspaceRole={workspaceRole}
            />
          ))}
        </HideEmptyListWhenNonEditor>
      </SectionUl>
      <HideEmptyListWhenNonEditor
        workspaceRole={workspaceRole}
        list={componentSchemasWithInfo}
      >
        <SectionUl
          title="Models"
          workspaceRole={workspaceRole}
          onAddClick={() => {
            actionBarDispatch({
              type: "SET_PAGE",
              payload: {
                name: "add-component-schema",
              },
            });
          }}
        >
          {componentSchemasWithInfo.map((componentSchemaWithInfo) => (
            <SchemaItem
              key={componentSchemaWithInfo.name}
              value={value}
              onChange={onChange}
              componentSchemaWithInfo={componentSchemaWithInfo}
              workspaceRole={workspaceRole}
            />
          ))}
        </SectionUl>
      </HideEmptyListWhenNonEditor>
      <HideEmptyListWhenNonEditor
        workspaceRole={workspaceRole}
        list={componentResponsesWithInfo}
      >
        <SectionUl title="Responses" workspaceRole={workspaceRole}>
          {componentResponsesWithInfo.map((componentResponseWithInfo) => (
            <ResponseItem
              key={componentResponseWithInfo.responseName}
              value={value}
              onChange={onChange}
              workspaceRole={workspaceRole}
              componentResponseWithInfo={componentResponseWithInfo}
            />
          ))}
        </SectionUl>
      </HideEmptyListWhenNonEditor>
      <HideEmptyListWhenNonEditor
        workspaceRole={workspaceRole}
        list={componentParametersWithInfo}
      >
        <SectionUl
          title="Parameters"
          workspaceRole={workspaceRole}
          onAddClick={() => {
            actionBarDispatch({
              type: "SET_PAGE",
              payload: {
                name: "add-component-parameter",
              },
            });
          }}
        >
          {componentParametersWithInfo.map((componentParameterWithInfo) => (
            <ComponentParameterItem
              key={componentParameterWithInfo.label}
              value={value}
              onChange={onChange}
              componentParameterWithInfo={componentParameterWithInfo}
              workspaceRole={workspaceRole}
            />
          ))}
        </SectionUl>
      </HideEmptyListWhenNonEditor>
    </div>
  );
}
