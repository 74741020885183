import {
  addPlaceholderToPath,
  getPathProperty,
  isNonArraySchemaObject,
} from "@/lib/oas-tools/oas-schema-utils";
import { OASSchema } from "@/lib/types";

/**
 * Point path to `properties` of an object
 */
export const addPlaceholderToSchema = ({
  schema,
  path,
}: {
  schema: OASSchema;
  path: string;
}) => {
  let newSchema = structuredClone(schema);
  const property = getPathProperty(schema, path);
  if (!isNonArraySchemaObject(property))
    throw new Error("Schema is not of type NonArray");
  const existingKyes = Object.keys(property.properties || {});

  if (existingKyes.some((k) => k.startsWith("%%%"))) {
    throw new Error("There is an existing placeholder");
  }

  const placeholderValues = addPlaceholderToPath(newSchema, path);
  newSchema = placeholderValues[0];

  return { schema: newSchema, placeholderId: placeholderValues[1] };
};
