import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/_shadui/form";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/_shadui/select";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/components/_shadui/tabs";
import { Textarea } from "@/components/_shadui/textarea";
import ApplyPaginationForm from "@/components/apply-pagination-form";
import { useActionBarContext } from "@/components/contexts/action-bar-context";
import { useAPIEditorTools } from "@/components/contexts/api-editor-context-hooks";
import { FormLabelRequired } from "@/components/form-label-required";
import { ActionBarReferenceMessage } from "@/components/module-action-bar";
import {
  ActionBarFieldGroup,
  ActionBarForm,
  ActionBarFormTitle,
} from "@/components/module-action-bar/elements";
import { EditorInputProps } from "@/components/module-api-editor/types";
import { SchemaEditor } from "@/components/module-schema-editor/schema-editor";
import {
  BtnGroup,
  SubmitButton,
} from "@/components/module-visual-editor/shared-components";
import { SchemaEditorPreviewContainer } from "@/components/preview-container";
import { useResponses } from "@/hooks/use-responses";
import {
  defaultResponseCodeMap,
  HttpStatus,
  httpStatusMap,
  supportedHttpStatusCodes,
} from "@/lib/helpers";
import { httpStatusDescriptions } from "@/lib/oas-tools/http-status-default-description";
import { getHttpStatusResponse } from "@/lib/oas-tools/https-status-default-schemas";
import { httpVerbStatusCodes } from "@/lib/oas-tools/https-verb-status-codes";
import { zodSchemaSchema } from "@/lib/oas-tools/oas-schema-utils";
import { deref, isReference } from "@/lib/oas-tools/oas-tag-helpers";
import {
  OASComponentsObject,
  OASResponse,
  SupportedContentFormats,
  SupportedHTTPVerbs,
} from "@/lib/types";
import { NormIcons } from "@/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";

type Row = { code: number; message: string; isRecommended: boolean };

const formSchema = z.object({
  responseCode: z.number().int(),
  schema: zodSchemaSchema,
  description: z.string().trim().min(1, "Required"),
});

type FormValues = z.infer<typeof formSchema>;

export function FormResponse({
  onSubmit,
  method,
  defaultValues,
  componentsObject,
  isEditing,
  title,
  btnTitle,
  isDisabled = false,
}: {
  onSubmit: (v: FormValues) => unknown;
  method: SupportedHTTPVerbs;
  defaultValues: Partial<FormValues>;
  componentsObject: OASComponentsObject;
  isEditing: boolean;
  isDisabled?: boolean;
  responseName?: string;
  title: string;
  btnTitle: string;
}) {
  const [schemaDirty, setSchemaDirty] = useState(isEditing);
  const defaultResponseCode = defaultResponseCodeMap[method];
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      ...{
        responseCode: defaultResponseCode,
        schema: getHttpStatusResponse(defaultResponseCode),
      },
      ...defaultValues,
    },
  });

  const dirty = form.formState.dirtyFields;

  const schema = form.watch("schema");
  const responseCode = form.watch("responseCode");

  const canPaginate =
    method === "get" && responseCode >= 200 && responseCode <= 299;

  const rows: Row[] = useMemo(() => {
    const res: Row[] = [];
    const suggestionObject = httpVerbStatusCodes[method];
    const suggestedStatusCodeDescriptions = [
      ...suggestionObject.tamplates,
      ...suggestionObject.components,
    ];
    const suggestedStatusCodes = suggestedStatusCodeDescriptions.map(
      (d) => d.code
    );
    suggestedStatusCodeDescriptions.forEach((description) => {
      const value = httpStatusMap[description.code];
      res.push({
        code: description.code,
        message: value.message,
        isRecommended: true,
      });
    });
    supportedHttpStatusCodes
      .filter((e) => !suggestedStatusCodes.includes(e))
      .forEach((code) => {
        const value = httpStatusMap[code];
        res.push({
          code,
          message: value.message,
          isRecommended: false,
        });
      });
    return res;
  }, [method]);

  return (
    <Form {...form}>
      <ActionBarForm onSubmit={form.handleSubmit(onSubmit)}>
        <ActionBarFormTitle>{title}</ActionBarFormTitle>
        <ActionBarFieldGroup>
          <FormField
            control={form.control}
            name="responseCode"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>
                    <FormLabelRequired />
                    Response code
                  </FormLabel>
                  <Select
                    disabled={isEditing || isDisabled}
                    onValueChange={(v) => {
                      field.onChange(+v);
                      if (!schemaDirty) {
                        form.setValue(
                          "schema",
                          getHttpStatusResponse(+v as HttpStatus)
                        );
                      }
                      if (!dirty["description"]) {
                        form.setValue(
                          "description",
                          httpStatusDescriptions[+v as HttpStatus]
                            ?.description || ""
                        );
                      }
                    }}
                    defaultValue={String(field.value)}
                  >
                    <FormControl>
                      <SelectTrigger className="w-[180px]">
                        <SelectValue />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectGroup>
                        {rows.map((row) => {
                          return (
                            <SelectItem
                              key={`row-${row.code}-${row.isRecommended ? "recommended" : "all-codes"}`}
                              value={`${row.code}`}
                            >
                              <div className="flex items-center">
                                {row.isRecommended && (
                                  <NormIcons.Recommended
                                    size={16}
                                    className="mr-2 text-brand"
                                  />
                                )}
                                {row.code} {row.message}
                              </div>
                            </SelectItem>
                          );
                        })}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              );
            }}
          />
          <FormField
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  <FormLabelRequired />
                  Description
                </FormLabel>
                <FormControl>
                  <Textarea disabled={isDisabled} placeholder="" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </ActionBarFieldGroup>
        {canPaginate && !isDisabled ? (
          <Tabs defaultValue="schema">
            <TabsList>
              <TabsTrigger value="schema">Schema</TabsTrigger>
              <TabsTrigger value="options">Options</TabsTrigger>
            </TabsList>
            <TabsContent value="schema" className="h-[330px]">
              <SchemaEditorPreviewContainer
                title="Schema"
                isDisabled={isDisabled}
              >
                <Controller
                  name="schema"
                  control={form.control}
                  render={({ field: { onChange, value } }) => (
                    <SchemaEditor
                      allowTopLevelReferences={true}
                      componentsObject={componentsObject}
                      isDisabled={isDisabled}
                      value={value}
                      onChange={(schema) => {
                        if (!schemaDirty) setSchemaDirty(true);
                        onChange(schema);
                      }}
                      onRemoveRootSchema={() => {
                        form.setValue(
                          "schema",
                          getHttpStatusResponse(responseCode as HttpStatus)
                        );
                      }}
                    />
                  )}
                />
              </SchemaEditorPreviewContainer>
            </TabsContent>
            <TabsContent value="options" className="h-[330px]">
              <ApplyPaginationForm
                onChangeSchema={(schema) => form.setValue("schema", schema)}
                defaultValues={{}}
                schema={schema}
              />
            </TabsContent>
          </Tabs>
        ) : (
          <SchemaEditorPreviewContainer
            title="Schema"
            className="h-[330px]"
            isDisabled={isDisabled}
          >
            <SchemaEditor
              componentsObject={componentsObject}
              allowTopLevelReferences={true}
              isDisabled={isDisabled}
              value={schema}
              onChange={(schema) => {
                if (!schemaDirty) setSchemaDirty(true);
                form.setValue("schema", schema);
              }}
              onRemoveRootSchema={() => {
                form.setValue(
                  "schema",
                  getHttpStatusResponse(responseCode as HttpStatus)
                );
              }}
            />
          </SchemaEditorPreviewContainer>
        )}
        {!isDisabled && (
          <BtnGroup className="justify-end">
            <SubmitButton disabled={isDisabled} aria-disabled={isDisabled}>
              {btnTitle}
            </SubmitButton>
          </BtnGroup>
        )}
      </ActionBarForm>
    </Form>
  );
}

export function FormResponseEdit({
  operationId,
  method,
  response,
  format = "application/json",
  responseCode,
  value,
  onChange,
}: {
  operationId: string;
  method: SupportedHTTPVerbs;
  format: SupportedContentFormats;
  response: OASResponse;
  responseCode: HttpStatus;
} & EditorInputProps) {
  const { componentsObject } = useAPIEditorTools({ value, onChange });
  const { update, dereferenceResponse } = useResponses({ value, onChange });
  const { setActiveElement } = useAPIEditorTools({ value, onChange });

  const [, actionbarDispatch] = useActionBarContext();

  const onSubmit = (values: FormValues, response: OASResponse) => {
    if (isReference(response)) return;
    update({
      newResponseCode: responseCode,
      oldResponseCode: responseCode,
      operationId,
      response: {
        ...response,
        description: values.description || "",
        content: {
          ...response.content,
          [format]: {
            schema: values.schema,
          },
        },
      },
    });
    actionbarDispatch({
      type: "CLOSE",
    });
  };

  const isRef = isReference(response);

  const handleVisitResponse = (responseName: string) => {
    actionbarDispatch({
      type: "CLOSE",
    });
    setActiveElement({ type: "response", responseName });
  };

  const handleDereference = () => {
    actionbarDispatch({
      type: "CLOSE",
    });
    dereferenceResponse({ operationId, responseCode });
  };

  if (isRef) {
    const responseName = deref(response.$ref);
    return (
      <ActionBarReferenceMessage
        title="Reference"
        description={
          <p>
            This response points to{" "}
            <span
              role="button"
              onClick={() => handleVisitResponse(responseName)}
              className="text-brand underline cursor-pointer"
            >
              {responseName}
            </span>
            . Edit the component or dereference this response.
          </p>
        }
        onVisitReference={() => handleVisitResponse(responseName)}
        onDereference={handleDereference}
      />
    );
  }

  return (
    <FormResponse
      title="Edit response"
      btnTitle="Edit"
      isDisabled={isRef}
      isEditing={true}
      onSubmit={(values) => onSubmit(values, response)}
      method={method}
      componentsObject={componentsObject}
      defaultValues={{
        schema:
          response?.content?.[format].schema ||
          getHttpStatusResponse(responseCode),
        responseCode: responseCode,
        description: response.description || `Response ${responseCode}`,
      }}
    />
  );
}

export function FormResponseAdd({
  operationId,
  method,
  value,
  onChange,
}: {
  operationId: string;
  method: SupportedHTTPVerbs;
} & EditorInputProps) {
  const defaultResponseCode = defaultResponseCodeMap[method];
  const { componentsObject } = useAPIEditorTools({ value, onChange });
  const { addResponse } = useResponses({ value, onChange });
  const [, actionbarDispatch] = useActionBarContext();

  const onSubmit = (values: FormValues) => {
    addResponse({
      operationId,
      responseCode: values.responseCode as HttpStatus,
      response: {
        description: values.description,
        content: {
          "application/json": {
            schema: values.schema,
          },
        },
      },
    });
    actionbarDispatch({
      type: "CLOSE",
    });
  };

  return (
    <FormResponse
      title="Add response"
      btnTitle="Create"
      isEditing={false}
      onSubmit={onSubmit}
      method={method}
      componentsObject={componentsObject}
      defaultValues={{
        schema: getHttpStatusResponse(defaultResponseCode),
        responseCode: defaultResponseCode,
        description:
          httpStatusDescriptions[defaultResponseCode]?.description || "",
      }}
    />
  );
}
