import { OASSchema } from "@/lib/types";
import { useCallback } from "react";
import {
  AllPropertyTypes,
  changeType,
  changeTypeToModel,
  ComponentId,
} from "@/lib/oas-tools/oas-schema-utils";
import { useDisclosure } from "@/hooks/use-disclosure";
import { cloneDeep } from "lodash";

export type ChangeTypeOption =
  | {
      type: "type";
      typeOption: AllPropertyTypes;
    }
  | { type: "model"; componentId: ComponentId };

export function useChangeType(
  path: string,
  rootSchema: OASSchema,
  onChange: (e: OASSchema) => void
) {
  const disclosure = useDisclosure();

  const onSelect = useCallback(
    (option: ChangeTypeOption) => {
      // don't mutate the root schema
      const rootSchemaCp = cloneDeep(rootSchema);

      let newSchema: OASSchema = rootSchemaCp;

      switch (option.type) {
        case "type":
          newSchema = changeType(rootSchemaCp, path, option.typeOption);
          break;
        case "model":
          newSchema = changeTypeToModel(rootSchemaCp, path, option.componentId);
          break;
        default:
          // Exhaustive check: if option.type is not handled, TypeScript will show an error
          const _exhaustiveCheck: never = option;
          throw new Error(
            `Unhandled option type: ${(_exhaustiveCheck as any).type}`
          );
      }

      onChange(newSchema);
      disclosure.onClose();
      return newSchema;
    },
    [disclosure, path, rootSchema, onChange]
  );

  return {
    onSelect,
    ...disclosure,
  };
}
