// import { addResponseToOperationInDefinition } from "@/lib/editor-mutations/oas-operations";
import { getOperationsWithInfoFromDefinition } from "@/lib/editor-mutations/oas-operations";
import {
  removeSchemaRefsFromDefinition,
  updateSchemaRefsInDefinition,
} from "@/lib/editor-mutations/oas-references";
import { addResponseToOperationInDefinition } from "@/lib/editor-mutations/oas-responses";
import { NoRefsHereError } from "@/lib/errors";
import { HttpStatus } from "@/lib/helpers";
import { httpStatusDescriptions } from "@/lib/oas-tools/http-status-default-description";
import { getHttpStatusResponse } from "@/lib/oas-tools/https-status-default-schemas";
import { TemplateResponseCodes } from "@/lib/oas-tools/https-verb-status-codes";
import { isReference } from "@/lib/oas-tools/oas-tag-helpers";
import {
  OASComponentsResponses,
  OASDefinition,
  OASReferenceObject,
  OASResponseObject,
  OASSchema,
} from "@/lib/types";
import { isEmpty } from "lodash";
import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import set from "lodash/set";
import unset from "lodash/unset";

export function addComponentSchemaToDefinition({
  definition,
  name,
  schema,
}: {
  definition: OASDefinition;
  name: string;
  schema: OASSchema;
}) {
  const cp = cloneDeep(definition);
  set(cp, `components.schemas.${name}`, schema);
  return cp;
}

export function getComponentSchemasFromDefinition({
  definition,
}: {
  definition: OASDefinition;
}): Record<string, OASSchema> {
  return definition.components?.schemas || {};
}

export function getComponentSchemaFromDefinition({
  definition,
  schemaName,
}: {
  definition: OASDefinition;
  schemaName: string;
}): OASSchema | undefined {
  const componentSchemas = getComponentSchemasFromDefinition({ definition });
  const found = componentSchemas[schemaName];
  if (found && "$ref" in found) throw new NoRefsHereError();
  return found;
}

export function editComponentSchemaInDefinition({
  oldName,
  newName,
  definition,
  newComponentSchema,
}: {
  definition: OASDefinition;
  oldName: string;
  newName: string;
  newComponentSchema: OASSchema | OASReferenceObject;
}) {
  if (
    oldName !== newName &&
    getComponentSchemaFromDefinition({
      definition,
      schemaName: newName,
    })
  ) {
    throw new Error("A response with this name already exists");
  }

  let newDefinition = definition;
  // IMPORTANT: only if names differ we update the refs and remove
  // the response
  if (oldName !== newName) {
    newDefinition = removeComponentSchemaFromDefinition({
      definition: updateSchemaRefsInDefinition({
        definition,
        oldRefName: oldName,
        newRefName: newName,
        type: "schemas",
      }),
      schemaName: oldName,
    });
  }
  return addComponentSchemaToDefinition({
    definition: newDefinition,
    name: newName,
    schema: newComponentSchema,
  });
}

export function removeSchemaDtosFromDefinition(
  definition: OASDefinition,
  schemaName: string
): OASDefinition {
  const shallowCp = { ...definition };
  const schemas = shallowCp.components?.schemas;
  if (!schemas) return shallowCp;
  const deletedDtoNames = [];
  for (const [key, value] of Object.entries(schemas)) {
    if (
      value["x-fiddle-dto-info"] &&
      value["x-fiddle-dto-info"]?.baseSchemaName === schemaName
    ) {
      deletedDtoNames.push(key);
      delete schemas[key];
    }
  }
  // Delete all references to dtos that exist in the definition
  let result = shallowCp;
  for (const dtoName of deletedDtoNames) {
    result = removeSchemaRefsFromDefinition(result, dtoName, "schemas");
  }
  return result;
}

export function removeComponentSchemaFromDefinition({
  definition,
  schemaName,
}: {
  definition: OASDefinition;
  schemaName: string;
}) {
  const cp = cloneDeep(definition);
  const schema: OASSchema | undefined = get(
    cp,
    `components.schemas.${schemaName}`
  );
  if (!schema) throw new Error("Old component schema not found");
  unset(cp, `components.schemas.${schemaName}`);
  return removeSchemaDtosFromDefinition(
    removeSchemaRefsFromDefinition(cp, schemaName, "schemas"),
    schemaName
  );
}

export function getComponentResponsesFromDefinition({
  definition,
}: {
  definition: OASDefinition;
}): OASComponentsResponses {
  return (definition.components?.responses || {}) as Record<
    string,
    OASResponseObject
  >;
}

export function getComponentResponseFromDefinition({
  definition,
  responseName,
}: {
  definition: OASDefinition;
  responseName: string;
}): OASResponseObject | undefined {
  const componentRespones = getComponentResponsesFromDefinition({ definition });
  const found = componentRespones[responseName];
  if (found && "$ref" in found) throw new NoRefsHereError();
  return found;
}

export function addComponentResponseToDefinition({
  definition,
  name,
  responseObject,
  skipCheckForExisting = false,
}: {
  definition: OASDefinition;
  name: string;
  responseObject: OASResponseObject;
  skipCheckForExisting?: boolean;
}) {
  if (!skipCheckForExisting) {
    const found = getComponentResponseFromDefinition({
      definition,
      responseName: name,
    });
    if (found) throw new Error("A response with this name already exists");
  }

  const cp = cloneDeep(definition);
  set(cp, `components.responses.${name}`, responseObject);
  return cp;
}

export function removeComponentResponseFromDefinition({
  definition,
  responseName,
}: {
  definition: OASDefinition;
  responseName: string;
}) {
  const cp = cloneDeep(definition);

  const found = getComponentResponseFromDefinition({
    definition: cp,
    responseName,
  });
  if (!found) throw new Error("No response found");

  unset(cp, `components.responses.${responseName}`);

  const operations = getOperationsWithInfoFromDefinition(cp);

  // dereference all responses
  operations.forEach(({ operation }) => {
    Object.entries(operation.responses || {}).forEach(
      ([responseCode, response]) => {
        if (!isReference(response)) return;
        if (response.$ref !== `#/components/responses/${responseName}`) return;
        operation.responses![responseCode] = found;
      }
    );
  });

  // clean up empty object
  if (isEmpty(cp.components?.responses)) {
    delete cp.components?.responses;
  }

  return cp;
}

export function editComponentResponseInDefinition({
  oldName,
  newName,
  definition,
  newResponseObject,
}: {
  definition: OASDefinition;
  oldName: string;
  newName: string;
  newResponseObject: OASResponseObject;
}) {
  if (
    oldName !== newName &&
    getComponentResponseFromDefinition({
      definition,
      responseName: newName,
    })
  ) {
    throw new Error("A response with this name already exists.");
  }

  let newDefinition = definition;

  // IMPORTANT: only if names differ we update the refs and remove
  // the response
  if (oldName !== newName) {
    newDefinition = removeComponentResponseFromDefinition({
      definition: updateSchemaRefsInDefinition({
        definition,
        oldRefName: oldName,
        newRefName: newName,
        type: "responses",
      }),
      responseName: oldName,
    });
  }

  return addComponentResponseToDefinition({
    definition: newDefinition,
    name: newName,
    responseObject: newResponseObject,
    skipCheckForExisting: true,
  });
}

export function addComponentResponseToOperationByResponseName({
  operationId,
  componentResponseName,
  definition,
  skipCheckForExisting,
  newResponse,
  responseCode,
}: {
  operationId: string;
  componentResponseName: string;
  definition: OASDefinition;
  newResponse: OASResponseObject;
  skipCheckForExisting?: boolean;
  responseCode: HttpStatus;
}) {
  let newDefinition = definition;

  const existingResponse = getComponentResponseFromDefinition({
    definition,
    responseName: componentResponseName,
  });
  // If the component response does not exist we create it
  if (!existingResponse) {
    newDefinition = addComponentResponseToDefinition({
      definition,
      name: componentResponseName,
      responseObject: newResponse,
    });
  }

  // Add the reference to the response to the operation
  newDefinition = addResponseToOperationInDefinition({
    responseCode,
    operationId,
    definition: newDefinition,
    skipCheckForExisting,
    response: {
      $ref: `#/components/responses/${componentResponseName}`,
    },
  });

  return newDefinition;
}

const componentResponseMap: Record<TemplateResponseCodes, string> = {
  204: "NoContent",
  202: "Accepted",
  400: "BadRequest",
  401: "Unauthorized",
  403: "Forbidden",
  404: "NotFound",
  409: "Conflict",
  422: "UnprocessableEntity",
  500: "InternalServerErrror",
};

export function addComponentResponseByCodeToOperationInDefinition({
  operationId,
  responseCode,
  definition,
  skipCheckForExisting = false,
}: {
  operationId: string;
  responseCode: TemplateResponseCodes;
  definition: OASDefinition;
  skipCheckForExisting?: boolean;
}) {
  const responseName = componentResponseMap[responseCode];
  const description = httpStatusDescriptions[responseCode].description;
  const schema = getHttpStatusResponse(responseCode);

  return addComponentResponseToOperationByResponseName({
    definition,
    responseCode,
    componentResponseName: responseName,
    operationId,
    newResponse: { description, content: { "application/json": { schema } } },
    skipCheckForExisting,
  });
}
