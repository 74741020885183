import { Button } from "@/components/_shadui/button";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/_shadui/dropdown-menu";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/_shadui/tooltip";
import { useAPIEditorTools } from "@/components/contexts/api-editor-context-hooks";
import { usePreviewContext } from "@/components/contexts/operation-preview-context";
import {
  AboutDialog,
  FeedbackDialog,
  NewsletterDialog,
  ShareDialog,
} from "@/components/module-api-editor/header-dialogs";
import { SignupDialog } from "@/components/module-api-editor/signup-dialog";
import {
  EditorInputProps,
  WorkspaceRole,
} from "@/components/module-api-editor/types";
import { useHandleCopy } from "@/components/module-api-editor/use-handle-copy";
import { useDisclosure } from "@/hooks/use-disclosure";
import { useOperation } from "@/hooks/use-operation";
import { canEdit, cn, DEFAULT_ICON_SIZE, NormIcons } from "@/lib/utils";
import { Link } from "@tanstack/react-router";
import { SquareMenu } from "lucide-react";
import { ComponentProps, forwardRef, useRef } from "react";
import { useActionBarContext } from "../contexts/action-bar-context";
import { InfoIcon } from "@/components/info-icon";
import { _DEV_ } from "@/lib/http-utils";

const copyMsg = "Copy to clipboard";

const ToolbarBtn = forwardRef<HTMLButtonElement, ComponentProps<typeof Button>>(
  ({ children, className, ...rest }, ref) => {
    return (
      <Button
        size="sm"
        variant="ghost"
        className={cn("text-muted-foreground", className)}
        ref={ref}
        {...rest}
      >
        {children}
      </Button>
    );
  }
);

export function Toolbar({
  workspaceSlug,
  organizationSlug,
  onChange,
  value,
  isAuthed,
  hasOperations,
  workspaceRole,
}: {
  workspaceSlug: string | undefined;
  organizationSlug: string | undefined;
  isAuthed: boolean;
  hasOperations: boolean;
  workspaceRole: WorkspaceRole;
} & EditorInputProps) {
  const [previewState, previewDispatch] = usePreviewContext();
  const [, actionBarDispatch] = useActionBarContext();

  const { getOperationsWithInfo } = useOperation({ value, onChange });
  const {
    goBackInHistory,
    canGoBackInHistory,
    activeElement,
    componentsObject,
  } = useAPIEditorTools({
    value,
    onChange,
  });
  const {
    handleCopyAllOperations,
    handleCopyOperation,
    handleExportAsPng,
    handleCopyComponent,
  } = useHandleCopy({ onChange, value });

  const feedbackDisclosure = useDisclosure();
  const aboutDisclosure = useDisclosure();
  const shareDialogDisclosure = useDisclosure();
  const newsletterDisclosure = useDisclosure();
  const signUpDialogDisclosure = useDisclosure();

  const downloadBtnRef = useRef<HTMLDivElement>(null);

  const handleCopyActive = () => {
    if (!activeElement) return;
    handleCopyOperation({ activeElement, componentsObject });
  };

  const handleCopyAll = () => {
    const operationsWithInfo = getOperationsWithInfo();
    handleCopyAllOperations({
      operationsWithInfo,
      componentsObject,
    });
  };

  const handleShowNestedComponents = (v: boolean) => {
    previewDispatch({
      type: "SET_SHOW_NESTED_COMPONENTS",
      payload: v,
    });
  };

  const handleExportOperationAsPng = async () => {
    if (!activeElement) return;
    await handleExportAsPng({ activeElement, componentsObject });
  };

  const handleCopyActiveModel = () => {
    if (activeElement?.type === "model") {
      handleCopyComponent({ activeElement, componentsObject });
    }
  };

  const handleDebugClick = () => {
    actionBarDispatch({
      type: "SET_PAGE",
      payload: {
        name: "debug",
      },
    });
  };

  const isEditor = canEdit(workspaceRole);
  return (
    <div className="flex items-center gap-2 pt-2">
      {activeElement?.type === "operation" && (
        <>
          <Tooltip>
            <TooltipTrigger asChild>
              <ToolbarBtn onClick={handleExportOperationAsPng}>
                <NormIcons.Image className="mr-2" size={DEFAULT_ICON_SIZE} />
                <span>Get PNG</span>
              </ToolbarBtn>
            </TooltipTrigger>
            <TooltipContent>Export as PNG image</TooltipContent>
          </Tooltip>
          <Tooltip>
            <TooltipTrigger asChild>
              <ToolbarBtn onClick={handleCopyActive}>
                <NormIcons.Clipboard
                  size={DEFAULT_ICON_SIZE}
                  className="mr-2"
                />
                <span>Copy</span>
              </ToolbarBtn>
            </TooltipTrigger>
            <TooltipContent>{copyMsg}</TooltipContent>
          </Tooltip>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <ToolbarBtn>
                <SquareMenu className="mr-2" size={DEFAULT_ICON_SIZE} />
                <span>Operation</span>
              </ToolbarBtn>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <div className="flex items-center justify-between">
                <DropdownMenuItem
                  ref={downloadBtnRef}
                  disabled={true}
                  className="flex items-center text-muted-foreground gap-2 opacity-100"
                >
                  <span className="text-strike">Export ...</span>
                  {/* TODO: DELETE THIS MOVED NOTIFICATION */}
                </DropdownMenuItem>
                <InfoIcon msg="Moved to sidebar item 'Swgger & client'" />
              </div>
              <DropdownMenuItem onClick={handleCopyActive}>
                Copy
              </DropdownMenuItem>
              <DropdownMenuItem onClick={handleCopyAll}>
                Copy all
              </DropdownMenuItem>
              <DropdownMenuItem onClick={handleExportOperationAsPng}>
                Export PNG
              </DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuLabel>Preview options</DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuCheckboxItem
                checked={previewState.showNestedComponents}
                onCheckedChange={handleShowNestedComponents}
              >
                Show nested models
              </DropdownMenuCheckboxItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </>
      )}
      {activeElement?.type === "model" && (
        <Tooltip>
          <TooltipTrigger asChild>
            <ToolbarBtn onClick={handleCopyActiveModel}>
              <NormIcons.Clipboard size={DEFAULT_ICON_SIZE} className="mr-2" />
              Copy
            </ToolbarBtn>
          </TooltipTrigger>
          <TooltipContent>{copyMsg}</TooltipContent>
        </Tooltip>
      )}

      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            variant="ghost"
            className="text-muted-foreground"
            size="sm"
            onClick={
              isAuthed
                ? shareDialogDisclosure.onOpen
                : signUpDialogDisclosure.onOpen
            }
          >
            <NormIcons.Share size={DEFAULT_ICON_SIZE} className="mr-2" />
            Share
          </Button>
        </TooltipTrigger>
        <TooltipContent>Share workspace</TooltipContent>
      </Tooltip>
      {!isAuthed && isEditor && (
        <Tooltip>
          <TooltipTrigger asChild>
            <Link to="/login">
              <Button
                variant="ghost"
                className="text-muted-foreground"
                size="sm"
              >
                <NormIcons.Save size={DEFAULT_ICON_SIZE} className="mr-2" />
                Save
              </Button>
            </Link>
          </TooltipTrigger>
          <TooltipContent>Save workspace</TooltipContent>
        </Tooltip>
      )}
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            variant="ghost"
            size="sm"
            className="text-muted-foreground"
            onClick={() => {
              actionBarDispatch({
                type: "SET_PAGE",
                payload: {
                  name: "workspace-settings",
                  context: {
                    handleAboutClick: aboutDisclosure.onOpen,
                    handleGiveFeedbackClick: feedbackDisclosure.onOpen,
                    handleShareWorkspaceClick: isAuthed
                      ? shareDialogDisclosure.onOpen
                      : signUpDialogDisclosure.onOpen,
                    handleSubscribeToNewsClick: newsletterDisclosure.onOpen,
                    isAuthed,
                    organizationSlug,
                    workspaceRole,
                  },
                },
              });
            }}
          >
            <NormIcons.Settings size={DEFAULT_ICON_SIZE} className=" mr-2" />
            Actions
          </Button>
        </TooltipTrigger>
        <TooltipContent>Workspace actions</TooltipContent>
      </Tooltip>
      {hasOperations && isEditor && (
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant="ghost"
              size="sm"
              onClick={goBackInHistory}
              disabled={!canGoBackInHistory}
            >
              <NormIcons.Undo size={DEFAULT_ICON_SIZE} className="mr-2" />
              Undo
            </Button>
          </TooltipTrigger>
          <TooltipContent>Undo last action</TooltipContent>
        </Tooltip>
      )}
      {_DEV_ && (
        <Button variant="ghost" size="sm" onClick={handleDebugClick}>
          Debug
        </Button>
      )}
      <FeedbackDialog
        open={feedbackDisclosure.isOpen}
        onOpenChange={feedbackDisclosure.onOpenChange}
      />
      <AboutDialog
        open={aboutDisclosure.isOpen}
        onOpenChange={aboutDisclosure.onOpenChange}
      />
      {isAuthed && workspaceSlug && organizationSlug && (
        <ShareDialog
          workspaceSlug={workspaceSlug}
          organizationSlug={organizationSlug}
          open={shareDialogDisclosure.isOpen}
          onOpenChange={shareDialogDisclosure.onOpenChange}
        />
      )}
      <NewsletterDialog
        open={newsletterDisclosure.isOpen}
        onOpenChange={newsletterDisclosure.onOpenChange}
      />
      <SignupDialog
        open={signUpDialogDisclosure.isOpen}
        onOpenChange={signUpDialogDisclosure.onOpenChange}
      />
    </div>
  );
}
