import { useActionBarContext } from "@/components/contexts/action-bar-context";
import { useAPIEditorTools } from "@/components/contexts/api-editor-context-hooks";
import {
  ParameterForm,
  ParameterFormValues,
} from "@/components/module-action-bar/parameter-form";
import { EditorInputProps } from "@/components/module-api-editor/types";
import { useParameters } from "@/hooks/use-parameters";
import { OASParameterObject } from "@/lib/types";
import { toast } from "sonner";

export function ParameterAdd({
  operationId,
  urlPath,
  value,
  onChange,
}: {
  operationId: string;
  urlPath: string;
} & EditorInputProps) {
  const [, actionBarDispatch] = useActionBarContext();
  const { oas } = useAPIEditorTools({ value, onChange });
  const { addParameter } = useParameters({ value, onChange });
  const onSubmit = (values: ParameterFormValues) => {
    try {
      addParameter({
        urlPath,
        parameter: {
          ...values,
          schema: values.schema as OASParameterObject["schema"],
        },
        operationId,
      });
      actionBarDispatch({
        type: "CLOSE",
      });
    } catch (err) {
      if (err instanceof Error) {
        toast.error(err.message);
      }
    }
  };

  return (
    <ParameterForm
      btnTitle="Create"
      defaultValues={{
        required: false,
      }}
      onSubmit={onSubmit}
      title="Add parameter"
      componentsObject={oas?.components || {}}
    />
  );
}
