import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
} from "@/components/_shadui/breadcrumb";
import { useAPIEditorTools } from "@/components/contexts/api-editor-context-hooks";
import {
  SelectItem,
  SelectList,
} from "@/components/module-action-bar/workspace-settings";
import { EditorInputProps } from "@/components/module-api-editor/types";
import { useActionBarContext } from "../contexts/action-bar-context";
import { ActionBarContent } from "./action-bar-content";
import { ActionBarContentHeader } from "./action-bar-content-header";

export function WorkspaceActions(props: EditorInputProps) {
  const [, actionBarDispatch] = useActionBarContext();
  const { resetToEmptyWorkspace } = useAPIEditorTools(props);

  const handleResetToExample = () => {
    actionBarDispatch({
      type: "SET_PAGE",
      payload: {
        name: "select-example",
      },
    });
  };

  return (
    <ActionBarContent>
      <ActionBarContentHeader>
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>Reset mode</BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </ActionBarContentHeader>
      <SelectList>
        <SelectItem onClick={resetToEmptyWorkspace}>
          Reset to empty workspace
        </SelectItem>
        <SelectItem onClick={handleResetToExample}>
          Reset to example workspace
        </SelectItem>
      </SelectList>
    </ActionBarContent>
  );
}
