import { OASDefinition } from "@/lib/types";

export function removeSchemaRefsFromDefinition(
  obj: any,
  refName: string,
  type: "schemas" | "responses" | "parameters"
): OASDefinition {
  if (typeof obj !== "object" || obj === null) {
    return obj as OASDefinition;
  }
  if (Array.isArray(obj)) {
    return obj
      .filter(
        (item) =>
          !(
            typeof item === "object" &&
            "$ref" in item &&
            item.$ref === `#/components/${type}/${refName}`
          )
      )
      .map((item) => {
        return removeSchemaRefsFromDefinition(item, refName, type);
      }) as any;
  }

  const result: { [key: string]: any } = {};
  for (const [key, value] of Object.entries(obj)) {
    if (typeof value === "object" && value !== null) {
      if (
        key === "items" &&
        "$ref" in value &&
        typeof value.$ref === "string" &&
        value.$ref === `#/components/${type}/${refName}`
      ) {
        // Special case for array items
        result[key] = { type: "object" };
      } else if (
        "$ref" in value &&
        typeof value.$ref === "string" &&
        value.$ref === `#/components/${type}/${refName}`
      ) {
        continue;
      } else if (
        ["oneOf", "anyOf", "allOf"].includes(key) &&
        Array.isArray(value)
      ) {
        // Handle oneOf, anyOf, allOf
        result[key] = (value as any[]).filter(
          (item) =>
            !(
              "$ref" in item &&
              typeof item.$ref === "string" &&
              item.$ref === `#/components/${type}/${refName}`
            )
        );
        if (result[key].length === 0) {
          delete result[key];
        }
      } else {
        result[key] = removeSchemaRefsFromDefinition(value, refName, type);
      }
    } else {
      result[key] = value;
    }
  }
  return result as OASDefinition;
}

export function updateSchemaRefsInDefinition({
  definition,
  oldRefName,
  newRefName,
  type,
}: {
  definition: any;
  oldRefName: string;
  newRefName: string;
  type: "schemas" | "responses" | "parameters";
}): OASDefinition {
  if (typeof definition !== "object" || definition == null) return definition;

  if (Array.isArray(definition))
    return definition.map((el) =>
      updateSchemaRefsInDefinition({
        definition: el,
        oldRefName,
        newRefName,
        type,
      })
    ) as unknown as OASDefinition;

  const res: Record<string, any> = {};
  for (const [key, v] of Object.entries(definition)) {
    res[key] =
      key === "$ref" && v === `#/components/${type}/${oldRefName}`
        ? `#/components/${type}/${newRefName}`
        : updateSchemaRefsInDefinition({
            definition: v,
            oldRefName,
            newRefName,
            type,
          });
  }
  return res as OASDefinition;
}
