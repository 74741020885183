import { ActiveElement } from "@/components/contexts/api-editor-context";
import {
  PreviewProvider,
  usePreviewContext,
} from "@/components/contexts/operation-preview-context";
import { EditorInputProps } from "@/components/module-api-editor/types";
import { ModelRender } from "@/components/module-preview-pane/model-preview";
import { OperationRender } from "@/components/module-preview-pane/operation-preview";
import { useExportOperationAsPng } from "@/hooks/use-export-operation-as-png";
import { useOperation } from "@/hooks/use-operation";
import { OperationWithInfo } from "@/lib/editor-mutations/oas-operations";
import { OASComponentsObject } from "@/lib/types";
import { useCallback } from "react";
import ReactDOMServer from "react-dom/server";
import { toast } from "sonner";

const cssText = `
    <style>
      :root {
        --tw-text-opacity: 1;
      }
      .font-mono {
        font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
      }
      .text-red-400 {
        color: rgb(248 113 113 / var(--tw-text-opacity));
      }
      .text-green-400 {
        color: rgb(74 222 128 / var(--tw-text-opacity));
      }
      .text-yellow-400 {
        color: rgb(250 204 21 / var(--tw-text-opacity));
      }
      .text-gray-400 {
        color: rgb(156 163 175 / var(--tw-text-opacity));
      }
      .text-indigo-400 {
        color: rgb(129 140 248 / var(--tw-text-opacity));
      }
      .text-blue-400 {
        color: rgb(96 165 250 / var(--tw-text-opacity));
      }
      .text-teal-400 {
        color: rgb(45 212 191 / var(--tw-text-opacity));
      }
      .text-emerald-400 {
        color: rgb(52 211 153 / var(--tw-text-opacity));
      }
      .text-muted-foreground {
        color: #64748b;
      }
      .font-bold {
        font-weight: 700;
      }
      .pl-5 {
        padding-left: 20px; 
      }
    </style>
  `;

const addStylesAndWriteToClipboard = async (
  container: HTMLElement,
  successMsg: string
) => {
  const styleElement = document.createElement("style");
  styleElement.textContent = cssText;
  container.insertBefore(styleElement, container.firstChild);

  try {
    await navigator.clipboard.write([
      new ClipboardItem({
        "text/html": new Blob([container.innerHTML], { type: "text/html" }),
      }),
    ]);
    toast.success(successMsg);
  } catch (err) {
    toast.error("Failed to copy to clipboard: " + JSON.stringify(err));
    throw err;
  }
};

export function useHandleCopy(props: EditorInputProps) {
  const { getOperationsWithInfo } = useOperation(props);
  const [previewState] = usePreviewContext();
  const { exportAsPng } = useExportOperationAsPng();

  const handleCopyComponent = useCallback(
    async ({
      activeElement,
      componentsObject,
    }: {
      activeElement: ActiveElement;
      componentsObject: OASComponentsObject;
    }) => {
      if (activeElement == null || activeElement?.type !== "model") return;

      const container = document.createElement("div");
      container.innerHTML = ReactDOMServer.renderToString(
        <PreviewProvider initialState={previewState}>
          <ModelRender
            componentId={{ type: "schema", id: activeElement.modelName }}
            componentsObject={componentsObject}
          />
        </PreviewProvider>
      );

      const styleElement = document.createElement("style");
      styleElement.textContent = cssText;
      container.insertBefore(styleElement, container.firstChild);

      await addStylesAndWriteToClipboard(
        container,
        "Copied model to clipboard"
      );
    },
    [previewState]
  );

  const handleCopyOperation = useCallback(
    async ({
      activeElement,
      componentsObject,
    }: {
      activeElement: ActiveElement;
      componentsObject: OASComponentsObject;
    }) => {
      if (activeElement == null || activeElement?.type !== "operation") return;

      const operationWithInfo = getOperationsWithInfo().find(
        (o) => o.operation.operationId === activeElement.operationId
      );

      if (!operationWithInfo) throw new Error("Unable to find operation");

      const container = document.createElement("div");
      container.innerHTML = ReactDOMServer.renderToString(
        <PreviewProvider initialState={previewState}>
          <OperationRender
            operationWithInfo={operationWithInfo}
            components={componentsObject}
          />
        </PreviewProvider>
      );

      const styleElement = document.createElement("style");
      styleElement.textContent = cssText;
      container.insertBefore(styleElement, container.firstChild);

      await addStylesAndWriteToClipboard(
        container,
        "Copied operation to clipboard"
      );
    },
    [previewState, getOperationsWithInfo]
  );

  const handleCopyAllOperations = useCallback(
    async ({
      operationsWithInfo,
      componentsObject,
    }: {
      operationsWithInfo: OperationWithInfo[];
      componentsObject: OASComponentsObject;
    }) => {
      const container = document.createElement("div");
      operationsWithInfo.forEach((operation, i) => {
        const operationNode = document.createElement("div");
        operationNode.innerHTML = ReactDOMServer.renderToString(
          <PreviewProvider initialState={previewState}>
            <OperationRender
              operationWithInfo={operation}
              components={componentsObject}
            />
          </PreviewProvider>
        );
        container.appendChild(operationNode);
        if (i !== operationsWithInfo.length - 1) {
          const separator = document.createElement("div");
          separator.textContent =
            "############################################################";
          separator.classList.add("font-mono");
          container.appendChild(document.createElement("br"));
          container.appendChild(separator);
          container.appendChild(document.createElement("br"));
        }
      });
      await addStylesAndWriteToClipboard(
        container,
        "Copied operations to clipboard"
      );
    },
    [previewState]
  );

  const handleExportAsPng = useCallback(
    async ({
      activeElement,
      componentsObject,
    }: {
      activeElement: ActiveElement;
      componentsObject: OASComponentsObject;
    }) => {
      if (activeElement == null || activeElement?.type !== "operation") return;

      const operationWithInfo = getOperationsWithInfo().find(
        (o) => o.operation.operationId === activeElement.operationId
      );

      if (!operationWithInfo) throw new Error("Unable to find operation");

      const container = document.createElement("div");
      container.innerHTML = ReactDOMServer.renderToString(
        <PreviewProvider initialState={previewState}>
          <OperationRender
            operationWithInfo={operationWithInfo}
            components={componentsObject}
          />
        </PreviewProvider>
      );

      const styleElement = document.createElement("style");
      styleElement.textContent = cssText;
      container.insertBefore(styleElement, container.firstChild);

      await exportAsPng({
        apiDefinition: container.innerHTML,
        cardWidth: 800,
        cardPadding: 20,
        backgroundPadding: 100,
      });
    },
    [exportAsPng, getOperationsWithInfo, previewState]
  );

  return {
    handleCopyOperation,
    handleCopyAllOperations,
    handleCopyComponent,
    handleExportAsPng,
  };
}
