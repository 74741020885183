import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/_shadui/breadcrumb";
import { useAPIEditorTools } from "@/components/contexts/api-editor-context-hooks";
import {
  SelectItem,
  SelectList,
} from "@/components/module-action-bar/workspace-settings";
import { EditorInputProps } from "@/components/module-api-editor/types";
import { useActionBarContext } from "../contexts/action-bar-context";
import { ActionBarContent } from "./action-bar-content";

export function SelectExample(props: EditorInputProps) {
  const { resetToExample } = useAPIEditorTools(props);
  const [, actionBarDispatch] = useActionBarContext();

  const handleResetToExample = (exampleName: "bookStore") => {
    resetToExample(exampleName);
  };

  return (
    <ActionBarContent>
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink
              className="cursor-pointer"
              onClick={() => {
                actionBarDispatch({
                  type: "SET_PAGE",
                  payload: {
                    name: "workspace-actions",
                  },
                });
              }}
            >
              Workspace actions
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbLink>Select example</BreadcrumbLink>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
      <SelectList>
        <SelectItem onClick={() => handleResetToExample("bookStore")}>
          Book store
        </SelectItem>
      </SelectList>
    </ActionBarContent>
  );
}
