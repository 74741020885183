import { Button } from "@/components/_shadui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/_shadui/tooltip";
import { DEFAULT_ICON_SIZE, NormIcons } from "@/lib/utils";
import { ReactNode } from "@tanstack/react-router";

export function InfoIcon({ msg }: { msg: ReactNode }) {
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button size="icon-sm" variant="ghost" className="text-brand">
          <NormIcons.Info size={DEFAULT_ICON_SIZE} />
        </Button>
      </TooltipTrigger>
      <TooltipContent>{msg}</TooltipContent>
    </Tooltip>
  );
}
