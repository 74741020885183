import { AxiosInstance, AxiosRequestConfig } from "axios";
import * as definitions from "./definitions";

export class ApiClient {
  public axios: AxiosInstance;

  constructor(axiosInstance: AxiosInstance) {
    this.axios = axiosInstance;
  }

  public setTokenHeader(accessToken: string) {
    this.axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  }

  public githubSigninOrSignup(options?: AxiosRequestConfig) {
    return this.axios.get<definitions.TokenResponseDto>(
      "/v1/public/me/auth/github/authorized",
      options
    );
  }

  public googleSigninOrSignup(options?: AxiosRequestConfig) {
    return this.axios.get<definitions.TokenResponseDto>(
      "/v1/public/me/auth/google/authorized",
      options
    );
  }

  public findMe(options?: AxiosRequestConfig) {
    return this.axios.get<definitions.UserDto>("v1/users/me", options);
  }

  public patchUser(
    params: { userId: string },
    data: definitions.PatchUserDto,
    options?: AxiosRequestConfig
  ) {
    return this.axios.patch(`/v1/users/${params.userId}`, data, options);
  }

  public findOrganizationByIdOrSlug(
    params: { organizationIdOrSlug: string },
    options?: AxiosRequestConfig
  ) {
    return this.axios.get<definitions.OrganizationDto>(
      `/v1/organizations/${params.organizationIdOrSlug}`,
      options
    );
  }

  public findWorkspacesByOrganizationSlug(
    params: { organizationSlug: string },
    options?: AxiosRequestConfig
  ) {
    return this.axios.get<definitions.WorkspaceDto[]>(
      `/v1/organizations/${params.organizationSlug}/workspaces`,
      options
    );
  }

  public createOrganization(
    data: definitions.CreateOrganizationDto,
    options?: AxiosRequestConfig
  ) {
    return this.axios.post("/v1/organizations", data, options);
  }

  public patchOrganization(
    params: { organizationId: string },
    data: definitions.PatchOrganizationDto,
    options?: AxiosRequestConfig
  ) {
    return this.axios.patch(
      `/v1/organizations/${params.organizationId}`,
      data,
      options
    );
  }

  public cloneWorkspace(
    params: { workspaceId: string },
    options?: AxiosRequestConfig
  ) {
    return this.axios.post(
      `/v1/workspaces/${params.workspaceId}/clone`,
      {},
      options
    );
  }

  public createWorkspace(
    data: definitions.CreateWorkspaceDto,
    options?: AxiosRequestConfig
  ) {
    return this.axios.post<definitions.WorkspaceDto>(
      "/v1/workspaces",
      data,
      options
    );
  }

  public findWorkspaceByIdOrSlug(
    params: { workspaceSlugOrId: string },
    options?: AxiosRequestConfig
  ) {
    return this.axios.get<definitions.UserWorkspaceDto>(
      `/v1/workspaces/${params.workspaceSlugOrId}`,
      options
    );
  }

  public patchWorkspace(
    params: { workspaceId: string },
    data: definitions.PatchWorkspaceDto,
    options?: AxiosRequestConfig
  ) {
    return this.axios.patch<definitions.WorkspaceDto>(
      `/v1/workspaces/${params.workspaceId}`,
      data,
      options
    );
  }

  public deleteWorkspace(
    params: { workspaceId: string },
    options?: AxiosRequestConfig
  ) {
    return this.axios.delete<void>(
      `/v1/workspaces/${params.workspaceId}`,
      options
    );
  }

  public findFullWorkspace(
    params: {
      organizationSlug: string;
      workspaceSlug: string;
    },
    options?: AxiosRequestConfig
  ) {
    return this.axios.get<definitions.WorkspaceStateDto>(
      `/v1/workspaces/${params.organizationSlug}/${params.workspaceSlug}`,
      options
    );
  }

  public createWorkspaceInvitation(
    data: definitions.CreateWorkspaceInvitationDto,
    options?: AxiosRequestConfig
  ) {
    return this.axios.post<unknown>(`/v1/workspace-invitations`, data, options);
  }

  public acceptWorkspaceInvitaiton(
    data: definitions.AcceptWorkspaceInvitationDto,
    options?: AxiosRequestConfig
  ) {
    return this.axios.post(`/v1/workspace-invitations/accept`, data, options);
  }

  public findUserWorkspaces(options?: AxiosRequestConfig) {
    return this.axios.get<definitions.UserWorkspaceDto[]>(
      `/v1/workspaces`,
      options
    );
  }

  public deleteWorkspaceMember(
    params: { workspaceId: string; userId: string },
    options?: AxiosRequestConfig
  ) {
    return this.axios.delete(
      `/v1/workspaces/${params.workspaceId}/members/${params.userId}`,
      options
    );
  }

  public findWorkspaceMembers(
    params: { workspaceId: string },
    opations?: AxiosRequestConfig
  ) {
    return this.axios.get<definitions.WorkspaceMemberSummaryDto[]>(
      `/v1/workspaces/${params.workspaceId}/members`,
      opations
    );
  }

  public patchWorkspaceMember(
    params: { workspaceId: string; userId: string },
    data: definitions.PatchWorkspaceMemberDto,
    opations?: AxiosRequestConfig
  ) {
    return this.axios.patch(
      `/v1/workspaces/${params.workspaceId}/members/${params.userId}`,
      data,
      opations
    );
  }

  public createWorkspaceItem(
    data: definitions.CreateWorkspaceItemDto,
    options?: AxiosRequestConfig
  ) {
    return this.axios.post<definitions.WorksapceItemDto>(
      `/v1/workspace-items`,
      data,
      options
    );
  }

  public createWorkspaceDocument(
    data: definitions.CreateWorkspaceDocumentDto,
    options?: AxiosRequestConfig
  ) {
    return this.axios.post<definitions.WorkspaceDocumentDto>(
      `/v1/workspace-documents`,
      data,
      options
    );
  }

  public patchWorkspaceDocument(
    params: { workspaceDocumentId: string },
    data: definitions.UpdateWorkspaceDocumentDto,
    options?: AxiosRequestConfig
  ) {
    return this.axios.patch<definitions.WorkspaceDocumentDto>(
      `/v1/workspace-documents/${params.workspaceDocumentId}`,
      data,
      options
    );
  }
}
