import { Button } from "@/components/_shadui/button";
import { useAPIEditorTools } from "@/components/contexts/api-editor-context-hooks";
import { EditorInputProps } from "@/components/module-api-editor/types";
import { companyInfo } from "@/lib/const";
import { DEFAULT_ICON_SIZE } from "@/lib/utils";
import { ArrowLeft } from "lucide-react";
import React, { useEffect } from "react";
import { toast } from "sonner";
import * as Sentry from "@sentry/react";

export function ApiEditorErrorFallback({
  resolveError,
  error,
  ...editorInputProps
}: {
  resolveError: () => unknown;
  error: Error;
} & EditorInputProps) {
  const { goBackInHistory, canGoBackInHistory, resetToEmptyWorkspace, oas } =
    useAPIEditorTools(editorInputProps);

  const handleGoBack = () => {
    goBackInHistory();
    resolveError();
    toast.success(
      "Previous history restored. To go back further, press the button again until it becomes disabled."
    );
  };

  useEffect(() => {
    Sentry.captureException(error, {
      extra: {
        oas: JSON.stringify(oas),
      },
    });
  }, []);

  const handleReset = () => {
    resetToEmptyWorkspace();
    resolveError();
  };

  return (
    <div className="h-screen w-screen flex justify-center items-center">
      <div className="flex flex-col justify-center items-stretchtext-lg gap-4 max-w-[400px]">
        <span className="text-center text-4xl">😪</span>
        <p>
          Oh no! Looks like the editor crashed. This is on us and a bug. We're
          sorry and hope you don't have to see this message again. If this keeps
          happening write us at: {companyInfo.supportEmail}
          <br />
        </p>
        <div className="pt-2 flex flex-col items-stretch gap-2">
          <Button
            className="w-full"
            disabled={!canGoBackInHistory}
            onClick={handleGoBack}
          >
            <ArrowLeft size={DEFAULT_ICON_SIZE} className="mr-2" /> Try going
            back to last stable state
          </Button>
          <Button variant="secondary" className="w-full" onClick={handleReset}>
            Restore to empty workspace (data will be lost)
          </Button>
        </div>
      </div>
    </div>
  );
}

export class ApiEditorErrorBoundary extends React.Component<
  React.PropsWithChildren<{
    fallback: (arg: {
      resolveError: () => unknown;
      error: Error;
    }) => React.ReactNode;
  }>,
  { hasError: boolean; error: Error | undefined }
> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, error: undefined };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  componentDidCatch(_error: Error, _info: any) {
    // Log in fallback component
  }

  render() {
    if (this.state.hasError && this.state.error) {
      // You can render any custom fallback UI
      return this.props.fallback({
        resolveError: () =>
          this.setState({ hasError: false, error: undefined }),
        error: this.state.error,
      });
    }

    return this.props.children;
  }
}
