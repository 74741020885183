import { OpenAPIV3_1 } from "@/lib/oas-tools/openapi-types";
import { supportedHttpVerbs } from "./helpers";
import { supportedQueryParameterFormats } from "@/lib/oas-tools/oas-schema-utils";

export interface KeyKombo {
  keys: string[];
  ctrlKey?: boolean;
}

export type SupportedHTTPVerbs = (typeof supportedHttpVerbs)[number];
export const supportedContentFormats = ["application/json"] as const;
export type SupportedContentFormats = (typeof supportedContentFormats)[number];
export type SupportedQuerySerializationStyles =
  (typeof supportedQueryParameterFormats)[number];

// eslint-disable-next-line @typescript-eslint/ban-types
export type Prettyfy<T> = { [K in keyof T]: T[K] } & {};

export type OASDefinition = OpenAPIV3_1.Document;
export type OASPath = OpenAPIV3_1.PathItemObject;
export type OASOperation = OpenAPIV3_1.OperationObject;
export type OASResponseObject = OpenAPIV3_1.ResponseObject;
export type OASResponsesObject = OpenAPIV3_1.ResponsesObject;
export type OASRequestBodyObject = OpenAPIV3_1.RequestBodyObject;
export type OASResponse = OpenAPIV3_1.ResponseObject | OASReferenceObject;
export type OASSchema = OpenAPIV3_1.SchemaObject;
export type OASNonArraySchemaObject = OpenAPIV3_1.NonArraySchemaObject;
export type OASParameterObject = OpenAPIV3_1.ParameterObject;
export type OASParameter = OpenAPIV3_1.ParameterObject | OASReferenceObject;
export type OASTag = OpenAPIV3_1.TagObject;
export type OASReferenceObject = OpenAPIV3_1.ReferenceObject;
export type OASArraySchemaObject = OpenAPIV3_1.ArraySchemaObject;
export type OASComponentsObject = OpenAPIV3_1.ComponentsObject;
export type OASComponentsResponses = NonNullable<
  OASComponentsObject["responses"]
>;
export type OASMixedSchemaObject = OpenAPIV3_1.BaseSchemaObject & {
  type?: (
    | OpenAPIV3_1.ArraySchemaObjectType
    | OpenAPIV3_1.NonArraySchemaObjectType
  )[];
  items?: OpenAPIV3_1.ReferenceObject | OpenAPIV3_1.SchemaObject;
};
