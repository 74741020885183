import { Button } from "@/components/_shadui/button";
import { H3 } from "@/components/headings";
import { DEFAULT_ICON_SIZE, NormIcons } from "@/lib/utils";
import { ReactNode } from "@tanstack/react-router";

export function ActionBarReferenceMessage({
  title,
  description,
  onVisitReference,
  onDereference,
}: {
  title: ReactNode;
  description: ReactNode;
  onVisitReference: () => unknown;
  onDereference: () => unknown;
}) {
  return (
    <div className="pb-6">
      <H3 className="text-muted-foreground font-mono text-sm">
        <span className="inline-block pb-0.5 pr-1 mb-2">{title}</span>
      </H3>

      <p className="text-muted-foreground pb-6">{description}</p>

      <div className="flex flex-col items-stretch gap-1">
        <Button size="sm" onClick={onVisitReference}>
          <NormIcons.Link size={DEFAULT_ICON_SIZE} className="mr-2" />
          Visit reference
        </Button>
        <Button variant="secondary" size="sm" onClick={onDereference}>
          <NormIcons.Dereference className="mr-2" size={DEFAULT_ICON_SIZE} />
          Dereference
        </Button>
      </div>
    </div>
  );
}
