import { H3 } from "@/components/headings";
import { cn } from "@/lib/utils";
import { ComponentProps } from "react";

export function FormBox({
  children,
  title,
  className,
  ...rest
}: ComponentProps<"div"> & { title: string }) {
  return (
    <div className={cn("rounded-lg px-6 py-6 border", className)} {...rest}>
      <H3 className="font-mono pb-4">{title}</H3>
      <div>{children}</div>
    </div>
  );
}

export function FieldGroup({ className, ...rest }: ComponentProps<"div">) {
  return (
    <div
      className={cn("flex flex-col items-stretch gap-3", className)}
      {...rest}
    />
  );
}

export function ButtonGroup({
  className,
  children,
  ...rest
}: ComponentProps<"div">) {
  return (
    <div className={cn("pt-4 flex gap-2", className)} {...rest}>
      {children}
    </div>
  );
}
