import * as React from "react";

import { Button } from "@/components/_shadui/button";
import { ColorBadeColors, ColorBadge } from "@/components/_shadui/color-badge";
import { H3 } from "@/components/headings";
import { getSchemaTypeName } from "@/lib/oas-tools/oas-schema-utils";
import { deref, isReferenceSchema } from "@/lib/oas-tools/oas-tag-helpers";
import { typeColorMap } from "@/lib/oas-tools/style-helpers";
import { OASReferenceObject, OASSchema } from "@/lib/types";
import { canEdit, cn } from "@/lib/utils";
import { WorkspaceRole } from "@/components/module-api-editor/types";

export const DocumentationCard = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("text-card-foreground shadow", className)}
    {...props}
  />
));
DocumentationCard.displayName = "Card";

export const DocumentationCardHeader = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & {
    onAdd?: () => unknown;
    workspaceRole: WorkspaceRole;
  }
>(({ className, children, onAdd, workspaceRole, ...props }, ref) => {
  const isEditor = canEdit(workspaceRole);
  return (
    <div
      ref={ref}
      className={cn(
        "flex pb-2 justify-between items-center border-b mx-2 pl-2 pr-2",
        className
      )}
      {...props}
    >
      {children}
      {onAdd && isEditor && (
        <Button variant="secondary" size="xs" onClick={onAdd}>
          Add new
        </Button>
      )}
    </div>
  );
});
DocumentationCardHeader.displayName = "CardHeader";

export const DocumentationCardTitle = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
  <H3 ref={ref} className={cn(className)} {...props} />
));
DocumentationCardTitle.displayName = "CardTitle";

export const DocumentationCardDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <p
    ref={ref}
    className={cn(
      "text-sm text-muted-foreground max-w-[600px] w-2/3] whitespace-pre-wrap",
      className
    )}
    {...props}
  />
));
DocumentationCardDescription.displayName = "CardDescription";

export const DocumentationCardContent = React.forwardRef<
  HTMLUListElement,
  React.HTMLAttributes<HTMLUListElement>
>(({ className, ...props }, ref) => (
  <ul ref={ref} className={cn(" pt-0", className)} {...props} />
));
DocumentationCardContent.displayName = "CardContent";

export const DocumentationCardItem = React.forwardRef<
  HTMLLIElement,
  React.HTMLAttributes<HTMLLIElement> & { isLast?: boolean }
>(({ children, className, isLast = false, ...props }, ref) => {
  const canHover = props.onClick;
  return (
    <li
      ref={ref}
      data-islast={isLast}
      className={cn(
        "border-b data-[islast=true]:border-none py-0.5",
        className
      )}
      {...props}
    >
      <div
        className={cn("py-4 px-4 rounded-md", {
          "hover:bg-accent cursor-pointer transition-colors": canHover,
        })}
      >
        {children}
      </div>
    </li>
  );
});

export const DocumentationCardItemHeader = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  return (
    <div
      ref={ref}
      className={cn("flex justify-between items-center", className)}
      {...props}
    />
  );
});

export const DocumentationCardItemTitle = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => {
  return (
    <div ref={ref} className={cn("", className)} {...props}>
      <H3 className="text-primary">{children}</H3>
    </div>
  );
});

export const DocumentationCardMoreButton = React.forwardRef<
  HTMLButtonElement,
  React.HTMLAttributes<HTMLButtonElement> & { btnTitle: string }
>(({ className, btnTitle, children: _childen, ...props }, ref) => {
  return (
    <li className="flex gap-2 pt-2 pl-2 ml-2">
      <Button
        role="button"
        size="xs"
        variant="secondary"
        ref={ref}
        className={cn("", className)}
        {...props}
      >
        {btnTitle}
      </Button>
    </li>
  );
});

export const DocumentationCardItemContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  return <div ref={ref} className={cn("pt-1", className)} {...props} />;
});

export const DocumentationCardTypeBadge = ({
  className,
  schema,
  isRequired = true,
  forceColor,
  ...props
}: React.ComponentProps<typeof ColorBadge> & {
  schema: OASSchema | OASReferenceObject | undefined;
  isRequired?: boolean;
  forceColor?: ColorBadeColors;
}) => {
  const badge = !schema ? (
    <ColorBadge className={className} color={forceColor || "gray"} {...props}>
      null
    </ColorBadge>
  ) : isReferenceSchema(schema) ? (
    <ColorBadge {...props} className={className} color={forceColor || "blue"}>
      {deref(schema.$ref)}
    </ColorBadge>
  ) : (
    <ColorBadge
      {...props}
      className={className}
      color={forceColor || typeColorMap[getSchemaTypeName(schema)] || "gray"}
    >
      {getSchemaTypeName(schema)}
    </ColorBadge>
  );
  return (
    <span className="inline-flex gap-1">
      {badge}
      {!isRequired && <ColorBadge color="gray">or null</ColorBadge>}
    </span>
  );
};
