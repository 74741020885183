import { EditorState } from "@/components/module-api-editor/types";
import { WorkspaceDocumentEditor } from "@/components/module-api-editor/workspace-document-editor";
import { apiClient } from "@/lib/http-utils";
import { validateEditorStateBeforeUse } from "@/lib/oas-tools/oas-schema-utils";
import { canEdit, getIsAuthed } from "@/lib/utils";
import {
  useUpdateWorkspaceDocument,
  useUserWorkspace,
} from "@/queries/workspaces";
import { createFileRoute } from "@tanstack/react-router";
import { useCallback, useMemo, useState } from "react";

export const Route = createFileRoute(
  "/editor/$organizationSlug/$workspaceSlug"
)({
  loader: ({ params: { workspaceSlug, organizationSlug } }) => {
    return apiClient.findFullWorkspace({ organizationSlug, workspaceSlug });
  },
  component: WorkspaceEditor,
});

// Editing a document that is persisted in the database
// Editing this object will update the database record
export function WorkspaceEditor() {
  const { organizationSlug, workspaceSlug } = Route.useParams();
  const publicWorkspace = Route.useLoaderData().data;
  const patchWorkspaceDocumentMutation = useUpdateWorkspaceDocument();

  const isAuthed = useMemo(() => getIsAuthed(), []);

  const userWorkspace = useUserWorkspace(
    { workspaceSlugOrId: workspaceSlug },
    { enabled: isAuthed }
  );

  const workspaceDocument =
    publicWorkspace.workspace_items[0].workspace_documents[0];

  const [value, setValue] = useState<EditorState>(() => {
    const editorState = validateEditorStateBeforeUse({
      document_type: workspaceDocument.document_type,
      data: workspaceDocument.data,
    });
    return editorState;
  });

  const role = useMemo(() => {
    const role = userWorkspace.data?.role;
    if (role === "admin") return role;
    if (publicWorkspace.access_level === "public_write") return "editor";
    if (userWorkspace.data?.role) return userWorkspace.data.role;
    return "reader";
  }, [publicWorkspace.access_level, userWorkspace.data?.role]);

  const onChange = useCallback(
    (editorState: EditorState) => {
      setValue(editorState);
      if (!canEdit(role)) return;
      patchWorkspaceDocumentMutation.mutate({
        workspaceDocumentId: workspaceDocument.id,
        data: editorState.data,
        workspace_id: workspaceDocument.workspace_id,
      });
    },
    [
      workspaceDocument.id,
      workspaceDocument.workspace_id,
      patchWorkspaceDocumentMutation,
      role,
    ]
  );

  return (
    <WorkspaceDocumentEditor
      value={value}
      onChange={onChange}
      organizationSlug={organizationSlug}
      workspaceSlug={workspaceSlug}
      isAuthed={isAuthed}
      workspaceRole={role}
      editorMode="managed-workspace"
    />
  );
}
