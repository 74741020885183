import { useAPIEditorTools } from "@/components/contexts/api-editor-context-hooks";
import {
  EditorInputProps,
  WorkspaceRole,
} from "@/components/module-api-editor/types";
import { SchemaEditor } from "@/components/module-schema-editor/schema-editor";
import { SchemaEditorPreviewContainer } from "@/components/preview-container";
import { getPristineType } from "@/lib/oas-tools/oas-schema-utils";
import { OASSchema } from "@/lib/types";
import { canEdit } from "@/lib/utils";
import { cloneDeep } from "lodash";
import get from "lodash/get";
import set from "lodash/set";
import {
  ComponentPropsWithoutRef,
  forwardRef,
  useCallback,
  useMemo,
  useState,
} from "react";

export const SchemaQuickEditor = forwardRef<
  HTMLDivElement,
  Omit<
    ComponentPropsWithoutRef<"div"> & {
      path: string;
      title: string;
      allowTopLevelReferences: boolean;
    },
    "onChange"
  > &
    EditorInputProps & {
      onClose?: () => unknown;
      workspaceRole: WorkspaceRole;
    }
>(
  (
    {
      path,
      title,
      className,
      allowTopLevelReferences,
      workspaceRole,
      onClose,
      children: _children,
      value: editorValue,
      onChange: editorOnChange,
      ...rest
    },
    ref
  ) => {
    const { getOASOrError, setNewEditorState, componentsObject } =
      useAPIEditorTools({ value: editorValue, onChange: editorOnChange });
    const value = useMemo(() => {
      const oas = getOASOrError();
      return get(oas, path);
    }, [getOASOrError, path]);

    const handleDefinitionUpdate = useCallback(
      (schema: OASSchema) => {
        const oas = getOASOrError();
        const cp = cloneDeep(oas);
        set(cp, path, schema);
        setNewEditorState({
          data: cp,
          document_type: editorValue.document_type,
        });
      },
      [path, getOASOrError, setNewEditorState, editorValue.document_type]
    );

    const handleChange = (schema: OASSchema) => {
      handleDefinitionUpdate(schema);
    };

    const [isLocked, setIsLocked] = useState(true);

    const isEditor = canEdit(workspaceRole);
    return (
      <SchemaEditorPreviewContainer
        isLocked={isLocked}
        onLockChange={isEditor ? () => setIsLocked(!isLocked) : undefined}
        title={title}
        onClose={onClose}
        className={className}
        {...rest}
        ref={ref}
      >
        <SchemaEditor
          value={value}
          onChange={handleChange}
          onRemoveRootSchema={() => handleChange(getPristineType("object"))}
          componentsObject={componentsObject}
          allowTopLevelReferences={allowTopLevelReferences}
          isReadOnly={isLocked}
        />
      </SchemaEditorPreviewContainer>
    );
  }
);
