import { Button } from "@/components/_shadui/button";
import {
  BtnGroup,
  MinimizeButton,
} from "@/components/module-visual-editor/shared-components";
import { cn, DEFAULT_ICON_SIZE_SM, NormIcons } from "@/lib/utils";
import { ComponentProps, PropsWithChildren } from "react";

export function SchemaEditorPreviewContainer({
  title,
  children,
  className,
  isLocked,
  // If no `onLockChange` is passed the editor is
  // always read-only
  onLockChange,
  onClose,
  isDisabled = false,
  ...rest
}: PropsWithChildren<
  ComponentProps<"div"> & {
    title: string;
    isLocked?: boolean;
    onLockChange?: (value: boolean) => unknown;
    onClose?: () => unknown;
    isDisabled?: boolean;
  }
>) {
  return (
    <div
      className={cn(
        "bg-primary-foreground border border-input h-full overflow-auto rounded-md",
        { "opacity-50": isDisabled },
        className
      )}
      {...rest}
    >
      <div
        className={cn(
          "flex items-center justify-between border-input border-b px-2 py-2 gap-3",
          {
            "bg-accent": !onLockChange,
          }
        )}
      >
        <p className="text-sm block">{title}</p>
        <BtnGroup>
          {onLockChange && (
            <Button
              size="icon-sm"
              variant="primary"
              onClick={() => onLockChange(!isLocked)}
            >
              {isLocked ? (
                <NormIcons.Lock size={DEFAULT_ICON_SIZE_SM} />
              ) : (
                <NormIcons.LockOpen size={DEFAULT_ICON_SIZE_SM} />
              )}
            </Button>
          )}
          {onClose && <MinimizeButton onClick={onClose} />}
        </BtnGroup>
      </div>
      <div className="py-2 px-4">{children}</div>
    </div>
  );
}
