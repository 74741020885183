import { getComponentParameterByLabelFromDefinition } from "@/lib/editor-mutations/oas-component-parameters";
import { getOperationByIdFromDefinition } from "@/lib/editor-mutations/oas-operations";
import { deref, isReference } from "@/lib/oas-tools/oas-tag-helpers";
import { OASDefinition, OASParameter, OASParameterObject } from "@/lib/types";
import { getParametersFromUrlPath } from "@/lib/url-path";

export type Where = "path" | "query";

export function getParameterFromDefinitionOrError({
  operationId,
  parameterIdx,
  definition,
}: {
  operationId: string;
  parameterIdx: number;
  definition: OASDefinition;
}) {
  const operation = getOperationByIdFromDefinition(definition, operationId);
  const found = operation.parameters?.[parameterIdx];
  if (!found) throw new Error("Paramter not found");
  return found;
}

export const updateParameterInDefinition = ({
  definition,
  parameterIdx,
  newParameter,
  operationId,
}: {
  definition: OASDefinition;
  parameterIdx: number;
  newParameter: OASParameterObject;
  operationId: string;
}): OASDefinition => {
  const cp = structuredClone(definition);
  const operation = getOperationByIdFromDefinition(cp, operationId);

  const oldParamter = operation.parameters?.[parameterIdx];
  if (!oldParamter) throw new Error("Old parameter not found");

  operation.parameters![parameterIdx] = newParameter;

  return cp;
};

export const getParamterByIndexFromDefinition = ({
  definition,
  operationId,
  idx,
}: {
  definition: OASDefinition;
  operationId: string;
  idx: number;
}) => {
  const parameters =
    getOperationByIdFromDefinition(definition, operationId).parameters || [];

  const found = parameters[idx];
  if (!found) throw new Error("Parameter not found");

  return found;
};

export const removeParameterFromDefinition = ({
  definition,
  operationId,
  parameterIdx,
}: {
  definition: OASDefinition;
  operationId: string;
  parameterIdx: number;
}): OASDefinition => {
  const cp = structuredClone(definition);

  const operation = getOperationByIdFromDefinition(cp, operationId);

  const existing = operation.parameters?.[parameterIdx];
  if (!existing) throw new Error("Paramter not found.");

  operation.parameters!.splice(parameterIdx, 1);

  // clean up empty array
  if (operation.parameters?.length === 0) {
    delete operation.parameters;
  }

  return cp;
};

export function resolveParamterFromDefinition({
  definition,
  parameter,
}: {
  definition: OASDefinition;
  parameter: OASParameter;
}) {
  if (!isReference(parameter)) return parameter;

  const found = definition.components?.parameters?.[deref(parameter.$ref)];
  if (!found) throw new Error("Reference not found");
  if (isReference(found))
    throw new Error("Component parameters cannot be references");

  return found;
}

export function addParameterToDefinition({
  definition,
  parameter,
  operationId,
  urlPath,
}: {
  definition: OASDefinition;
  parameter: OASParameter;
  operationId: string;
  urlPath: string;
}) {
  const cp = structuredClone(definition);

  const resolved = resolveParamterFromDefinition({ definition, parameter });

  const operation = getOperationByIdFromDefinition(cp, operationId);

  if (!operation.parameters) operation.parameters = [];

  if (resolved.in === "path") {
    const parameters = getParametersFromUrlPath(urlPath);
    if (!parameters.some((p) => p.name === resolved.name)) {
      throw new Error(
        "Trying to add a path parameter that is not present in the urlPath."
      );
    }
  }

  if (
    operation.parameters.some((p) => {
      const resolvedParameter = resolveParamterFromDefinition({
        definition,
        parameter: p,
      });
      return (
        resolvedParameter.name === resolved.name &&
        resolvedParameter.in === resolved.in
      );
    })
  ) {
    throw new Error(
      "Conflict: Parameter with this name already exists for operation."
    );
  }

  operation.parameters.push(parameter);

  return cp;
}

export function dereferenceParameterInDefinition({
  definition,
  operationId,
  idx,
}: {
  definition: OASDefinition;
  operationId: string;
  idx: number;
}) {
  const cp = structuredClone(definition);
  const operation = getOperationByIdFromDefinition(cp, operationId);
  const parameter = operation.parameters?.[idx] as OASParameter | undefined;
  if (!parameter) throw new Error("Parameter not found");
  if (!isReference(parameter)) throw new Error("Parameter is not a reference");

  const label = deref(parameter.$ref);
  const componentParameter = getComponentParameterByLabelFromDefinition({
    definition: cp,
    label,
  });

  if (!componentParameter) throw new Error("Unable to find reference");

  const componentParameterCp = structuredClone(componentParameter);
  operation.parameters![idx] = componentParameterCp;
  return cp;
}
