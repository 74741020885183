import {
  getOperationByIdFromDefinition,
  getOperationsWithInfoFromDefinition,
} from "@/lib/editor-mutations/oas-operations";
import {
  getParameterFromDefinitionOrError,
  resolveParamterFromDefinition,
} from "@/lib/editor-mutations/oas-parameters";
import { NoRefsHereError } from "@/lib/errors";
import { supportedHttpVerbs } from "@/lib/helpers";
import { deref, isReference } from "@/lib/oas-tools/oas-tag-helpers";
import { OpenAPIV3_1 } from "@/lib/oas-tools/openapi-types";
import {
  OASDefinition,
  OASParameterObject,
  OASReferenceObject,
} from "@/lib/types";
import { isEmpty, setWith, unset } from "lodash";

export function getComponentParametersFromDefinition({
  definition,
}: {
  definition: OASDefinition;
}) {
  return definition.components?.parameters || {};
}

export function getComponentParameterByLabelFromDefinition({
  definition,
  label,
}: {
  definition: OASDefinition;
  label: string;
}) {
  const componentParameters = getComponentParametersFromDefinition({
    definition,
  });

  const found = componentParameters[label] as
    | OpenAPIV3_1.ParameterObject
    | OpenAPIV3_1.ReferenceObject
    | undefined;

  if (found && isReference(found)) throw new NoRefsHereError();

  return found;
}

export function getComponentParameterByNameFromDefinition({
  definition,
  name,
}: {
  definition: OASDefinition;
  name: string;
}) {
  const componentParameters = getComponentParametersFromDefinition({
    definition,
  });

  const found = Object.entries(componentParameters).find(([, value]) => {
    if ("$ref" in value) return false;
    return value.name === name;
  }) as OASParameterObject | undefined;

  return found;
}

export function addComponentParameterToDefinition({
  definition,
  name,
  parameter,
  skipCheckForExisting = false,
}: {
  definition: OASDefinition;
  name: string;
  parameter: OASParameterObject;
  skipCheckForExisting?: boolean;
}) {
  name = name.toLowerCase();
  parameter.name = parameter.name.toLocaleLowerCase();

  // Check that name and label match
  if (name !== parameter.name)
    throw new Error("Parameter name does not match label");

  // Check for existing by label and throw
  if (!skipCheckForExisting) {
    const found = getComponentParameterByLabelFromDefinition({
      definition,
      label: name,
    });
    if (found)
      throw new Error("A component parameter with this label already exists.");
  }

  // Check for existing by name throw
  if (!skipCheckForExisting) {
    const found = getComponentParameterByNameFromDefinition({
      definition,
      name,
    });
    if (found)
      throw new Error("A component parameter with this name already exists.");
  }

  const cp = structuredClone(definition);

  setWith(cp, `components.parameters.${name}`, parameter);

  return cp;
}

export function addOrAttatchExistingParameterAsComponentToDefinition({
  definition,
  operationId,
  parameterIdx,
}: {
  definition: OASDefinition;
  operationId: string;
  parameterIdx: number;
}) {
  let newDefinition = definition;

  const parameter = getParameterFromDefinitionOrError({
    definition: newDefinition,
    parameterIdx,
    operationId,
  });
  if (isReference(parameter))
    throw new Error("Paramter is already a reference");
  const existingComponent = getComponentParameterByLabelFromDefinition({
    definition: newDefinition,
    label: parameter.name,
  });

  if (existingComponent) {
    newDefinition = structuredClone(newDefinition);
    if (existingComponent.in !== parameter.in) {
      throw new Error(
        "There is an existing component with the same name. But unable to attach because the types do not match"
      );
    }
  } else {
    newDefinition = addComponentParameterToDefinition({
      definition: newDefinition,
      name: parameter.name,
      parameter,
    });
  }

  const operation = getOperationByIdFromDefinition(newDefinition, operationId);
  operation.parameters![parameterIdx] = {
    $ref: `#/components/parameters/${parameter.name}`,
  };

  return newDefinition;
}

export function dereferenceParameter({
  referenceParameter,
  definition,
}: {
  referenceParameter: OASReferenceObject;
  definition: OASDefinition;
}): OASParameterObject {
  const referenceName = deref(referenceParameter.$ref);

  const found = getComponentParameterByLabelFromDefinition({
    definition,
    label: referenceName,
  });
  if (!found) throw new Error("Reference not found.");

  return structuredClone(found);
}

export function removeComponentParameterFromDefinition({
  definition,
  label,
}: {
  definition: OASDefinition;
  label: string;
}) {
  label = label.toLowerCase();
  // Throw if the component does not exist
  const found = getComponentParameterByLabelFromDefinition({
    definition,
    label,
  });
  if (!found) throw new Error("Component parameter not found.");
  const cp = structuredClone(definition);

  // Dereference all references to this parameter
  const operations = getOperationsWithInfoFromDefinition(cp);
  operations.forEach(({ operation }) => {
    operation.parameters = operation.parameters?.map((parameter) => {
      if (!isReference(parameter)) return parameter;
      if (parameter.$ref === `#/components/parameters/${label}`) {
        return dereferenceParameter({
          referenceParameter: parameter,
          definition: cp,
        });
      }
      return parameter;
    });
  });

  // Remove the parameter
  unset(cp, `components.parameters.${label}`);
  return cp;
}

export function editComponentParameterInDefinition({
  oldName,
  newName,
  parameterObject,
  definition,
}: {
  oldName: string;
  newName: string;
  parameterObject: OASParameterObject;
  definition: OASDefinition;
}) {
  oldName = oldName.toLowerCase();
  newName = newName.toLowerCase();

  // throw if parameter component with new label exists
  if (
    oldName !== newName &&
    getComponentParameterByLabelFromDefinition({
      definition,
      label: newName,
    })
  ) {
    throw new Error("A component parameter with this label already exists.");
  }

  // throw if parameter component with new name exists
  if (
    oldName !== newName &&
    getComponentParameterByNameFromDefinition({ definition, name: newName })
  ) {
    throw new Error("A component parameter with this name already exists.");
  }

  // Throw if parameter name does not match new name
  if (newName !== parameterObject.name)
    throw new Error("Component parameter name and label mismatch.");

  let newDefinition = structuredClone(definition);

  // IMPORTANT: only if names differ we update the refs and remove
  // the response
  if (oldName !== newName) {
    Object.entries(newDefinition.paths || {}).forEach(
      ([urlPath, pathObject]) => {
        if (!pathObject) return;

        // Update operation parameters to support query parameters
        for (const method of supportedHttpVerbs) {
          let shouldMoveOperationToNewPath = false;
          const operationObject = pathObject[method];
          if (!operationObject || isReference(operationObject)) continue; // TODO: Support reference objects for operations

          operationObject.parameters = (operationObject.parameters || []).map(
            (parameter) => {
              if (
                isReference(parameter) &&
                parameter.$ref === `#/components/parameters/${oldName}`
              ) {
                const resolvedParamter = resolveParamterFromDefinition({
                  definition,
                  parameter,
                });
                // Don't move the operation in we're mutating a query param
                if (resolvedParamter.in === "path") {
                  shouldMoveOperationToNewPath = true;
                }
                return { $ref: `#/components/parameters/${newName}` };
              }
              return parameter;
            }
          );

          if (shouldMoveOperationToNewPath) {
            const newUrlPath = urlPath.replace(`{${oldName}}`, `{${newName}}`);
            if (newDefinition.paths![newUrlPath]?.[method]) {
              throw new Error(
                `There was a conflict. Moving the operation_id:${operationObject?.operationId} to url:${newUrlPath} overwrites an operation the the same method and path.`
              );
            }

            newDefinition.paths![newUrlPath] = {
              ...newDefinition.paths![newUrlPath],
              [method]: operationObject,
            };

            delete newDefinition.paths![urlPath]?.[method];
          }
        }

        // clean up so we don't have empty path objects left in our definition
        Object.entries(newDefinition.paths || {}).map(
          ([urlPath, pathObject]) => {
            if (isEmpty(pathObject)) {
              delete newDefinition.paths![urlPath];
            }
          }
        );
      }
    );

    newDefinition = removeComponentParameterFromDefinition({
      definition: newDefinition,
      label: oldName,
    });
  }

  return addComponentParameterToDefinition({
    definition: newDefinition,
    name: newName,
    parameter: parameterObject,
    skipCheckForExisting: true,
  });
}
