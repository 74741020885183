import { z } from "zod";

export const dtoTemplateNames = [
  "create",
  "update",
  "partial update",
  "summary",
] as const;
export const dtoTemplateNamesSchema = z.enum(dtoTemplateNames);
export type DtoTemplateName = z.infer<typeof dtoTemplateNamesSchema>;

export function getDtoTemplateValues(
  templatename: DtoTemplateName,
  baseSchemaName: string
): { dtoName: string; dtoDescription: string } {
  switch (templatename) {
    case "create":
      return {
        dtoName: `${baseSchemaName}Create`,
        dtoDescription: `Data transfer object for creating a new ${baseSchemaName}.`,
      };
    case "update":
      return {
        dtoName: `${baseSchemaName}Update`,
        dtoDescription: `Data transfer object for updating all fields of an existing ${baseSchemaName} (PUT operation).`,
      };
    case "partial update":
      return {
        dtoName: `${baseSchemaName}PartialUpdate`,
        dtoDescription: `Data transfer object for partially updating an existing ${baseSchemaName} (PATCH operation).`,
      };
    case "summary":
      return {
        dtoName: `${baseSchemaName}Summary`,
        dtoDescription: `A simplified representation of a ${baseSchemaName}, typically used in list views.`,
      };
  }
}
