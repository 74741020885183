import { HttpStatus } from "@/lib/helpers";
import { OASSchema } from "@/lib/types";
import { cloneDeep } from "lodash";

const httpStatusResponses: Record<HttpStatus, OASSchema> = {
  100: {
    type: "object",
    properties: {
      status: {
        type: "string",
        enum: ["informational"],
        default: "informational",
      },
      message: { type: "string", enum: ["Continue"], default: "Continue" },
    },
    required: ["status", "message"],
  },
  200: {
    type: "object",
    properties: {
      data: { type: "object" },
    },
    required: ["data"],
  },
  201: {
    type: "object",
    properties: {
      data: { type: "object" },
    },
    required: ["data"],
  },
  202: {
    type: "object",
    properties: {
      message: { type: "string", enum: ["Accepted"], default: "Accepted" },
    },
    required: ["message"],
  },
  203: {
    type: "object",
    properties: {
      message: {
        type: "string",
        enum: ["Non-Authoritative Information"],
        default: "Non-Authoritative Information",
      },
    },
    required: ["message"],
  },
  204: {
    type: "null",
  }, // No content, empty response body
  206: {
    type: "object",
    properties: {
      data: { type: "object", properties: {} },
      contentRange: { type: "string" },
    },
    required: ["data", "contentRange"],
  },
  207: {
    type: "object",
    properties: {
      message: {
        type: "string",
        enum: ["Multi-Status"],
        default: "Multi-Status",
      },
    },
    required: ["message"],
  },
  208: {
    type: "object",
    properties: {
      message: {
        type: "string",
        enum: ["Already Reported"],
        default: "Already Reported",
      },
    },
    required: ["message"],
  },
  226: {
    type: "object",
    properties: {
      message: { type: "string", enum: ["IM Used"], default: "IM Used" },
    },
    required: ["message"],
  },
  300: {
    type: "object",
    properties: {
      message: {
        type: "string",
        enum: ["Multiple Choices"],
        default: "Multiple Choices",
      },
    },
    required: ["message"],
  },
  301: {
    type: "object",
    properties: {
      status: { type: "string", enum: ["redirection"], default: "redirection" },
      message: {
        type: "string",
        enum: ["Moved Permanently"],
        default: "Moved Permanently",
      },
      location: { type: "string" },
    },
    required: ["status", "message", "location"],
  },
  302: {
    type: "object",
    properties: {
      status: { type: "string", enum: ["redirection"], default: "redirection" },
      message: { type: "string", enum: ["Found"], default: "Found" },
      location: { type: "string" },
    },
    required: ["status", "message", "location"],
  },
  303: {
    type: "object",
    properties: {
      status: { type: "string", enum: ["redirection"], default: "redirection" },
      message: { type: "string", enum: ["See Other"], default: "See Other" },
      location: { type: "string" },
    },
    required: ["status", "message", "location"],
  },
  304: {
    type: "null",
  }, // Not Modified, empty response body
  305: {
    type: "object",
    properties: {
      status: { type: "string", enum: ["redirection"], default: "redirection" },
      message: { type: "string", enum: ["Use Proxy"], default: "Use Proxy" },
    },
    required: ["status", "message"],
  },
  306: {
    type: "object",
    properties: {
      status: { type: "string", enum: ["redirection"], default: "redirection" },
      message: { type: "string", enum: ["Unused"], default: "Unused" },
    },
    required: ["status", "message"],
  },
  307: {
    type: "object",
    properties: {
      status: { type: "string", enum: ["redirection"], default: "redirection" },
      message: {
        type: "string",
        enum: ["Temporary Redirect"],
        default: "Temporary Redirect",
      },
      location: { type: "string" },
    },
    required: ["status", "message", "location"],
  },
  308: {
    type: "object",
    properties: {
      status: { type: "string", enum: ["redirection"], default: "redirection" },
      message: {
        type: "string",
        enum: ["Permanent Redirect"],
        default: "Permanent Redirect",
      },
      location: { type: "string" },
    },
    required: ["status", "message", "location"],
  },
  400: {
    type: "object",
    properties: {
      message: { type: "string" },
      errors: {
        type: "array",
        items: {
          type: "object",
          properties: {
            message: { type: "string" },
          },
          required: ["message"],
        },
      },
    },
    required: ["message"],
  },
  401: {
    type: "object",
    properties: {
      message: {
        type: "string",
        enum: ["Unauthorized"],
        default: "Unauthorized",
      },
    },
    required: ["message"],
  },
  402: {
    type: "object",
    properties: {
      message: {
        type: "string",
        enum: ["Payment Required"],
        default: "Payment Required",
      },
    },
    required: ["message"],
  },
  403: {
    type: "object",
    properties: {
      message: { type: "string", enum: ["Forbidden"], default: "Forbidden" },
    },
    required: ["message"],
  },
  404: {
    type: "object",
    properties: {
      message: { type: "string", enum: ["Not Found"], default: "Not Found" },
    },
    required: ["message"],
  },
  405: {
    type: "object",
    properties: {
      message: {
        type: "string",
        enum: ["Method Not Allowed"],
        default: "Method Not Allowed",
      },
      allowedMethods: { type: "array", items: { type: "string" } },
    },
    required: ["message", "allowedMethods"],
  },
  406: {
    type: "object",
    properties: {
      message: {
        type: "string",
        enum: ["Not Acceptable"],
        default: "Not Acceptable",
      },
    },
    required: ["message"],
  },
  407: {
    type: "object",
    properties: {
      message: {
        type: "string",
        enum: ["Proxy Authentication Required"],
        default: "Proxy Authentication Required",
      },
    },
    required: ["message"],
  },
  408: {
    type: "object",
    properties: {
      message: {
        type: "string",
        enum: ["Request Timeout"],
        default: "Request Timeout",
      },
    },
    required: ["message"],
  },
  409: {
    type: "object",
    properties: {
      message: { type: "string", enum: ["Conflict"], default: "Conflict" },
    },
    required: ["message"],
  },
  410: {
    type: "object",
    properties: {
      message: { type: "string", enum: ["Gone"], default: "Gone" },
    },
    required: ["message"],
  },
  411: {
    type: "object",
    properties: {
      message: {
        type: "string",
        enum: ["Length Required"],
        default: "Length Required",
      },
    },
    required: ["message"],
  },
  412: {
    type: "object",
    properties: {
      message: {
        type: "string",
        enum: ["Precondition Failed"],
        default: "Precondition Failed",
      },
    },
    required: ["message"],
  },
  413: {
    type: "object",
    properties: {
      message: {
        type: "string",
        enum: ["Payload Too Large"],
        default: "Payload Too Large",
      },
    },
    required: ["message"],
  },
  414: {
    type: "object",
    properties: {
      message: {
        type: "string",
        enum: ["URI Too Long"],
        default: "URI Too Long",
      },
    },
    required: ["message"],
  },
  416: {
    type: "object",
    properties: {
      message: {
        type: "string",
        enum: ["Range Not Satisfiable"],
        default: "Range Not Satisfiable",
      },
    },
    required: ["message"],
  },
  417: {
    type: "object",
    properties: {
      message: {
        type: "string",
        enum: ["Expectation Failed"],
        default: "Expectation Failed",
      },
    },
    required: ["message"],
  },
  418: {
    type: "object",
    properties: {
      message: {
        type: "string",
        enum: ["I'm a teapot"],
        default: "I'm a teapot",
      },
    },
    required: ["message"],
  },
  420: {
    type: "object",
    properties: {
      message: {
        type: "string",
        enum: ["Enhance Your Calm"],
        default: "Enhance Your Calm",
      },
    },
    required: ["message"],
  },
  422: {
    type: "object",
    properties: {
      message: { type: "string" },
      errors: {
        type: "array",
        items: {
          type: "object",
          properties: {
            field: { type: "string" },
            message: { type: "string" },
          },
          required: ["field", "message"],
        },
      },
    },
    required: ["message", "errors"],
  },
  423: {
    type: "object",
    properties: {
      message: { type: "string", enum: ["Locked"], default: "Locked" },
    },
    required: ["message"],
  },
  424: {
    type: "object",
    properties: {
      message: {
        type: "string",
        enum: ["Failed Dependency"],
        default: "Failed Dependency",
      },
    },
    required: ["message"],
  },
  425: {
    type: "object",
    properties: {
      message: { type: "string", enum: ["Too Early"], default: "Too Early" },
    },
    required: ["message"],
  },
  426: {
    type: "object",
    properties: {
      message: {
        type: "string",
        enum: ["Upgrade Required"],
        default: "Upgrade Required",
      },
    },
    required: ["message"],
  },
  429: {
    type: "object",
    properties: {
      message: {
        type: "string",
        enum: ["Too Many Requests"],
        default: "Too Many Requests",
      },
      retryAfter: { type: "number" },
    },
    required: ["message"],
  },
  431: {
    type: "object",
    properties: {
      message: {
        type: "string",
        enum: ["Request Header Fields Too Large"],
        default: "Request Header Fields Too Large",
      },
    },
    required: ["message"],
  },
  444: {
    type: "object",
    properties: {
      message: {
        type: "string",
        enum: ["No Response"],
        default: "No Response",
      },
    },
    required: ["message"],
  },
  450: {
    type: "object",
    properties: {
      message: {
        type: "string",
        enum: ["Blocked by Windows Parental Controls"],
        default: "Blocked by Windows Parental Controls",
      },
    },
    required: ["message"],
  },
  451: {
    type: "object",
    properties: {
      message: {
        type: "string",
        enum: ["Unavailable For Legal Reasons"],
        default: "Unavailable For Legal Reasons",
      },
    },
    required: ["message"],
  },
  494: {
    type: "object",
    properties: {
      message: {
        type: "string",
        enum: ["Request Header Too Large"],
        default: "Request Header Too Large",
      },
    },
    required: ["message"],
  },
  500: {
    type: "object",
    properties: {
      message: {
        type: "string",
        enum: ["Internal Server Error"],
        default: "Internal Server Error",
      },
    },
    required: ["message"],
  },
  501: {
    type: "object",
    properties: {
      message: {
        type: "string",
        enum: ["Not Implemented"],
        default: "Not Implemented",
      },
    },
    required: ["message"],
  },
  502: {
    type: "object",
    properties: {
      message: {
        type: "string",
        enum: ["Bad Gateway"],
        default: "Bad Gateway",
      },
    },
    required: ["message"],
  },
  503: {
    type: "object",
    properties: {
      message: {
        type: "string",
        enum: ["Service Unavailable"],
        default: "Service Unavailable",
      },
      retryAfter: { type: "number" },
    },
    required: ["message"],
  },
  504: {
    type: "object",
    properties: {
      message: {
        type: "string",
        enum: ["Gateway Timeout"],
        default: "Gateway Timeout",
      },
    },
    required: ["message"],
  },
  506: {
    type: "object",
    properties: {
      message: {
        type: "string",
        enum: ["Variant Also Negotiates"],
        default: "Variant Also Negotiates",
      },
    },
    required: ["message"],
  },
  507: {
    type: "object",
    properties: {
      message: {
        type: "string",
        enum: ["Insufficient Storage"],
        default: "Insufficient Storage",
      },
    },
    required: ["message"],
  },
  508: {
    type: "object",
    properties: {
      message: {
        type: "string",
        enum: ["Loop Detected"],
        default: "Loop Detected",
      },
    },
    required: ["message"],
  },
  509: {
    type: "object",
    properties: {
      message: {
        type: "string",
        enum: ["Bandwidth Limit Exceeded"],
        default: "Bandwidth Limit Exceeded",
      },
    },
    required: ["message"],
  },
  510: {
    type: "object",
    properties: {
      message: {
        type: "string",
        enum: ["Not Extended"],
        default: "Not Extended",
      },
    },
    required: ["message"],
  },
};

export function getHttpStatusResponse(code: HttpStatus) {
  return cloneDeep(httpStatusResponses[code]);
}
