export class OperationIdUndefinedError extends Error {
  constructor(msg?: string) {
    super(msg || "Operation id undefined");
  }
}

export class DefinitionNotDefinedError extends Error {
  constructor() {
    super("Definition not defined");
  }
}

export class NoRefsHereError extends Error {
  constructor() {
    super("Refs are not yet supported for this entity");
  }
}
