import { useAPIEditorTools } from "@/components/contexts/api-editor-context-hooks";
import { EditorInputProps } from "@/components/module-api-editor/types";
import {
  addTagToDefinition,
  editTagsInDefinition,
  getTagFromDefinition,
  getTagsFromDefinition,
  removeTagFromDefinition,
} from "@/lib/editor-mutations/oas-tags";
import { OASTag } from "@/lib/types";
import { useCallback } from "react";
import { toast } from "sonner";

export function useTags({ value, onChange }: EditorInputProps) {
  const { setNewEditorState, getOASOrError } = useAPIEditorTools({
    value,
    onChange,
  });

  const update = useCallback(
    ({ tagName, tagObject }: { tagName: string; tagObject: OASTag }) => {
      const definition = getOASOrError();
      const newDefinition = editTagsInDefinition({
        definition,
        tagName,
        tagObject: { ...tagObject, name: tagObject.name },
      });

      setNewEditorState({ document_type: "oas_api_3_1", data: newDefinition });
    },
    [setNewEditorState, getOASOrError]
  );

  const create = useCallback(
    (tagObject: OASTag) => {
      const definition = getOASOrError();
      const newDefinition = addTagToDefinition({
        definition,
        tagObject: { ...tagObject, name: tagObject.name.toLocaleLowerCase() },
      });

      setNewEditorState({ document_type: "oas_api_3_1", data: newDefinition });
    },
    [setNewEditorState, getOASOrError]
  );

  const get = useCallback(
    (tagName: string) => {
      const definition = getOASOrError();
      return getTagFromDefinition({
        definition,
        tagName: tagName.toLowerCase(),
      });
    },
    [getOASOrError]
  );

  const remove = useCallback(
    (tagName: string) => {
      const definition = getOASOrError();
      const newDefinition = removeTagFromDefinition({ definition, tagName });
      if (!newDefinition) return;
      setNewEditorState({ document_type: "oas_api_3_1", data: newDefinition });
      toast.success("Tag removed");
    },
    [getOASOrError, setNewEditorState]
  );

  const getAll = useCallback(() => {
    const definition = getOASOrError();
    return getTagsFromDefinition({ definition });
  }, [getOASOrError]);

  return {
    update,
    create,
    getAll,
    remove,
    get,
  };
}
