import { ColorBadeColors, ColorBadge } from "@/components/_shadui/color-badge";
import { SupportedHTTPVerbs } from "@/lib/types";

const colorMap: Record<SupportedHTTPVerbs, ColorBadeColors> = {
  delete: "red",
  get: "green",
  head: "yellow",
  options: "gray",
  patch: "indigo",
  post: "blue",
  put: "teal",
  trace: "emerald",
};

export function HttpMethodBadge({ method }: { method: SupportedHTTPVerbs }) {
  const methodStr = method.toUpperCase();
  return (
    <ColorBadge color={colorMap[method] || "blue"}>{methodStr}</ColorBadge>
  );
}
