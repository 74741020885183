import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Form,
} from "@/components/_shadui/form";
import { Input } from "@/components/_shadui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/_shadui/select";
import { Textarea } from "@/components/_shadui/textarea";
import { useActionBarContext } from "@/components/contexts/action-bar-context";
import { useAPIEditorTools } from "@/components/contexts/api-editor-context-hooks";
import { FormLabelRequired } from "@/components/form-label-required";
import {
  ActionBarFieldGroup,
  ActionBarForm,
  ActionBarFormTitle,
} from "@/components/module-action-bar/elements";
import { EditorInputProps } from "@/components/module-api-editor/types";
import { SchemaEditor } from "@/components/module-schema-editor/schema-editor";
import {
  BtnGroup,
  SubmitButton,
} from "@/components/module-visual-editor/shared-components";
import { SchemaEditorPreviewContainer } from "@/components/preview-container";
import { useComponentParameters } from "@/hooks/use-component-parameters";
import {
  getPristineType,
  zodSchemaSchema,
} from "@/lib/oas-tools/oas-schema-utils";
import { appRegex } from "@/lib/regex";
import { OASComponentsObject, OASParameterObject } from "@/lib/types";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";

const formSchema = z.object({
  name: z
    .string()
    .min(1, "Required")
    .regex(appRegex.parameterName.expression, appRegex.parameterName.message)
    .toLowerCase(),
  description: z.string().optional(),
  in: z.enum(["path", "query"]),
  schema: zodSchemaSchema,
});

type FormValues = z.infer<typeof formSchema>;

function RawForm({
  defaultValues,
  onSubmit,
  componentsObject,
  title,
  btnTitle,
}: {
  defaultValues: Partial<FormValues>;
  onSubmit: SubmitHandler<FormValues>;
  title: string;
  btnTitle: string;
  componentsObject: OASComponentsObject;
}) {
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      schema: getPristineType("string"),
      name: "",
      description: "",
      in: "path",
      ...defaultValues,
    },
  });

  return (
    <Form {...form}>
      <ActionBarForm onSubmit={form.handleSubmit(onSubmit)}>
        <ActionBarFormTitle>{title}</ActionBarFormTitle>
        <ActionBarFieldGroup>
          <div className="flex gap-4">
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    <FormLabelRequired />
                    Name
                  </FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="in"
              render={({ field }) => (
                <FormItem className="grow">
                  <FormLabel>
                    <FormLabelRequired /> Type
                  </FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl className="h-10">
                      <SelectTrigger>
                        <SelectValue placeholder="Select a parameter type" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="path">Path</SelectItem>
                      <SelectItem value="query">Query</SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <FormField
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Description</FormLabel>
                <FormControl>
                  <Textarea {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <SchemaEditorPreviewContainer
            title="Schema"
            className="min-h-[150px]"
          >
            <Controller
              name="schema"
              control={form.control}
              render={({ field: { onChange, value } }) => (
                <SchemaEditor
                  componentsObject={componentsObject}
                  allowTopLevelReferences={false}
                  value={value}
                  onChange={(schema) => {
                    onChange(schema);
                  }}
                  onRemoveRootSchema={() => {
                    form.setValue("schema", getPristineType("object"));
                  }}
                />
              )}
            />
          </SchemaEditorPreviewContainer>
        </ActionBarFieldGroup>
        <BtnGroup className="justify-end">
          <SubmitButton>{btnTitle}</SubmitButton>
        </BtnGroup>
      </ActionBarForm>
    </Form>
  );
}

export function FormAddComponentParameter({
  value,
  onChange,
}: EditorInputProps) {
  const { componentsObject } = useAPIEditorTools({ value, onChange });
  const { addComponentParameter } = useComponentParameters({ value, onChange });

  const [, actionBarDispatch] = useActionBarContext();

  const onSubmit: SubmitHandler<FormValues> = (values) => {
    addComponentParameter({
      name: values.name,
      parameter: {
        name: values.name,
        description: values.description,
        in: values.in,
        schema: values.schema,
      },
    });
    actionBarDispatch({ type: "CLOSE" });
  };

  return (
    <RawForm
      onSubmit={onSubmit}
      defaultValues={{}}
      title="Add component parameter"
      btnTitle="Add"
      componentsObject={componentsObject}
    />
  );
}

export function FormEditComponentParameter({
  value,
  onChange,
  parameter,
  label,
}: { parameter: OASParameterObject; label: string } & EditorInputProps) {
  const { componentsObject } = useAPIEditorTools({ value, onChange });
  const { editComponentParameter } = useComponentParameters({
    value,
    onChange,
  });

  const [, actionBarDispatch] = useActionBarContext();

  const onSubmit: SubmitHandler<FormValues> = (values) => {
    editComponentParameter({
      oldName: label,
      newName: values.name,
      parameterObject: {
        name: values.name,
        in: values.in,
        description: values.description,
        schema: values.schema,
      },
    });
    actionBarDispatch({ type: "CLOSE" });
  };

  return (
    <RawForm
      componentsObject={componentsObject}
      btnTitle="Edit"
      title="Edit component parameter"
      onSubmit={onSubmit}
      defaultValues={{
        name: parameter.name,
        description: parameter.description,
        schema: parameter.schema,
        in: parameter.in as "path" | "query",
      }}
    />
  );
}
