import {
  createTypeRow,
  generateSchemaRows,
  PreviewRow,
} from "@/lib/oas-tools/generate-oas-operation-rows";
import {
  ComponentId,
  getComponentSchemaFromComponentsObjectOrError,
} from "@/lib/oas-tools/oas-schema-utils";
import { OASComponentsObject } from "@/lib/types";

export function generateOASComponentSchemaRows({
  initialLevel = 0,
  componentsObject,
  componentId,
}: {
  initialLevel: number;
  componentId: ComponentId;
  componentsObject: OASComponentsObject;
}): PreviewRow[] {
  const componentSchema = getComponentSchemaFromComponentsObjectOrError(
    componentsObject,
    componentId
  );
  const rows: PreviewRow[] = [];

  rows.push({
    kind: "oas-row",
    propertyName: undefined,
    rowType: "model-header",
    text: `${componentId.id}`,
    format: undefined,
    level: initialLevel,
    extraInsetLevel: 0,
  });

  rows.push(createTypeRow(initialLevel, 0));

  const schemaRows = generateSchemaRows(
    componentSchema,
    initialLevel + 1,
    componentsObject,
    0
  );

  return rows.concat(schemaRows);
}
