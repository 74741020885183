import { useActionBarContext } from "@/components/contexts/action-bar-context";
import { ActionBarContent } from "@/components/module-action-bar/action-bar-content";
import { ActionBarTitle } from "@/components/module-action-bar/action-bar-title";
import {
  EditorMode,
  WorkspaceRole,
} from "@/components/module-api-editor/types";
import { companyInfo } from "@/lib/const";
import { canEdit, NormIcons } from "@/lib/utils";
import { Link } from "@tanstack/react-router";
import { ComponentProps, PropsWithChildren } from "react";

export function SelectList({ children }: PropsWithChildren) {
  return <ul className="flex flex-col items-stretch pt-4">{children}</ul>;
}

export function SelectItem({ children, ...rest }: ComponentProps<"li">) {
  return (
    <li
      role="button"
      tabIndex={0}
      className="flex transition-all cursor-pointer rounded-md text-sm hover:bg-ho px-2 py-1.5 aria-selected:bg-accent hover:bg-accent aria-selected:text-accent-foreground data-[disabled=true]:pointer-events-none data-[disabled=true]:opacity-50"
      {...rest}
    >
      {children}
    </li>
  );
}

export function WorkspaceSettings({
  isAuthed,
  handleAboutClick,
  handleGiveFeedbackClick,
  handleShareWorkspaceClick,
  handleSubscribeToNewsClick,
  workspaceRole,
  editorMode,
}: {
  isAuthed: boolean;
  handleSubscribeToNewsClick: () => void;
  handleGiveFeedbackClick: () => void;
  handleShareWorkspaceClick: () => void;
  handleAboutClick: () => void;
  workspaceRole: WorkspaceRole;
  editorMode: EditorMode;
}) {
  const [, actionBardispatch] = useActionBarContext();

  const handleResetWorkspaceClick = () => {
    actionBardispatch({
      type: "SET_PAGE",
      payload: {
        name: "workspace-actions",
      },
    });
  };

  const handleCloseAndExecute = (callback: () => unknown) => {
    actionBardispatch({ type: "CLOSE" });
    callback();
  };

  const isEditor = canEdit(workspaceRole);

  return (
    <ActionBarContent>
      <ActionBarTitle>Workspace actions</ActionBarTitle>
      <SelectList>
        {!isAuthed && (
          <Link to="/login">
            <SelectItem>
              <NormIcons.Login className="mr-2 h-4 w-4" />
              Login
            </SelectItem>
          </Link>
        )}
        {!isAuthed && (
          <Link to="/login">
            <SelectItem>
              <NormIcons.Signup className="mr-2 h-4 w-4" />
              Sign up
            </SelectItem>
          </Link>
        )}
        {isEditor && editorMode === "annonymous" && (
          <SelectItem
            onClick={() => handleCloseAndExecute(handleResetWorkspaceClick)}
          >
            <NormIcons.Reset className="mr-2 h-4 w-4" />
            Reset workspace
          </SelectItem>
        )}
        <SelectItem
          onClick={() => handleCloseAndExecute(handleShareWorkspaceClick)}
        >
          <NormIcons.Share className="mr-2 h-4 w-4" />
          Share workspace
        </SelectItem>

        {isAuthed && (
          <Link to="/dash/onboarding">
            <SelectItem>
              <NormIcons.Dashboard className="mr-2 h-4 w-4" />
              Go to dashboard
            </SelectItem>
          </Link>
        )}
      </SelectList>
      <SelectList>
        <a href={companyInfo.blogUrl}>
          <SelectItem>
            <NormIcons.Blog className="mr-2 h-4 w-4" />
            Blog
          </SelectItem>
        </a>
        <SelectItem
          onClick={() => handleCloseAndExecute(handleSubscribeToNewsClick)}
        >
          <NormIcons.Subscribe className="mr-2 h-4 w-4" />
          Subscribe to news
        </SelectItem>
        <SelectItem
          onClick={() => handleCloseAndExecute(handleGiveFeedbackClick)}
        >
          <NormIcons.Feedback className="mr-2 h-4 w-4" />
          Give feedback
        </SelectItem>
        {isAuthed && (
          <Link to="/force-logout">
            <SelectItem>
              <NormIcons.Logout className="mr-2 h-4 w-4" />
              Logout
            </SelectItem>
          </Link>
        )}
        <SelectItem onClick={() => handleCloseAndExecute(handleAboutClick)}>
          <NormIcons.About className="mr-2 h-4 w-4" />
          About
        </SelectItem>
      </SelectList>
    </ActionBarContent>
  );
}
