import {
  addComponentParameterToDefinition,
  getComponentParameterByNameFromDefinition,
} from "@/lib/editor-mutations/oas-component-parameters";
import {
  capitalize,
  HttpStatus,
  httpStatusMap,
  supportedHttpVerbs,
} from "@/lib/helpers";
import { isReference } from "@/lib/oas-tools/oas-tag-helpers";
import {
  OASDefinition,
  OASOperation,
  OASParameterObject,
  SupportedHTTPVerbs,
} from "@/lib/types";
import { camelCase } from "lodash";
import cloneDeep from "lodash/cloneDeep";
import set from "lodash/set";

export type OperationWithInfo = {
  urlPath: string;
  method: SupportedHTTPVerbs;
  operation: OASOperation;
};

export function removeOperationFromDefinition({
  definition,
  urlPath,
  method,
}: {
  definition: OASDefinition;
  urlPath: string;
  method: SupportedHTTPVerbs;
}): OASDefinition {
  const cp = cloneDeep(definition);
  // Check if more than one operation exists for the path.
  // IF we just have one, we need to delete the path as well.
  const path = cp.paths?.[urlPath];
  if (!path) throw new Error("Operation does not exist at path");

  if (Object.keys(path).length > 1) {
    delete path[method];
  } else {
    delete cp.paths?.[urlPath];
  }
  return cp;
}

// Don't clone and return original references
export function getOperationByIdFromDefinition(
  definition: OASDefinition,
  operationId: string
): OASOperation {
  const pathEntries = Object.entries(definition.paths || {});
  let operation: OASOperation | null = null;
  pathEntries.forEach(([, operationObject]) => {
    if (!operationObject) return false;
    const operationEntries = Object.entries(operationObject);
    operationEntries.forEach(([, o]) => {
      if (typeof o === "object" && "operationId" in o) {
        if (o.operationId === operationId) operation = o;
      }
    });
  });
  if (!operation) {
    throw new Error("Operation not found");
  }
  return operation;
}

export function addOperationToDefinition(
  definition: OASDefinition,
  urlPath: string,
  method: SupportedHTTPVerbs,
  operation: OASOperation
): OASDefinition {
  if (definition.paths?.[urlPath]?.[method])
    throw new Error("An operation with the same url and method already exists");
  if (!operation.operationId) throw new Error("OperationId is not defined");
  if (isOperationIdUsedInDefinition(operation.operationId || "", definition)) {
    throw new Error(
      "Choose a unique summary. Seems like there is an operation with the same summary."
    );
  }
  // TODO: Let's validate the urlPath here
  const cp = cloneDeep(definition);
  set(cp, `paths.${urlPath}.${method}`, operation);
  return cp;
}

export function getPathParametersFromUrlPath(
  urlPath: string
): OASParameterObject[] {
  const found = urlPath.match(/{([\w_.-]+)}/g);
  if (!found?.length) return [];
  return found.map((r) => ({
    name: r.replace(/[{}]/g, ""),
    in: "path",
    required: true,
    schema: {
      type: "string",
    },
  }));
}

export function addOperationAndParamsToDefinition({
  definition,
  urlPath,
  method,
  newOperation,
}: {
  definition: OASDefinition;
  urlPath: string;
  method: SupportedHTTPVerbs;
  newOperation: OASOperation;
}) {
  const operationId = newOperation.operationId;
  if (!operationId) throw Error("operationId undefined.");

  let newDefinition = addOperationToDefinition(
    definition,
    urlPath,
    method,
    newOperation
  );

  const pathParams = getPathParametersFromUrlPath(urlPath);

  pathParams.forEach((param) => {
    param.name = param.name.toLowerCase();

    // If the operation already has a path parameter defined
    // we do not need to link it to a component parameter
    const foundInOperation = newOperation.parameters?.find(
      (p) => !isReference(p) && p.in === "path" && p.name === param.name
    );

    const found = getComponentParameterByNameFromDefinition({
      definition: newDefinition,
      name: param.name,
    });

    if (!found && !foundInOperation) {
      newDefinition = addComponentParameterToDefinition({
        definition: newDefinition,
        name: param.name,
        parameter: param,
      });
    }

    const operation = getOperationByIdFromDefinition(
      newDefinition,
      operationId
    );
    operation.parameters = [...(operation.parameters || [])];

    if (!foundInOperation) {
      operation.parameters.push({
        $ref: `#/components/parameters/${param.name}`,
      });
    }
  });

  return newDefinition;
}

export function editOperationAndParamsInDefinition({
  definition,
  newUrlPath,
  newMethod,
  oldMethod,
  oldUrlPath,
  // NOTE: Ensure to include all old parameters that should be copied over
  // These are likely all query parameters
  newOperation,
}: {
  definition: OASDefinition;
  newUrlPath: string;
  oldUrlPath: string;
  oldMethod: SupportedHTTPVerbs;
  newMethod: SupportedHTTPVerbs;
  newOperation: OASOperation;
}) {
  const newDefinition = addOperationAndParamsToDefinition({
    definition: removeOperationFromDefinition({
      definition,
      urlPath: oldUrlPath,
      method: oldMethod,
    }),
    urlPath: newUrlPath,
    method: newMethod,
    newOperation: newOperation,
  });

  return newDefinition;
}

/**
 * Return original references and don't clone
 */
export function getOperationsWithInfoFromDefinition(
  definition: OASDefinition
): OperationWithInfo[] {
  const res: OperationWithInfo[] = [];
  const pathEntries = Object.entries(definition.paths || {});
  for (const [urlPath, path] of pathEntries) {
    const operationEntries = Object.entries(path || {});
    for (const [method, operation] of operationEntries) {
      if (!supportedHttpVerbs.includes(method as SupportedHTTPVerbs)) continue;
      res.push({
        urlPath,
        method: method as SupportedHTTPVerbs,
        operation: operation as OASOperation,
      });
    }
  }
  return res;
}

export function getOperationWithInfoFromDefinition({
  definition,
  operationId,
}: {
  definition: OASDefinition;
  operationId: string;
}): OperationWithInfo {
  const operationsAndMeta = getOperationsWithInfoFromDefinition(definition);
  const found = operationsAndMeta.find(
    (e) => e.operation.operationId === operationId
  );
  if (!found) throw new Error("Unable to find operation");
  return found;
}

export function generateOperationIdForDefinition(
  opInfo: OperationWithInfo,
  oasDefinition: OASDefinition
): string {
  const { urlPath, method } = opInfo;

  // Try to find the last non-variable segment of the path
  const pathSegments = urlPath.split("/").filter(Boolean);
  let lastNonVarSegment = pathSegments
    .slice()
    .reverse()
    .find((seg) => !seg.includes("{"));

  const lastSegmentisVar = pathSegments.slice().reverse()[0][0] === "{";

  if (
    lastNonVarSegment &&
    lastSegmentisVar &&
    lastNonVarSegment.endsWith("s")
  ) {
    lastNonVarSegment = lastNonVarSegment.substring(
      0,
      lastNonVarSegment.length - 1
    );
  }

  // Generate a base name
  const baseName = `${method}${lastNonVarSegment ? capitalize(camelCase(lastNonVarSegment)) : ""}`;

  // Ensure uniqueness
  let finalName = baseName;
  let counter = 1;
  while (isOperationIdUsedInDefinition(finalName, oasDefinition)) {
    if (counter >= 5) {
      finalName = "";
      break;
    }
    finalName = `${baseName}${counter}`;
    counter++;
  }

  return finalName || `operation${Math.random().toString(36).substring(2, 8)}`;
}

export function generateResponseTitleForDefinition(
  httpStatus: HttpStatus,
  operationId: string
) {
  const finalName = `${capitalize(camelCase(operationId))}${capitalize(camelCase(httpStatusMap[httpStatus].message))}`;
  return /[\d]/.test(finalName[0])
    ? `InvalidOperationId${finalName}`
    : finalName;
}

export function isOperationIdUsedInDefinition(
  id: string,
  oasDefinition: OASDefinition
): boolean {
  return Object.values(oasDefinition.paths || []).some((path) =>
    Object.values(path || []).some(
      (op) =>
        typeof op === "object" && "operationId" in op && op.operationId === id
    )
  );
}
