import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/_shadui/accordion";
import { ColorBadge } from "@/components/_shadui/color-badge";
import { useActionBarContext } from "@/components/contexts/action-bar-context";
import { ActiveElement } from "@/components/contexts/api-editor-context";
import {
  DocumentationCard,
  DocumentationCardContent,
  DocumentationCardDescription,
  DocumentationCardHeader,
  DocumentationCardItem,
  DocumentationCardItemContent,
  DocumentationCardItemHeader,
  DocumentationCardItemTitle,
  DocumentationCardMoreButton,
  DocumentationCardTitle,
} from "@/components/documentation-card";
import {
  EditorItemDescription,
  HideEmptyListWhenNonEditor,
} from "@/components/module-api-editor";
import { Toolbar } from "@/components/module-api-editor/toolbar";
import {
  EditorInputProps,
  WorkspaceRole,
} from "@/components/module-api-editor/types";
import {
  BtnGroup,
  DeleteButton,
  EditButton,
} from "@/components/module-visual-editor/shared-components";
import { SchemaQuickEditor } from "@/components/schema-quick-editor";
import { ComponentSchemaWithInfo, useComponents } from "@/hooks/use-components";
import { DtoSchemaWithInfo, useSchemaDtos } from "@/hooks/use-dtos";
import { getSchemaTypeName } from "@/lib/oas-tools/oas-schema-utils";
import { typeColorMap } from "@/lib/oas-tools/style-helpers";
import { canEdit } from "@/lib/utils";
import { Dispatch, SetStateAction, useMemo, useState } from "react";

export function EditorPreviewModel({
  activeElement,
  onChange,
  value,
  isAuthed,
  hasOperations,
  workspaceSlug,
  organizationSlug,
  workspaceRole,
}: {
  activeElement: ActiveElement;
  isAuthed: boolean;
  hasOperations: boolean;
  workspaceSlug: string | undefined;
  organizationSlug: string | undefined;
  workspaceRole: WorkspaceRole;
} & EditorInputProps) {
  const [, actionBarDispatch] = useActionBarContext();
  const { getComponentSchemaOrError } = useComponents({ value, onChange });

  const [activeDto, setActiveDto] = useState("");

  const handleEditClick = (
    componentSchemaWithInfo: ComponentSchemaWithInfo
  ) => {
    actionBarDispatch({
      type: "SET_PAGE",
      payload: {
        name: "edit-component-schema",
        context: {
          schemaName: componentSchemaWithInfo.name,
          componentSchemaObject: componentSchemaWithInfo.schema,
        },
      },
    });
  };

  const handleAdd = () => {
    actionBarDispatch({
      type: "SET_PAGE",
      payload: {
        name: "dto-add",
        context: {
          baseSchemaName: componetSchema.name,
        },
      },
    });
  };

  if (activeElement.type !== "model")
    throw new Error("Active element needs to be model");

  const componetSchema = useMemo(() => {
    return getComponentSchemaOrError(activeElement.modelName);
  }, [activeElement.modelName, getComponentSchemaOrError]);

  const dtoSchemaList = componetSchema.dtoSchemas || [];

  const typeName = getSchemaTypeName(componetSchema.schema);
  const color = typeColorMap[typeName];
  if (!color) throw new Error("Can't find color for type");

  const isEditor = canEdit(workspaceRole);
  return (
    <div className="max-w-screen-xl">
      <div className="flex justify-end pb-3">
        <Toolbar
          hasOperations={hasOperations}
          isAuthed={isAuthed}
          onChange={onChange}
          value={value}
          workspaceSlug={workspaceSlug}
          organizationSlug={organizationSlug}
          workspaceRole={workspaceRole}
        />
      </div>
      <div className="flex justify-between px-5 pb-1">
        <div className="flex gap-4">
          <ColorBadge color={color}>{typeName}</ColorBadge>{" "}
          <p>{componetSchema.name}</p>
        </div>
        {isEditor && (
          <BtnGroup>
            <EditButton onClick={() => handleEditClick(componetSchema)} />
            <DeleteButton />
          </BtnGroup>
        )}
      </div>
      <Accordion type="single" collapsible className="mx-4 mb-norm">
        <AccordionItem value="item-1">
          <AccordionTrigger className="text-sm px-2">
            Toggle schema
          </AccordionTrigger>
          <AccordionContent>
            <SchemaQuickEditor
              value={value}
              onChange={onChange}
              title={`${componetSchema.name} schema`}
              className="min-h-[200px] mb-norm"
              path={`components.schemas.${componetSchema.name}`}
              allowTopLevelReferences={false}
              workspaceRole={workspaceRole}
            />
          </AccordionContent>
        </AccordionItem>
      </Accordion>
      <div className="flex gap-norm mx-2 items-stretch">
        <HideEmptyListWhenNonEditor
          workspaceRole={workspaceRole}
          list={dtoSchemaList}
        >
          <div className="grow flex flex-col gap-4 items-stretch">
            <DocumentationCard className="">
              <DocumentationCardHeader
                onAdd={handleAdd}
                workspaceRole={workspaceRole}
              >
                <DocumentationCardTitle>
                  Data transfer objects
                </DocumentationCardTitle>
              </DocumentationCardHeader>
              <DocumentationCardContent>
                {dtoSchemaList.map((dtoSchemaWithInfo) => {
                  return (
                    <DtoSchemaItem
                      value={value}
                      onChange={onChange}
                      key={dtoSchemaWithInfo.name}
                      dtoSchemaWithInfo={dtoSchemaWithInfo}
                      activeDto={activeDto}
                      setActiveDto={setActiveDto}
                      workspaceRole={workspaceRole}
                    />
                  );
                })}
                {isEditor && (
                  <DocumentationCardMoreButton
                    onClick={handleAdd}
                    btnTitle="Add new"
                  />
                )}
              </DocumentationCardContent>
            </DocumentationCard>
          </div>
        </HideEmptyListWhenNonEditor>
        {activeDto && (
          <DocumentationCard className="shrink min-w-[350px]">
            <DocumentationCardContent className="flex flex-col items-stretch gap-norm min-h-full">
              <SchemaQuickEditor
                value={value}
                onChange={onChange}
                workspaceRole={workspaceRole}
                title={`${activeDto} schema`}
                className="grow min-h-[250px] rounded-md"
                path={`components.schemas.${activeDto}`}
                allowTopLevelReferences={false}
                onClose={() => setActiveDto("")}
              />
            </DocumentationCardContent>
          </DocumentationCard>
        )}
      </div>
    </div>
  );
}

function DtoSchemaItem({
  dtoSchemaWithInfo,
  value,
  onChange,
  activeDto,
  setActiveDto,
  workspaceRole,
}: {
  dtoSchemaWithInfo: DtoSchemaWithInfo;
  activeDto: string;
  setActiveDto: Dispatch<SetStateAction<string>>;
  workspaceRole: WorkspaceRole;
} & EditorInputProps) {
  const { remove } = useSchemaDtos({ value, onChange });
  const [, actionBarDispatch] = useActionBarContext();

  const handleDeleteClick = (requestBodyName: string) => {
    setActiveDto("");
    remove(requestBodyName);
  };
  const handleSetActive = () => {
    setActiveDto(dtoSchemaWithInfo.name);
  };

  const handleEditClick = () => {
    // IMPORTANT If we do not reset activeDto before the modeal opens
    // the editor can crash. If name of dto is updated it gets out-of-sync
    // with activeDto state.
    setActiveDto("");
    actionBarDispatch({
      type: "SET_PAGE",
      payload: {
        name: "dto-edit",
        context: {
          dtoName: dtoSchemaWithInfo.name,
        },
      },
    });
  };

  const isEditor = canEdit(workspaceRole);
  return (
    <DocumentationCardItem
      aria-selected={activeDto === dtoSchemaWithInfo.name}
      className="aria-selected:bg-accent"
      key={dtoSchemaWithInfo.name}
      onClick={handleSetActive}
    >
      <DocumentationCardItemHeader>
        <DocumentationCardItemTitle>
          {dtoSchemaWithInfo.name}
        </DocumentationCardItemTitle>
        {isEditor && (
          <BtnGroup>
            <EditButton
              onClick={(e) => {
                e.stopPropagation();
                handleEditClick();
              }}
            />
            <DeleteButton
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteClick(dtoSchemaWithInfo.name);
              }}
            />
          </BtnGroup>
        )}
      </DocumentationCardItemHeader>
      <DocumentationCardItemContent>
        <DocumentationCardDescription>
          <EditorItemDescription
            item={dtoSchemaWithInfo.dtoSchema}
            onAddDescriptionClick={handleEditClick}
            workspaceRole={workspaceRole}
          />
        </DocumentationCardDescription>
      </DocumentationCardItemContent>
    </DocumentationCardItem>
  );
}
